import type { Datasource } from "@/model"
import {
  getDatasources,
  selectAllDatasources,
  selectDatasourceUsages,
  deleteDatasource as storeDeleteDatasource,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { Button, DeleteDialog } from "@/ui"
import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { Link } from "react-router-dom"

interface DatasourceUsages {
  [key: string]: boolean
}

interface DatasourceLinkProps {
  datasourceId: string
  children: React.ReactNode
}

interface DatasourcesProps {
  mode?: "manage" | "form"
  onSelect?: (datasources: Datasource[]) => void
  preselectedIds?: string[]
}

const DatasourceLink: React.FC<DatasourceLinkProps> = ({
  datasourceId,
  children,
}) => {
  return (
    <Link
      to={`/datasources/${datasourceId}`}
      className="text-indigo-600 hover:text-indigo-900 mr-2 sm:mr-4"
    >
      {children}
    </Link>
  )
}

const NewDatasourceLink: React.FC = () => {
  return (
    <Link
      to="/datasources/new"
      className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
    >
      New Datasource
    </Link>
  )
}

export const Datasources: React.FC<DatasourcesProps> = ({
  mode = "manage",
  onSelect = () => {},
  preselectedIds = [],
}) => {
  const dispatch = useAppDispatch()
  const datasources = useAppSelector(selectAllDatasources)
  const usages = useAppSelector(selectDatasourceUsages)
  const [selectedDatasources, setSelectedDatasources] = useState<
    Record<string, boolean>
  >({})
  const [datasourceToDelete, setDatasourceToDelete] = useState<string | null>(
    null,
  )

  useEffect(() => {
    dispatch(getDatasources())
  }, [dispatch])

  const handleRowClick = (id: string) => {
    if (mode === "form") {
      setSelectedDatasources(prev => ({
        ...prev,
        [id]: !prev[id],
      }))
    }
  }

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    event.stopPropagation()
    setSelectedDatasources(prev => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const handleSelectClick = () => {
    const selectedIds = Object.keys(selectedDatasources).filter(
      id => selectedDatasources[id],
    )

    onSelect(
      selectedIds.map(id => (datasources || []).find(ds => ds.id === id)!),
    )
  }

  const handleDelete = () => {
    if (datasourceToDelete) {
      setDatasourceToDelete(null)
      dispatch(storeDeleteDatasource(datasourceToDelete))
    }
  }

  const renderSkeletonRows = () => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <tr key={index} className="hover:bg-gray-50">
          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <Skeleton width={150} />
          </td>
          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
            <Skeleton width={100} />
          </td>
          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
            <Skeleton width={50} />
          </td>
          <td className="px-4 text-right sm:px-6 py-4 whitespace-nowrap text-sm font-medium">
            <Skeleton width={100} />
          </td>
        </tr>
      ))
  }

  const filteredDatasources = datasources
    ? datasources.filter(datasource => !preselectedIds.includes(datasource.id))
    : []

  return (
    <div className="p-4 sm:p-6">
      {mode === "manage" && (
        <div className="flex justify-between items-center mb-4 sm:mb-6">
          <h1 className="text-2xl font-bold text-gray-900">Datasources</h1>
          <NewDatasourceLink />
        </div>
      )}
      <div className="bg-white rounded-lg shadow overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {mode === "form" && (
                <th
                  scope="col"
                  className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Select
                </th>
              )}
              <th
                scope="col"
                className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
              >
                Type
              </th>
              {mode === "manage" && (
                <th
                  scope="col"
                  className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                ></th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {datasources === undefined ? (
              renderSkeletonRows()
            ) : filteredDatasources.length > 0 ? (
              filteredDatasources.map(datasource => (
                <tr
                  key={datasource.id}
                  className={`hover:bg-gray-50 ${
                    mode === "form" ? "cursor-pointer" : ""
                  }`}
                  onClick={() => handleRowClick(datasource.id)}
                >
                  {mode === "form" && (
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap">
                      <input
                        type="checkbox"
                        checked={selectedDatasources[datasource.id] || false}
                        onChange={e => handleCheckboxChange(e, datasource.id)}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </td>
                  )}
                  <td className="px-2 sm:px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {datasource.name}
                  </td>
                  <td className="px-2 sm:px-6 py-2 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                    {datasource.original_type}
                  </td>
                  {mode === "manage" && (
                    <td className="px-2 text-right sm:px-6 py-2 whitespace-nowrap text-sm font-medium">
                      <DatasourceLink datasourceId={datasource.id}>
                        View
                      </DatasourceLink>
                      <Button
                        variant="link"
                        onClick={e => {
                          e.stopPropagation()
                          setDatasourceToDelete(datasource.id)
                        }}
                        className="text-red-600 hover:text-red-900"
                        disabled={
                          ((usages as DatasourceUsages) || {})[datasource.id]
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={mode === "form" ? 3 : 4}
                  className="px-4 sm:px-6 py-4 text-center text-gray-500"
                >
                  No datasources found. Create a new datasource to get started.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {mode === "form" && (
        <div className="mt-4 flex justify-end">
          <Button
            onClick={handleSelectClick}
            disabled={
              Object.keys(selectedDatasources).filter(
                id => selectedDatasources[id],
              ).length === 0
            }
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
          >
            Select
          </Button>
        </div>
      )}

      {mode === "manage" && (
        <DeleteDialog
          isOpen={datasourceToDelete !== null}
          onClose={() => setDatasourceToDelete(null)}
          onDelete={handleDelete}
          title="Confirm Chart Deletion"
          description="Are you sure you want to delete this chart? This action cannot be undone."
        />
      )}
    </div>
  )
}

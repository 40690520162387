import { TASK_MEASURE, type Task } from "@/model"
import { selectPlotHoverValues, useAppSelector } from "@/store"
import { Line } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import { useState } from "react"
import LabeledText from "./LabeledText"
import { CreatePlusIcon } from "./util"

export const Guider = ({
  plotId,
  innerHeight,
  innerWidth,
  handleVerticalPlusClick,
  handleHorizontalPlusClick,
  formatNumberY,
  formatX,
  xScale,
  yScale,
  task,
}: {
  plotId: string
  innerHeight: number
  innerWidth: number
  handleVerticalPlusClick: () => void
  handleHorizontalPlusClick: () => void
  formatNumberY: (v: number) => string
  formatX: (v: number) => string
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  task: Task
}) => {
  const hoverValues = useAppSelector(selectPlotHoverValues(plotId)) || {}
  const [isVerticalHovered, setIsVerticalHovered] = useState(false)
  const [isHorizontalHovered, setIsHorizontalHovered] = useState(false)

  if (
    hoverValues.xPixel === undefined ||
    hoverValues.yPixel === undefined ||
    hoverValues.xScale === undefined ||
    hoverValues.yScale === undefined
  ) {
    return null
  }

  return (
    <>
      <Line
        from={{ x: hoverValues.xPixel, y: 0 }}
        to={{ x: hoverValues.xPixel, y: innerHeight }}
        stroke={isVerticalHovered ? "red" : "rgba(0, 0, 0, 0.5)"}
        strokeWidth={1}
        strokeDasharray="5,5"
        pointerEvents={"none"}
      />
      <Line
        from={{ x: 0, y: hoverValues.yPixel }}
        to={{ x: innerWidth, y: hoverValues.yPixel }}
        stroke={isHorizontalHovered ? "red" : "rgba(0, 0, 0, 0.5)"}
        strokeWidth={1}
        strokeDasharray="5,5"
        pointerEvents={"none"}
      />
      {task?.task === TASK_MEASURE && task?.subtask === "reference_line" && (
        <g
          onMouseEnter={() => setIsVerticalHovered(true)}
          onMouseLeave={() => setIsVerticalHovered(false)}
        >
          <CreatePlusIcon
            x={hoverValues.xPixel}
            y={0}
            onClick={handleVerticalPlusClick}
          />
        </g>
      )}
      {task?.task === TASK_MEASURE && task?.subtask === "reference_line" && (
        <g
          onMouseEnter={() => setIsHorizontalHovered(true)}
          onMouseLeave={() => setIsHorizontalHovered(false)}
        >
          <CreatePlusIcon
            x={innerWidth}
            y={hoverValues.yPixel}
            onClick={handleHorizontalPlusClick}
          />
        </g>
      )}
      <LabeledText
        x={hoverValues.xPixel}
        y={innerHeight + 15}
        text={formatX(xScale.invert(hoverValues.xPixel!))}
        orientation="vertical"
      />
      <LabeledText
        x={0}
        y={hoverValues.yPixel}
        text={formatNumberY(yScale.invert(hoverValues.yPixel!))}
        orientation="horizontal"
      />
    </>
  )
}

import * as Tooltip from "@radix-ui/react-tooltip"

export const LocalButton = ({
  label,
  onClick = () => {},
  children,
}: {
  label: string
  onClick?: () => void
  children: React.ReactNode
}) => (
  <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <button
          className="rounded hover:bg-gray-100 transition-colors duration-200 p-1.5"
          onClick={onClick}
        >
          {children}
        </button>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          className="px-2 py-1 text-xs bg-gray-800 text-white rounded shadow-lg select-none"
          sideOffset={5}
        >
          {label}
          <Tooltip.Arrow className="fill-gray-800" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
)

import { ChevronDown, ChevronUp, GripVertical } from "lucide-react"
import { useCallback, useEffect, useRef, useState } from "react"

interface Position {
  x: number
  y: number
}

interface DraggableControlContainerProps {
  containerRef: React.RefObject<HTMLDivElement>
  title: string
  initialTop?: number
  initialLeft?: number
  initialRight?: number
  className?: string
  contentClassName?: string
  initialCollapsed?: boolean
  onDragStart?: (position: Position) => void
  onDragMove?: (position: Position) => void
  onDragEnd?: (position: Position) => void
  onClickOutside?: () => void
  children: React.ReactNode
  onCollapsedChange?: (isCollapsed: boolean) => void
}

export const DraggableControlContainer = ({
  containerRef,
  title,
  initialTop = 20,
  initialLeft,
  initialRight = 20,
  className = "",
  contentClassName = "",
  onDragStart,
  onDragMove,
  onDragEnd,
  onClickOutside,
  children,
  initialCollapsed = false,
  onCollapsedChange = () => {},
}: DraggableControlContainerProps) => {
  initialTop = Math.max(150, initialTop)
  initialRight = Math.max(300, initialRight)

  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed)
  const calculateInitialX = useCallback(() => {
    if (!containerRef.current) return 0
    if (initialLeft !== undefined) return initialLeft
    if (initialRight !== undefined) {
      return containerRef.current.clientWidth - initialRight
    }
    return 0
  }, [containerRef, initialLeft, initialRight])

  const [position, setPosition] = useState<Position>({
    x: calculateInitialX(),
    y: initialTop,
  })
  const [isDragging, setIsDragging] = useState(false)
  const controlRef = useRef<HTMLDivElement>(null)
  const initialMousePos = useRef<Position>({ x: 0, y: 0 })
  const initialElementPos = useRef<Position>({ x: 0, y: 0 })
  const contentRef = useRef<HTMLDivElement>(null)
  const innerContentRef = useRef<HTMLDivElement>(null)

  // Add effect to handle content height
  useEffect(() => {
    if (contentRef.current && innerContentRef.current) {
      const height = isCollapsed ? 0 : innerContentRef.current.scrollHeight
      contentRef.current.style.height = `${height}px`
    }
  }, [isCollapsed, children])

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    // Ignore if clicking the toggle button
    if ((e.target as HTMLElement).closest(".toggle-button")) return

    e.preventDefault()
    if (!controlRef.current) return

    setIsDragging(true)
    initialMousePos.current = { x: e.clientX, y: e.clientY }
    initialElementPos.current = { ...position }

    onDragStart?.(position)
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !containerRef.current || !controlRef.current) return

      const deltaX = e.clientX - initialMousePos.current.x
      const deltaY = e.clientY - initialMousePos.current.y

      const containerRect = containerRef.current.getBoundingClientRect()
      const controlRect = controlRef.current.getBoundingClientRect()

      const newX = Math.max(
        0,
        Math.min(
          initialElementPos.current.x + deltaX,
          containerRect.width - controlRect.width,
        ),
      )
      const newY = Math.max(
        0,
        Math.min(
          initialElementPos.current.y + deltaY,
          containerRect.height - controlRect.height,
        ),
      )

      const newPosition = { x: newX, y: newY }
      setPosition(newPosition)
      onDragMove?.(newPosition)
    },
    [isDragging, containerRef, onDragMove],
  )

  const handleMouseUp = useCallback(() => {
    if (isDragging) {
      setIsDragging(false)
      onDragEnd?.(position)
    }
  }, [isDragging, onDragEnd, position])

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  useEffect(() => {
    if (!onClickOutside) return

    const handleClickOutside = (event: MouseEvent) => {
      if (
        controlRef.current &&
        !controlRef.current.contains(event.target as Node)
      ) {
        onClickOutside()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [onClickOutside])

  const toggleCollapse = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsCollapsed(prev => {
      const newValue = !prev
      onCollapsedChange?.(newValue)
      return newValue
    })
  }

  return (
    <div
      ref={controlRef}
      className={`control-container absolute bg-white shadow-lg border rounded-lg select-none ${
        isDragging ? "cursor-grabbing" : ""
      } ${className}`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        touchAction: "none",
        userSelect: "none",
        zIndex: 30,
      }}
    >
      <div
        className="flex items-center justify-between px-3 py-2 border-b bg-gray-50/50 cursor-grab active:cursor-grabbing"
        onMouseDown={handleMouseDown}
      >
        <div className="flex items-center space-x-2">
          <GripVertical className="w-4 h-4 text-gray-400 flex-shrink-0" />
          <h4 className="font-medium text-xs text-gray-700">{title}</h4>
        </div>
        <button
          className="toggle-button p-1 hover:bg-gray-100 rounded-md transition-colors"
          onClick={toggleCollapse}
          title={isCollapsed ? "Expand" : "Collapse"}
        >
          {isCollapsed ? (
            <ChevronDown className="w-4 h-4 text-gray-500" />
          ) : (
            <ChevronUp className="w-4 h-4 text-gray-500" />
          )}
        </button>
      </div>
      <div
        ref={contentRef}
        className="overflow-hidden transition-[height] duration-200 ease-in-out"
        style={{ height: "auto" }} // Initialize with auto height
      >
        <div ref={innerContentRef} className={`p-3 ${contentClassName}`}>
          {children}
        </div>
      </div>
    </div>
  )
}

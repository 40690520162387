import PlotViewer from "@/components/charts/plots/v2/PlotViewer"
import { getZoomParams } from "@/components/charts/util"
import {
  doZoomOutRequest,
  loadChart,
  loadDatasources,
  loadPlots,
  resetChart,
  selectChart,
  selectPlot,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { ZoomOut } from "lucide-react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

export const ViewPlot = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const plotId = params.plotId!
  const plot = useAppSelector(selectPlot(plotId))

  const urlParams = new URLSearchParams(window.location.search)
  const isScreenshot = urlParams.get("screenshot") === "true"

  const chart = useAppSelector(selectChart)
  useEffect(() => {
    if (params.id) {
      dispatch(loadChart(params.id))
      dispatch(loadPlots({ chartId: params.id, zoom: getZoomParams() }))
      dispatch(loadDatasources(params.id))
    }

    return () => {
      dispatch(resetChart())
    }
  }, [dispatch, params.id])

  const onContainerDoubleClicked = (e: React.MouseEvent) => {
    e.stopPropagation()
    zoomOut()
  }

  const zoomOut = () => {
    dispatch(
      doZoomOutRequest({
        plotId: plotId,
      }),
    )
  }

  if (!chart) {
    return <div></div>
  }

  return (
    <div
      id="plot-viewer"
      className="w-full h-[600px] relative"
      onDoubleClick={onContainerDoubleClicked}
    >
      <PlotViewer plotId={plotId} hideBoxes={true} readonly={true} />

      {plot && plot.zoom && plot.zoom.x_min && !isScreenshot && (
        <button
          onClick={zoomOut}
          className="p-2 text-gray-500 hover:text-blue-500 transition-colors duration-200 absolute top-4 right-4"
        >
          <ZoomOut size={16} />
        </button>
      )}
    </div>
  )
}

import {
  TASK_ANNOTATION,
  type Annotation,
  type AnnotationArrow,
  type AnnotationText,
  type Task,
} from "@/model"
import {
  addAnnotation,
  deleteAnnotation,
  selectPlot,
  setActiveLocalTask,
  updateAnnotation,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import type { ScaleLinear } from "d3-scale"
import { X } from "lucide-react"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDebouncedCallback } from "use-debounce"
import { v4 as uuidv4 } from "uuid"
import { newCompId } from "../../util"
import { Circle } from "./annotation/Circle"
import { useAnnotations } from "./useAnnotations"

interface AnnotationsProps {
  plotId: string
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  innerWidth: number
  innerHeight: number
  task: Task
}

export const Annotations = ({
  plotId,
  xScale,
  yScale,
  innerWidth,
  innerHeight,
  task,
}: AnnotationsProps) => {
  const dispatch = useAppDispatch()
  const plot = useAppSelector(selectPlot(plotId))
  const annotations = plot.annotations || []
  const [activeAnnotationId, setActiveAnnotationId] = useState<
    string | undefined
  >()
  const [editText, setEditText] = useState("")
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const annotationBoxRef = useRef<HTMLDivElement>(null)
  const { renderArrow, getMousePosition } = useAnnotations({
    xScale,
    yScale,
    innerWidth,
    innerHeight,
  })

  const [arrowInProgress, setArrowInProgress] = useState<{
    id: string
    from_x: number
    from_y: number
    to_x: number
    to_y: number
    color: string
  } | null>(null)

  const debouncedHandleAnnotationChange = useDebouncedCallback(
    (annotationId: string, text: string) => {
      const ann = annotations.find(a => a.id === annotationId)!
      const data = { ...ann.data, text }
      dispatch(updateAnnotation({ plotId, annotationId, data }))
    },
    300,
  )

  const handleAnnotationChange = (annotationId: string, text: string) => {
    setEditText(text)
    debouncedHandleAnnotationChange(annotationId, text)
  }

  const handleAnnotationBlur = () => {
    if (activeAnnotationId) {
      if (editText === "") {
        dispatch(deleteAnnotation({ plotId, annotationId: activeAnnotationId }))
      } else {
        debouncedHandleAnnotationChange(activeAnnotationId, editText)
      }
    }
    setEditText("")
    setActiveAnnotationId(undefined)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && activeAnnotationId) {
      handleAnnotationBlur()
    }
  }

  const handleDeleteAnnotation = (
    e: React.MouseEvent,
    annotationId: string,
  ) => {
    e.stopPropagation()
    dispatch(deleteAnnotation({ plotId, annotationId }))
    if (annotationId === activeAnnotationId) {
      setActiveAnnotationId(undefined)
    }
  }

  const handleTextAnnotation = useCallback(
    (x: number, y: number) => {
      if (activeAnnotationId) {
        return
      }

      const id = uuidv4()
      setEditText("")
      dispatch(
        addAnnotation({
          plotId,
          annotation: {
            id,
            type: "text",
            data: {
              x: xScale.invert(x),
              y: yScale.invert(y),
              text: "",
            },
          },
        }),
      )
      setActiveAnnotationId(id)
    },
    [dispatch, plotId, xScale, yScale, activeAnnotationId],
  )

  const handleArrowStart = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      const { x, y } = getMousePosition(event)
      const id = uuidv4()
      setArrowInProgress({
        id,
        from_x: xScale.invert(x),
        from_y: yScale.invert(y),
        to_x: xScale.invert(x),
        to_y: yScale.invert(y),
        color: "red",
      })
    },
    [getMousePosition, setArrowInProgress, xScale, yScale],
  )

  const handleArrowMove = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (arrowInProgress) {
        const { x, y } = getMousePosition(event)
        setArrowInProgress({
          ...arrowInProgress,
          to_x: xScale.invert(x),
          to_y: yScale.invert(y),
        })
      }
    },
    [arrowInProgress, xScale, yScale, setArrowInProgress, getMousePosition],
  )

  const handleArrowComplete = useCallback(() => {
    if (arrowInProgress) {
      dispatch(
        addAnnotation({
          plotId,
          annotation: {
            id: arrowInProgress.id,
            type: "arrow",
            data: {
              from_x: arrowInProgress.from_x,
              from_y: arrowInProgress.from_y,
              to_x: arrowInProgress.to_x,
              to_y: arrowInProgress.to_y,
              color: arrowInProgress.color,
            },
          },
        }),
      )
      setArrowInProgress(null)
    }
  }, [arrowInProgress, dispatch, plotId])

  const handleAnnotationClick = (id: string, text: string) => {
    setEditText(text)
    setActiveAnnotationId(id)
  }

  const handleChartClick = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (task.task !== TASK_ANNOTATION) {
        return
      }

      const { x, y } = getMousePosition(event)

      if (task.subtask === "text") {
        handleTextAnnotation(x, y)
      }

      if (task.subtask === "arrow") {
        if (arrowInProgress) {
          handleArrowComplete()
        } else {
          handleArrowStart(event)
        }
      }
    },
    [
      arrowInProgress,
      task,
      getMousePosition,
      handleTextAnnotation,
      handleArrowStart,
      handleArrowComplete,
    ],
  )

  const handleArrowClick = useCallback(
    (e: React.MouseEvent, ann: Annotation) => {
      e.stopPropagation()
      const data = ann.data as AnnotationArrow
      dispatch(
        setActiveLocalTask({
          id: ann.id,
          type: "annotation_arrow",
          x: xScale(data.from_x),
          y: yScale(data.from_y),
          data: {
            plotId: plotId,
          },
        }),
      )
    },
    [dispatch, plotId, xScale, yScale],
  )

  const handleChartMouseMove = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (task.subtask === "arrow" && arrowInProgress) {
        handleArrowMove(event)
      }
    },
    [task.subtask, arrowInProgress, handleArrowMove],
  )

  // New function to handle Esc key press
  const handleEscKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        if (activeAnnotationId) {
          setActiveAnnotationId(undefined)
          setEditText("")
        }
        if (arrowInProgress) {
          setArrowInProgress(null)
        }
      }
    },
    [activeAnnotationId, arrowInProgress],
  )

  // Add event listener for Esc key
  useEffect(() => {
    document.addEventListener("keydown", handleEscKeyPress)
    return () => {
      document.removeEventListener("keydown", handleEscKeyPress)
    }
  }, [handleEscKeyPress])

  return (
    <g onClick={handleChartClick} onMouseMove={handleChartMouseMove}>
      <defs>
        <clipPath id="plot-area">
          <rect x="0" y="0" width={innerWidth} height={innerHeight} />
        </clipPath>
      </defs>

      <rect
        x={0}
        y={0}
        width={innerWidth}
        height={innerHeight}
        fill="transparent"
        pointerEvents={task.task === TASK_ANNOTATION ? "all" : "none"} // Disable pointer events when not in annotation mode
      />

      <g clipPath="url(#plot-area)">
        {annotations.map(ann => {
          if (ann.type === "text" && activeAnnotationId !== ann.id) {
            const textData = ann.data as AnnotationText
            if (!textData) {
              return null
            }

            const x = xScale(textData.x)
            const y = yScale(textData.y)
            if (isNaN(x) || isNaN(y)) {
              return null
            }

            return (
              <foreignObject
                id={ann.id}
                key={`text-${ann.id}`}
                x={x + 10}
                y={y - 10}
                width="100"
                height="50"
              >
                <div
                  style={{
                    fontSize: "12px",
                    color: "black",
                    pointerEvents: "none",
                  }}
                >
                  {textData.text}
                </div>
              </foreignObject>
            )
          }
          if (ann.type === "arrow") {
            const arrowData = ann.data as AnnotationArrow
            return (
              <g
                className={newCompId(ann.id)}
                key={`arrow-${ann.id}`}
                style={{ cursor: "pointer" }}
                onClick={e => handleArrowClick(e, ann)}
              >
                {renderArrow(arrowData)}
              </g>
            )
          }
          return null
        })}

        {annotations.map(ann => {
          if (ann.type === "text") {
            const textData = ann.data as AnnotationText
            if (!textData) {
              return null
            }

            const cx = xScale(textData.x)
            const cy = yScale(textData.y)
            if (isNaN(cx) || isNaN(cy)) {
              return null
            }

            return (
              <Circle
                key={`dot-${ann.id}`}
                annotation={ann}
                x={cx}
                y={cy}
                isActive={activeAnnotationId === ann.id}
                task={task}
                onClick={handleAnnotationClick}
              />
            )
          }
          return null
        })}

        {arrowInProgress && renderArrow(arrowInProgress)}
      </g>

      {activeAnnotationId && (
        <foreignObject
          x={
            xScale(
              (
                annotations.find(a => a.id === activeAnnotationId)
                  ?.data as AnnotationText
              ).x,
            ) - 100
          }
          y={yScale(
            (
              annotations.find(a => a.id === activeAnnotationId)
                ?.data as AnnotationText
            ).y,
          )}
          width="200"
          height="100"
        >
          <div ref={annotationBoxRef} className="p-4">
            <div className="relative">
              <textarea
                ref={inputRef}
                value={editText}
                onChange={e =>
                  handleAnnotationChange(activeAnnotationId, e.target.value)
                }
                onKeyDown={handleKeyDown}
                className="w-full border border-gray-300 rounded px-2 py-1 text-sm outline-none resize-none bg-white"
                onClick={e => e.stopPropagation()}
              />
              <button
                onClick={e => handleDeleteAnnotation(e, activeAnnotationId)}
                className="absolute -top-1 -right-1 bg-white w-3 h-3 text-red-500 border border-red-500 rounded-full hover:text-white hover:bg-red-600 transition-colors flex items-center justify-center"
              >
                <X size={8} />
              </button>
            </div>
          </div>
        </foreignObject>
      )}
    </g>
  )
}

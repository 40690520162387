import { selectBreadcrumbs, useAppSelector } from "@/store"
import { ChevronRight } from "lucide-react"
import { Link } from "react-router-dom"

export const Breadcrumbs = () => {
  const breadcrumbs = useAppSelector(selectBreadcrumbs) || []
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {breadcrumbs.map((crumb, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index > 0 && (
                <ChevronRight
                  className="flex-shrink-0 h-3 w-3 text-gray-400"
                  aria-hidden="true"
                />
              )}
              {crumb.path && (
                <Link
                  to={crumb.path}
                  className={`${
                    index > 0 ? "ml-4" : ""
                  } text-sm font-medium text-gray-500 hover:text-gray-700`}
                >
                  {crumb.label}
                </Link>
              )}
              {!crumb.path && (
                <span
                  className={`${
                    index > 0 ? "ml-4" : ""
                  } text-sm font-medium text-gray-500`}
                >
                  {crumb.label}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

import {
  selectActivePlotId,
  selectPlots,
  useAppSelector
} from "@/store"
import { PlotConfig } from "./PlotConfig"

export const PlotsConfig = () => {
  const plots = useAppSelector(selectPlots)
  const activePlotId = useAppSelector(selectActivePlotId)

  return (
    plots && (
      <>
        {(plots || [])
          .filter(p => p.id === activePlotId)
          .map(plot => (
            <PlotConfig key={plot.id} id={plot.id} plotConfig={plot} />
          ))}
      </>
    )
  )
}

import type { Task } from "@/model"
import { ArrowUpRight, Type } from "lucide-react"
import { SubtaskButton } from "../SubtaskButton"

export const AnnotationSubToolbar = ({
  taskId,
  onChange,
}: {
  taskId: string
  onChange: (task: Task) => void
}) => {
  return (
    <div className="flex flex-col gap-1">
      <SubtaskButton
        taskId={taskId}
        subtask={{
          id: "text",
          icon: Type,
          label: "Text Annotation",
        }}
        onClick={onChange}
      />
      <SubtaskButton
        taskId={taskId}
        subtask={{
          id: "arrow",
          icon: ArrowUpRight,
          label: "Add an arrow",
        }}
        onClick={onChange}
      />
    </div>
  )
}

import { forwardRef, useCallback, useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

export interface TextFieldProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "value"
  > {
  error?: string
  variant?: "default" | "compact"
  className?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  debounceTime?: number
  selectAllOnFocus?: boolean
  value?: string | number
}

const getVariantStyles = (
  variant: "default" | "compact",
  disabled: boolean,
  error?: string,
) => {
  const baseStyles = disabled
    ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
    : "bg-white text-gray-700 border-gray-300 focus:outline-none"
  const errorStyles = error
    ? "border-red-300 focus:ring-red-500 focus:border-red-500"
    : ""
  const variants = {
    default: {
      input: `inline-flex w-full px-4 py-2 text-xs font-medium border rounded-md shadow-sm ${baseStyles} ${errorStyles}`,
      error: "mt-1 text-xs text-red-500",
    },
    compact: {
      input: `inline-flex w-full px-2 py-1 text-xs font-medium border rounded-md shadow-sm ${baseStyles} ${errorStyles}`,
      error: "mt-0.5 text-[10px] text-red-500",
    },
  }
  return variants[variant]
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      error,
      disabled = false,
      className = "",
      variant = "default",
      onChange,
      value,
      debounceTime = 300,
      selectAllOnFocus = false,
      ...props
    },
    ref,
  ) => {
    const styles = getVariantStyles(variant, disabled, error)
    const [internalValue, setInternalValue] = useState<string | number>(
      value ?? "",
    )

    const debouncedOnChange = useDebouncedCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(e)
      },
      debounceTime,
    )

    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setInternalValue(newValue)
        debouncedOnChange(e)
      },
      [debouncedOnChange],
    )

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (selectAllOnFocus) {
          e.target.select()
        }
        props.onFocus?.(e)
      },
      [selectAllOnFocus, props.onFocus],
    )

    useEffect(() => {
      if (value !== undefined) {
        setInternalValue(value)
      }
    }, [value])

    return (
      <div className={`${className}`}>
        <input
          ref={ref}
          disabled={disabled}
          value={internalValue}
          onChange={handleChange}
          onFocus={handleFocus}
          className={
            styles.input +
            " " +
            `[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`
          }
          {...props}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
    )
  },
)

TextField.displayName = "TextField"

import { type PayloadAction, createSlice } from "@reduxjs/toolkit"

interface DragDropState {
  currentItem?: any
}

const initialState: DragDropState = {}

export const dragDropSlice = createSlice({
  name: "dragdrop",
  initialState,
  reducers: {
    setCurrentDragDropItem: (state, action: PayloadAction<any>) => {
      state.currentItem = action.payload
    },
    deleteCurrentDragDropItem: state => {
      delete state.currentItem
    },
  },
})

export const { setCurrentDragDropItem, deleteCurrentDragDropItem } =
  dragDropSlice.actions

export default dragDropSlice.reducer

export const selectCurrentDragDropItem = (state: { dragdrop: DragDropState }) =>
  state.dragdrop.currentItem

import * as Label from "@radix-ui/react-label"
import * as RSelect from "@radix-ui/react-select"
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react"
import { useEffect, useRef, ReactNode } from "react"

export interface SelectItem {
  name: string | ReactNode
  value: string
}

export interface SelectGroup {
  name: string
  items: SelectItem[]
}

export interface SelectProps {
  name: string
  value?: string
  id?: string
  emptyText?: string
  label?: string
  items: (SelectItem | SelectGroup)[]
  onChange?: (value: string) => void
  disabled?: boolean
  className?: string
  variant?: "default" | "compact"
}

const isGroup = (item: SelectItem | SelectGroup): item is SelectGroup => {
  return "items" in item
}

const renderItemContent = (content: string | ReactNode) => {
  if (typeof content === "string") {
    return content
  }
  return content
}

const getVariantStyles = (
  variant: "default" | "compact",
  disabled: boolean,
) => {
  const baseStyles = disabled
    ? "bg-gray-100 text-gray-400 border-gray-200 cursor-not-allowed"
    : "bg-white text-gray-700 border-gray-300 hover:bg-gray-50 focus:outline-none"

  const variants = {
    default: {
      trigger: `inline-flex items-center justify-between w-full px-4 py-2 text-xs font-medium border rounded-md shadow-sm ${baseStyles}`,
      content:
        "overflow-hidden bg-gray-100 border border-gray-300 rounded-md shadow-lg -mt-[1px]",
      item: "relative flex items-center px-4 py-2 text-xs text-gray-900 cursor-pointer select-none hover:bg-gray-100 focus:bg-gray-100 focus:outline-none",
      groupLabel: "px-4 py-2 text-xs font-semibold text-gray-900 bg-gray-100",
      groupItem:
        "relative flex items-center pl-6 pr-4 py-2 text-xs text-gray-900 cursor-pointer select-none hover:bg-gray-100 focus:bg-gray-100 focus:outline-none",
    },
    compact: {
      trigger: `inline-flex items-center justify-between w-full px-2 py-1 text-xs font-medium border rounded-md shadow-sm ${baseStyles}`,
      content:
        "overflow-hidden bg-gray-100 border border-gray-300 rounded-md shadow-lg -mt-[1px] min-w-[120px]",
      item: "relative flex items-center px-2 py-1 text-xs text-gray-900 cursor-pointer select-none hover:bg-gray-100 focus:bg-gray-100 focus:outline-none",
      groupLabel: "px-2 py-1 text-xs font-semibold text-gray-900 bg-gray-100",
      groupItem:
        "relative flex items-center pl-4 pr-2 py-1 text-xs text-gray-900 cursor-pointer select-none hover:bg-gray-100 focus:bg-gray-100 focus:outline-none",
    },
  }

  return variants[variant]
}

export const Select: React.FC<SelectProps> = ({
  name,
  label,
  items,
  id,
  emptyText = "Select",
  value,
  onChange,
  disabled = false,
  className = "",
  variant = "default",
}) => {
  const findSelectedItem = (
    items: (SelectItem | SelectGroup)[],
  ): SelectItem | undefined => {
    for (const item of items) {
      if (isGroup(item)) {
        const found = item.items.find(subItem => subItem.value === value)
        if (found) return found
      } else if (item.value === value) {
        return item
      }
    }
    return undefined
  }

  const selectedItem = findSelectedItem(items)
  const viewportRef = useRef<HTMLDivElement>(null)
  const styles = getVariantStyles(variant, disabled)

  useEffect(() => {
    if (viewportRef.current && value) {
      const selectedElement = viewportRef.current.querySelector(
        `[data-value="${value}"]`,
      )
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "nearest", behavior: "auto" })
      }
    }
  }, [value])

  return (
    <div
      className={`flex flex-col ${variant === "default" ? "space-y-2 max-w-sm" : "space-y-1"} mx-auto text-xs ${className}`}
    >
      {label && (
        <Label.Root
          className={`font-semibold ${
            disabled ? "text-gray-400" : "text-gray-700"
          } ${variant === "compact" ? "text-[10px]" : "text-xs"}`}
          htmlFor={id || name}
        >
          {label}
        </Label.Root>
      )}

      <RSelect.Root
        name={name}
        value={value}
        onValueChange={onChange}
        disabled={disabled}
      >
        <RSelect.Trigger
          className={`${styles.trigger} data-[state=open]:bg-gray-100 data-[state=open]:rounded-b-none transition-colors duration-200`}
        >
          <div className="flex-grow text-left overflow-hidden">
            {selectedItem?.name ? (
              <RSelect.Value>
                {renderItemContent(selectedItem.name)}
              </RSelect.Value>
            ) : (
              <span className="text-gray-500">{emptyText}</span>
            )}
          </div>
          <RSelect.Icon
            className={`flex-shrink-0 ${variant === "compact" ? "ml-1" : "ml-2"}`}
          >
            <ChevronDownIcon
              className={`${variant === "compact" ? "w-4 h-4" : "w-5 h-5"} ${
                disabled ? "text-gray-300" : "text-gray-400"
              } transition-transform duration-200 data-[state=open]:rotate-180`}
            />
          </RSelect.Icon>
        </RSelect.Trigger>

        {!disabled && (
          <RSelect.Portal>
            <RSelect.Content
              className={`${styles.content} z-[9999] control-container`}
              position="popper"
              sideOffset={0}
              align="start"
              alignOffset={0}
            >
              <RSelect.ScrollUpButton
                className={`flex justify-center ${variant === "compact" ? "h-4" : "h-6"} bg-gray-100 text-gray-700 cursor-default`}
              >
                <ChevronUpIcon
                  className={variant === "compact" ? "w-4 h-4" : "w-5 h-5"}
                />
              </RSelect.ScrollUpButton>
              <RSelect.Viewport
                ref={viewportRef}
                className="bg-white max-h-60 overflow-y-auto"
              >
                {items.map((item, idx) =>
                  isGroup(item) ? (
                    <RSelect.Group key={idx}>
                      <RSelect.Label className={styles.groupLabel}>
                        {item.name}
                      </RSelect.Label>
                      {item.items.map((subItem, subIdx) => (
                        <RSelect.Item
                          key={`${idx}-${subIdx}`}
                          value={subItem.value}
                          className={styles.groupItem}
                          data-value={subItem.value}
                        >
                          <RSelect.ItemText>
                            {renderItemContent(subItem.name)}
                          </RSelect.ItemText>
                        </RSelect.Item>
                      ))}
                    </RSelect.Group>
                  ) : (
                    <RSelect.Item
                      key={idx}
                      value={item.value}
                      className={styles.item}
                      data-value={item.value}
                    >
                      <RSelect.ItemText>
                        {renderItemContent(item.name)}
                      </RSelect.ItemText>
                    </RSelect.Item>
                  ),
                )}
              </RSelect.Viewport>
              <RSelect.ScrollDownButton
                className={`flex justify-center ${variant === "compact" ? "h-4" : "h-6"} bg-gray-100 text-gray-700 cursor-default`}
              >
                <ChevronDownIcon
                  className={variant === "compact" ? "w-4 h-4" : "w-5 h-5"}
                />
              </RSelect.ScrollDownButton>
            </RSelect.Content>
          </RSelect.Portal>
        )}
      </RSelect.Root>
    </div>
  )
}

export default Select

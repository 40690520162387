import { forwardRef } from "react"

interface IconButtonProps {
  icon: React.ReactNode
  text?: string
  size?: "small" | "medium" | "large"
  variant?: "primary" | "secondary" | "ghost" | "danger"
  as?: React.ElementType
  className?: string
  [key: string]: any // This allows for spreading additional props
}

export const IconButton = forwardRef<HTMLElement, IconButtonProps>(
  (
    {
      as: Component = "button",
      icon,
      text,
      size = "medium",
      variant = "ghost",
      className = "",
      ...props
    },
    ref,
  ) => {
    const sizeClasses = {
      small: "p-1 text-xs",
      medium: "p-2 text-sm",
      large: "p-3 text-base",
    }

    const variantClasses = {
      primary: "bg-blue-500 text-white",
      secondary: "bg-gray-200 hover:text-blue-500 text-gray-800",
      ghost: "hover:text-blue-500 text-gray-600",
      danger: "hover:text-red-500 text-gray-400",
    }

    const baseClasses =
      "inline-flex items-center justify-center outline-none focus:outline-none transition-colors duration-200"
    const shapeClasses = text ? "rounded-md" : "rounded-full"

    const elementClasses = `${baseClasses} ${shapeClasses} ${sizeClasses[size]} ${variantClasses[variant]} ${className}`

    return (
      <Component ref={ref} className={elementClasses} {...props}>
        {icon}
        {text && <span className="ml-1">{text}</span>}
      </Component>
    )
  },
)

IconButton.displayName = "IconButton"

export default IconButton

import * as Tabs from "@radix-ui/react-tabs"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import { useEffect, useState } from "react"
import "react-loading-skeleton/dist/skeleton.css"
import { useNavigate, useParams } from "react-router-dom"

import { createChart, getChildDatasources, getDatasource } from "@/api"
import { EditDatasource as EditDatasourceComp } from "@/components/datasource/EditDatasource"
import type { Column, Datasource } from "@/model"
import { setBreadcrumbs, useAppDispatch } from "@/store"
import { Button } from "@/ui"

export const EditDatasource = ({
  mode = "datasource",
}: {
  mode?: "datasource" | "chart"
}) => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [datasource, setDatasource] = useState<Datasource>()
  const [datasources, setDatasources] = useState<
    {
      datasource: Datasource
      records: Record<string, any>[]
      columns: Column[]
      total: number
    }[]
  >()

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Datasources", path: "/datasources" },
        { label: "..." },
      ]),
    )
  }, [dispatch, params.id])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const resp = await getDatasource(params.id!)
        dispatch(
          setBreadcrumbs([
            { label: "Datasources", path: "/datasources" },
            { label: resp.datasource.name },
          ]),
        )

        if (resp.datasource.is_parent) {
          setDatasource(resp.datasource)
          // get all child datasources
          const childDatasources = await getChildDatasources(params.id!)
          setDatasources(childDatasources)
        } else {
          setDatasource(resp.datasource)
          setDatasources([resp])
        }
      } catch (error) {
        console.error("Error fetching datasource:", error)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [dispatch, params.id])

  const onCreateChart = async () => {
    const resp = await createChart(datasource?.name || "My Chart", [params.id!])
    navigate(`/charts/${resp.id}`, { replace: true })
  }

  if (loading) {
    return <div>Loadin...</div>
  }

  return (
    <div className="flex flex-col h-full p-6 pb-0">
      <div className="text-lg font-semibold mb-4 text-gray-800">
        {datasource?.name || ""}
      </div>
      <div className="flex-grow mb-6">
        {datasources && datasources.length > 1 ? (
          <Tabs.Root
            defaultValue={datasources[0].datasource.id}
            className="flex flex-col h-full"
          >
            <Tabs.List className="flex border-b mb-6">
              {datasources.map(ds => (
                <Tabs.Trigger
                  key={ds.datasource.id}
                  value={ds.datasource.id}
                  className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 focus:outline-none data-[state=active]:text-gray-800 data-[state=active]:border-b-2 data-[state=active]:border-gray-800"
                >
                  <div>{ds.datasource.name}</div>
                </Tabs.Trigger>
              ))}
            </Tabs.List>
            {datasources.map(ds => (
              <Tabs.Content
                key={ds.datasource.id}
                value={ds.datasource.id}
                className="flex-grow"
              >
                <EditDatasourceComp
                  datasource={ds.datasource}
                  records={ds.records}
                  total={ds.total}
                  columns={ds.columns}
                />
              </Tabs.Content>
            ))}
          </Tabs.Root>
        ) : (
          datasources?.map(ds => (
            <EditDatasourceComp
              key={ds.datasource.id}
              datasource={ds.datasource}
              records={ds.records}
              total={ds.total}
              columns={ds.columns}
            />
          ))
        )}
      </div>
      {datasource && datasource.is_pending && (
        <div className="flex justify-end my-4">
          <Button className="px-4 py-2 text-sm" onClick={onCreateChart}>
            Create Chart
          </Button>
        </div>
      )}
    </div>
  )
}

export default EditDatasource

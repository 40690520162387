import type {
  ActiveLocalTask,
  ActiveLocalTaskDataSeriesFunction,
} from "@/model"
import {
  addToast,
  deleteSeriesFunction,
  selectChart,
  selectSeriesFunction,
  useAppDispatch,
  useAppSelector
} from "@/store"
import { SimpleLoader } from "@/ui"
import { Images, Trash2 } from "lucide-react"
import { useState } from "react"
import { copyPlotToClipboardAsImage } from "../../util"
import { LocalButton } from "./LocalButton"

export const useSeriesFunctionToolbar = (
  localTask: ActiveLocalTask | undefined,
) => {
  const dispatch = useAppDispatch()
  const data = (localTask?.data as ActiveLocalTaskDataSeriesFunction) || {}
  const chart = useAppSelector(selectChart)
  const [isCopying, setIsCopying] = useState(false)

  const seriesFunction = useAppSelector(
    selectSeriesFunction(data.seriesId, data.seriesFunctionId),
  )

  if (!localTask || !seriesFunction) return null

  return (
    <div className="align-middle flex">
      {/* <LocalButton
        label={seriesFunction.style?.hidden ? "Show" : "Hide"}
        onClick={() => {
          dispatch(
            updateSeriesFunctionStyle({
              plotId: localTask.data.plotId,
              seriesId: (localTask.data as ActiveLocalTaskDataSeriesFunction)
                .seriesId,
              seriesFunctionId: localTask.id,
              style: {
                hidden: !seriesFunction.style?.hidden,
              },
            }),
          )
        }}
      >
        {seriesFunction.style?.hidden ? (
          <EyeOff className="w-4 h-4" />
        ) : (
          <Eye className="w-4 h-4" />
        )}
      </LocalButton> */}

      <LocalButton
        label="Copy as image"
        onClick={async () => {
          try {
            setIsCopying(true)
            await copyPlotToClipboardAsImage({
              chartId: chart!.id,
              plotId: localTask.data.plotId,
              zoom: seriesFunction.data,
            })
            dispatch(
              addToast({
                type: "success",
                message: "Plot copied to clipboard as image",
              }),
            )
          } catch (error) {
            dispatch(
              addToast({
                type: "error",
                message: "Failed to export image. Please contact support!",
              }),
            )
          } finally {
            setIsCopying(false)
          }
        }}
      >
        {isCopying ? <SimpleLoader /> : <Images className="w-4 h-4" />}
      </LocalButton>
      <LocalButton
        label="Delete"
        onClick={() => {
          dispatch(
            deleteSeriesFunction({
              plotId: localTask.data.plotId,
              seriesId: (localTask.data as ActiveLocalTaskDataSeriesFunction)
                .seriesId,
              seriesFunctionId: localTask.id,
            }),
          )
        }}
      >
        <Trash2 className="w-4 h-4" />
      </LocalButton>
    </div>
  )
}

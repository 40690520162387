import {
  FUNC_OSCILLATIONS,
  type Annotation,
  type AskAIChartResponse,
  type Chart,
  type DataPoint,
  type Datasource,
  type FunctionData,
  type PlotData,
  type PlotFunction,
  type PlotQuery,
  type ReferenceLine,
  type Series,
  type SeriesData,
  type SeriesFunction,
  type SeriesQuery,
} from "@/model"
import { authorisedFetch } from "./base"

interface Zoom {
  x_min?: number
  x_max?: number
  y_min?: number
  y_max?: number
}

interface Plot {
  id: string
  name: string
  serieses: Series[]
  functions: PlotFunction[]
  position?: number
  zoom?: Zoom
  reference_lines?: ReferenceLine[]
  annotations?: Annotation[]
  label_x: string
  label_y: string
}

export const getCharts = async (): Promise<Chart[]> => {
  return authorisedFetch("/charts")
}

export const getChart = async (chartId: string): Promise<Chart> => {
  return authorisedFetch(`/charts/${chartId}`)
}

export const updateChart = async (
  chartId: string,
  chart: Partial<Chart>,
): Promise<Chart> => {
  return authorisedFetch(`/charts/${chartId}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(chart),
  })
}
interface ResponseSeriesData {
  series_id: string
  columns: Record<string, string>
  data_points: [number, number][]
  functions_data?: {
    id: string
    data_points: [number, number][]
    type: string
    data: Record<string, any>
    boundary?: {
      x_min: number
      x_max: number
      y_min: number
      y_max: number
    }
  }[]
  error: string
}
interface ResponsePlotData {
  plot_id: string
  series_data: ResponseSeriesData[]
}

export const getYRange = async (
  chartId: string,
  plotId: string,
  xMin: number,
  xMax: number,
): Promise<[number, number]> => {
  const resp = await authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/y-range`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        x_min: xMin,
        x_max: xMax,
      }),
    },
  )

  return [resp.y_min, resp.y_max]
}

export const askChartAI = async (
  chartId: string,
  question: string,
): Promise<AskAIChartResponse> => {
  return authorisedFetch(`/charts/${chartId}/ask-ai`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ question }),
  })
}

interface OscillationStepData {
  type: "oscillations"
  data: {
    stability: {
      moving_average_points: [number, number][]
    }
  }
}

export const queryPlotsDatas = async (
  chartId: string,
  queries: PlotQuery[],
): Promise<PlotData[]> => {
  const queryPromises = queries.map(query =>
    authorisedFetch(`/charts/${chartId}/query`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(query),
    }),
  )

  const plotsData: ResponsePlotData[] = await Promise.all(queryPromises)

  return plotsData.map(plotData => {
    const series_data: SeriesData[] = (plotData.series_data || []).map(
      seriesData => {
        const dataPoints: DataPoint[] = (seriesData.data_points || []).map(
          ([x, y]) => ({
            x,
            y,
          }),
        )

        const functionsData = (seriesData.functions_data || []).map(
          funcData => {
            const subFuncData = funcData.data || {}
            if (subFuncData.steps) {
              subFuncData.steps = subFuncData.steps.map(
                (step: {
                  type: string
                  data_points?: [number, number][]
                  [key: string]: any
                }) => {
                  // hack, better option
                  if (step.type === FUNC_OSCILLATIONS) {
                    const osStep = step as OscillationStepData
                    const osStepData = osStep.data
                    console.log(osStepData)

                    const moving_average_points =
                      osStepData.stability.moving_average_points.map(
                        ([x, y]) => ({
                          x,
                          y,
                        }),
                      )

                    return {
                      ...osStep,
                      data: {
                        ...osStep.data,
                        stability: {
                          ...osStep.data.stability,
                          moving_average_points,
                        },
                      },
                    }
                  }

                  const points = (step.data_points || []).map(([x, y]) => ({
                    x,
                    y,
                  }))
                  return { ...step, data_points: points }
                },
              )
            }

            return {
              id: funcData.id,
              data_points: funcData.data_points.map(([x, y]) => ({
                x,
                y,
              })),
              type: funcData.type,
              data: subFuncData,
              boundary: funcData.boundary,
            } as FunctionData
          },
        )

        return {
          data_points: dataPoints || [],
          functions_data: functionsData,
          series_id: seriesData.series_id,
          error: seriesData.error,
        } as SeriesData
      },
    )

    return {
      plot_id: plotData.plot_id,
      series_data,
      error: "",
    }
  })
}

export const getChartDatasources = async (
  chartId: string,
): Promise<Record<string, Datasource>> => {
  return authorisedFetch(`/charts/${chartId}/datasources`)
}

export const getChartPlots = async (chartId: string): Promise<Plot[]> => {
  return authorisedFetch(`/charts/${chartId}/plots`)
}

export const deleteChart = async (chartId: string): Promise<void> => {
  return authorisedFetch(`/charts/${chartId}`, { method: "DELETE" })
}

export const createChart = async (
  name: string,
  datasourceIds: string[],
  addPlot = false,
): Promise<Chart> => {
  return authorisedFetch("/charts/new", {
    method: "POST",
    body: JSON.stringify({
      name,
      datasources: datasourceIds,
      add_plot: addPlot,
    }),
  })
}

export const attachChartDatasources = async (
  chartId: string,
  datasourceIds: string[],
): Promise<Chart> => {
  return authorisedFetch(`/charts/${chartId}/datasources`, {
    method: "POST",
    body: JSON.stringify({
      datasources: datasourceIds,
    }),
  })
}

export const removeChartDatasource = async (
  chartId: string,
  datasourceId: string,
): Promise<Chart> => {
  return authorisedFetch(`/charts/${chartId}/datasources/${datasourceId}`, {
    method: "DELETE",
  })
}

export const findAbnormalBoxes = async (
  chartId: string,
  plotId: string,
  data: {
    series: SeriesQuery
  },
): Promise<
  {
    x_min: number
    x_max: number
    y_min: number
    y_max: number
  }[]
> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/find-abnormal-boxes`,
    {
      method: "POST",
      body: JSON.stringify(data),
    },
  )
}

export const downloadImage = async ({
  chartId,
  plotId,
  zoom = {},
}: {
  chartId: string
  plotId: string
  zoom?: Zoom
}) => {
  const params = new URLSearchParams()
  params.append("screenshot", "true")
  if (zoom.x_min !== undefined) {
    params.append("x_min", zoom.x_min.toString())
  }
  if (zoom.x_max !== undefined) {
    params.append("x_max", zoom.x_max.toString())
  }
  if (zoom.y_min !== undefined) {
    params.append("y_min", zoom.y_min.toString())
  }
  if (zoom.y_max !== undefined) {
    params.append("y_max", zoom.y_max.toString())
  }

  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/image?${params.toString()}`,
    {
      method: "GET",
    },
    async (response: Response) => response,
  )
}

export const addPlot = async (chartId: string, plot: Plot): Promise<Plot> => {
  return authorisedFetch(`/charts/${chartId}/plots`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(plot),
  })
}

export const updatePlot = async (
  chartId: string,
  plotId: string,
  plot: Partial<Plot>,
) => {
  return authorisedFetch(`/charts/${chartId}/plots/${plotId}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(plot),
  })
}

export const deletePlot = async (
  chartId: string,
  plotId: string,
): Promise<void> => {
  return authorisedFetch(`/charts/${chartId}/plots/${plotId}`, {
    method: "DELETE",
  })
}

export const updatePlotPositions = async (
  chartId: string,
  plotIds: string[],
): Promise<void> => {
  const positions = plotIds.reduce(
    (acc, id, index) => {
      acc[id] = index
      return acc
    },
    {} as { [key: string]: number },
  )

  return authorisedFetch(`/charts/${chartId}/plot-positions`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ positions }),
  })
}

export const addSeries = async (
  chartId: string,
  plotId: string,
  series: Series,
): Promise<Series> => {
  return authorisedFetch(`/charts/${chartId}/plots/${plotId}/series`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(series),
  })
}

export const updateSeries = async (
  chartId: string,
  plotId: string,
  series: Partial<Series>,
): Promise<Series> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/series/${series.id}`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(series),
    },
  )
}

export const deleteSeries = async (
  chartId: string,
  plotId: string,
  seriesId: string,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/series/${seriesId}`,
    { method: "DELETE" },
  )
}

export const addReferenceLine = async (
  chartId: string,
  plotId: string,
  referenceLine: ReferenceLine,
): Promise<ReferenceLine> => {
  return authorisedFetch(`/charts/${chartId}/plots/${plotId}/reference-lines`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(referenceLine),
  })
}

export const deleteReferenceLine = async (
  chartId: string,
  plotId: string,
  referenceLineId: string,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/reference-lines/${referenceLineId}`,
    { method: "DELETE" },
  )
}

export const addAnnotation = async (
  chartId: string,
  plotId: string,
  annotation: Annotation,
): Promise<Annotation> => {
  return authorisedFetch(`/charts/${chartId}/plots/${plotId}/annotations`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(annotation),
  })
}

export const updateAnnotation = async (
  chartId: string,
  plotId: string,
  annotationId: string,
  annotation: Partial<Annotation>,
): Promise<Annotation> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/annotations/${annotationId}`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(annotation),
    },
  )
}

export const deleteAnnotation = async (
  chartId: string,
  plotId: string,
  annotationId: string,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/annotations/${annotationId}`,
    { method: "DELETE" },
  )
}

export const createSeriesFunction = async (
  chartId: string,
  plotId: string,
  seriesId: string,
  data: SeriesFunction,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/series/${seriesId}/functions`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    },
  )
}

export const updateSeriesFunction = async (
  chartId: string,
  plotId: string,
  seriesId: string,
  seriesFunctionId: string,
  data: Partial<SeriesFunction>,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/series/${seriesId}/functions/${seriesFunctionId}`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    },
  )
}

export const deleteSeriesFunction = async (
  chartId: string,
  plotId: string,
  seriesId: string,
  functionId: string,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/series/${seriesId}/functions/${functionId}`,
    { method: "DELETE" },
  )
}

export const createPlotFunction = async (
  chartId: string,
  plotId: string,
  data: PlotFunction,
): Promise<void> => {
  return authorisedFetch(`/charts/${chartId}/plots/${plotId}/functions`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
}

export const updatePlotFunction = async (
  chartId: string,
  plotId: string,
  plotFunctionId: string,
  data: Partial<PlotFunction>,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/functions/${plotFunctionId}`,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    },
  )
}

export const deletePlotFunction = async (
  chartId: string,
  plotId: string,
  functionId: string,
): Promise<void> => {
  return authorisedFetch(
    `/charts/${chartId}/plots/${plotId}/functions/${functionId}`,
    { method: "DELETE" },
  )
}

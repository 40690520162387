import type { ActiveLocalTask } from "@/model"
import {
    deleteAnnotation,
    selectAnnotation,
    updateAnnotation,
    useAppDispatch,
    useAppSelector,
} from "@/store"
import { Trash2 } from "lucide-react"
import { ColorPickerWrapper } from "./ColorPickerWrapper"
import { LocalButton } from "./LocalButton"

export const useAnnotationToolbar = (
  localToolbar: ActiveLocalTask | undefined,
) => {
  const dispatch = useAppDispatch()
  const annotation = useAppSelector(
    selectAnnotation(localToolbar?.data.plotId || "", localToolbar?.id || ""),
  )

  if (!localToolbar || !annotation) return null

  return (
    <>
      <LocalButton label="Color">
        <ColorPickerWrapper
          onChange={color => {
            dispatch(
              updateAnnotation({
                plotId: localToolbar.data.plotId,
                annotationId: localToolbar.id,
                data: { color },
              }),
            )
          }}
          color={annotation.data.color || "red"}
        />
      </LocalButton>
      <LocalButton
        label="Delete"
        onClick={() => {
          dispatch(
            deleteAnnotation({
              plotId: localToolbar.data.plotId,
              annotationId: localToolbar.id,
            }),
          )
        }}
      >
        <Trash2 className="w-4 h-4" />
      </LocalButton>
    </>
  )
}

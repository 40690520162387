import type { AnnotationArrow } from "@/model"
import type { ScaleLinear } from "d3-scale"
import { v4 as uuidv4 } from "uuid"
import { clipLine } from "./util"

export const useAnnotations = ({
  xScale,
  yScale,
  innerWidth,
  innerHeight,
}: {
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  innerWidth: number
  innerHeight: number
}) => {
  const getMousePosition = (
    event: React.MouseEvent<SVGElement>,
  ): { x: number; y: number } => {
    const rect = event.currentTarget.getBoundingClientRect()
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    }
  }

  const renderArrow = (arrowData: AnnotationArrow) => {
    const x1 = xScale(arrowData.from_x)
    const y1 = yScale(arrowData.from_y)
    const x2 = xScale(arrowData.to_x)
    const y2 = yScale(arrowData.to_y)

    if (isNaN(x1) || isNaN(y1) || isNaN(x2) || isNaN(y2)) {
      return null
    }

    const clippedLine = clipLine(x1, y1, x2, y2, 0, 0, innerWidth, innerHeight)

    if (!clippedLine) {
      return null // Line is completely outside the plot area
    }

    const [cx1, cy1, cx2, cy2] = clippedLine
    const isArrowheadClipped = cx2 !== x2 || cy2 !== y2
    const arrowId = uuidv4()

    return (
      <g>
        {/* Invisible, thicker line for easier clicking */}
        <line
          x1={cx1}
          y1={cy1}
          x2={cx2}
          y2={cy2}
          stroke="transparent"
          strokeWidth={10}
          style={{ cursor: "pointer" }}
        />
        {/* Visible arrow line */}
        <line
          x1={cx1}
          y1={cy1}
          x2={cx2}
          y2={cy2}
          stroke={arrowData.color}
          strokeWidth={2}
          markerEnd={isArrowheadClipped ? undefined : `url(#arrow-${arrowId})`}
          style={{ pointerEvents: "none" }}
        />

        <defs>
          <marker
            id={`arrow-${arrowId}`}
            markerWidth="10"
            markerHeight="7"
            refX="9"
            refY="3.5"
            orient="auto"
          >
            <polygon points="0 0, 10 3.5, 0 7" fill={arrowData.color} />
          </marker>
        </defs>
      </g>
    )
  }

  return { renderArrow, getMousePosition }
}

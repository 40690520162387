import type { DataPoint, SeriesData } from "@/model"
import type { ScaleLinear } from "d3-scale"
import { useCallback, useEffect, useRef, useState } from "react"

interface UseGuideLineHighlightProps {
  seriesData: SeriesData[]
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  guideLineX: number | undefined
  guideLineY: number | undefined
  threshold: number
}

interface HighlightPoint extends DataPoint {
  seriesIndex: number
}

const binarySearch = (
  points: DataPoint[],
  target: number,
  xScale: ScaleLinear<number, number>,
  threshold: number,
): number => {
  let left = 0
  let right = points.length - 1

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)
    const midX = xScale(points[mid].x)

    if (Math.abs(midX - target) <= threshold) {
      return mid
    }

    if (midX < target) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }

  return -1
}

export const useGuideLineHighlight = ({
  seriesData,
  xScale,
  guideLineX,
  guideLineY,
  threshold,
}: UseGuideLineHighlightProps) => {
  const [highlightPoints, setHighlightPoints] = useState<HighlightPoint[]>([])
  const prevPropsRef = useRef({ guideLineX, guideLineY })

  const findBestPoints = useCallback(
    (gX: number | undefined) => {
      if (gX === undefined) return []

      const bestXPoints: HighlightPoint[] = []

      seriesData.forEach((series, seriesIndex) => {
        const index = binarySearch(series.data_points, gX, xScale, threshold)
        if (index !== -1) {
          const point = series.data_points[index]
          bestXPoints.push({ ...point, seriesIndex })
        }
      })

      return bestXPoints
    },
    [seriesData, xScale, threshold],
  )

  useEffect(() => {
    const prevProps = prevPropsRef.current
    if (
      prevProps.guideLineX !== guideLineX ||
      prevProps.guideLineY !== guideLineY
    ) {
      const bestPoints = findBestPoints(guideLineX)
      setHighlightPoints(bestPoints)
      prevPropsRef.current = { guideLineX, guideLineY }
    }
  }, [guideLineX, guideLineY, findBestPoints])

  return highlightPoints
}

import { type SeriesFunction } from "@/model"
import { defaultColor } from "@/preference"
import { newCompId } from "../../util"
import { addTransparency } from "./SeriesViewer"

export const SeriesFunctionBox = ({
  func,
  x,
  y,
  width,
  height,
  showBox,
  isActive,
  pointerEvents,
  onSelected,
}: {
  func: SeriesFunction
  x: number
  y: number
  width: number
  height: number
  showBox: boolean
  isActive: boolean
  pointerEvents: any
  onSelected: () => void
}) => {
  return (
    <rect
      className={newCompId(func.id)}
      x={x}
      y={y}
      width={width}
      height={height}
      fill={showBox ? addTransparency(func.style.color, 0.1) : "transparent"}
      stroke={showBox ? func.style.color || defaultColor : "transparent"}
      strokeWidth={isActive ? 2 : 1}
      onClick={() => onSelected()}
      style={{ cursor: "pointer" }}
      pointerEvents={pointerEvents}
    />
  )
}

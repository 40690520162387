import type { SeriesFunctionStep } from "@/model"
import { defaultColor } from "@/preference"
import { TextField } from "@/ui"
import { ColorPicker } from "@/ui/ColorPicker"

export interface LowPassFilterConfigProps {
  step: SeriesFunctionStep
  onConfigChanged: (config: Record<string, any>) => void
  onStyleChanged: (style: Record<string, any>) => void
}

export const LowPassFilterConfig = ({
  step,
  onStyleChanged,
  onConfigChanged,
}: LowPassFilterConfigProps) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <span className="text-gray-500">Color</span>
        <span className="ml-auto">
          <ColorPicker
            allowNone={true}
            value={step.style?.color || defaultColor}
            onChange={v => onStyleChanged({ color: v })}
          />
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500">Cutoff Frequency</span>
        <span className="ml-auto">
          <TextField
            className="w-[55px]"
            type="number"
            autoComplete="off"
            variant="compact"
            min={0}
            max={1}
            onFocus={e => e.target.select()}
            value={step.config?.cutoff_freq}
            onChange={e => onConfigChanged({ cutoff_freq: +e.target.value })}
          />
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500">Filter Order</span>
        <span className="ml-auto">
          <TextField
            className="w-[55px]"
            type="number"
            autoComplete="off"
            variant="compact"
            min={0}
            onFocus={e => e.target.select()}
            value={step.config?.filter_order}
            onChange={e => onConfigChanged({ filter_order: +e.target.value })}
          />
        </span>
      </div>
    </div>
  )
}

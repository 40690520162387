import type { DataPoint } from "@/model"
import {
  selectFunctionActiveSeriesId,
  setFunctionActiveSeriesId,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { LinePath as VisxLinePath } from "@visx/shape"
import { useMemo, useState } from "react"

const Circle = ({
  point,
  stroke,
  xScale,
  yScale,
  isActive = false,
  onSelect,
}: {
  point: DataPoint
  stroke: string
  xScale: any
  yScale: any
  isActive?: boolean
  onSelect: () => void
}) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <circle
      cx={xScale(point.x) - 10}
      cy={yScale(point.y)}
      r="6"
      fill={isHover || isActive ? stroke : "transparent"}
      strokeWidth="1"
      stroke={stroke}
      strokeDasharray={isHover || isActive ? "" : "1,1"}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="hover:cursor-pointer"
      onClick={onSelect}
    />
  )
}

export const LinePath = ({
  data,
  xScale,
  yScale,
  strokeDasharray,
  stroke,
  strokeWidth = 1.5,
  plotId,
  seriesId,
  showSelector = true,
}: {
  data: DataPoint[]
  xScale: any
  yScale: any
  strokeDasharray?: string
  stroke?: string
  strokeWidth?: number
  plotId?: string
  seriesId?: string
  showSelector?: boolean
}) => {
  // split data into segments when y value is null
  const firstPoint = data.length ? data[0] : undefined
  const selectedSeriesId = useAppSelector(selectFunctionActiveSeriesId(plotId!))
  const dispatch = useAppDispatch()

  const segments = useMemo(() => {
    const segments = []
    let segment = []
    for (let i = 0; i < data.length; i++) {
      const point = data[i]
      if (point.y === null) {
        if (segment.length) {
          segments.push(segment)
          segment = []
        }
      } else {
        segment.push(point)
      }
    }
    if (segment.length) {
      segments.push(segment)
    }
    return segments
  }, [data])

  const isActive = seriesId === selectedSeriesId

  return (
    <g>
      {segments.map((segment, i) => (
        <VisxLinePath
          key={`segment-${i}`}
          data={segment}
          x={d => xScale(d.x)}
          y={d => yScale(d.y)}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          stroke={stroke}
          pointerEvents={"none"}
        />
      ))}
      {showSelector && firstPoint && plotId && seriesId && (
        <Circle
          point={firstPoint}
          stroke={stroke || "gray"}
          xScale={xScale}
          yScale={yScale}
          onSelect={() => {
            dispatch(setFunctionActiveSeriesId({ plotId, seriesId }))
          }}
          isActive={isActive}
        />
      )}
    </g>
  )
}

import Background1 from "@/images/background1.jpg"
import {
  selectAuthenticated,
  selectError,
  signup,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { Alert } from "@/ui"
import { useEffect } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"

interface FormValues {
  name: string
  email: string
  password: string
}

interface LogoProps extends React.SVGProps<SVGSVGElement> {}

const Logo: React.FC<LogoProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    width="40"
    height="40"
    {...props}
  >
    <defs>
      <linearGradient id="boldGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#2563eb"></stop>
        <stop offset="100%" stopColor="#1e40af"></stop>
      </linearGradient>
    </defs>
    <circle cx="100" cy="100" r="90" fill="url(#boldGradient)"></circle>
    <path
      d="M100 30 L130 90 L110 90 L140 170 L60 110 L80 110 Z"
      fill="#FFFFFF"
    ></path>
    <circle
      cx="100"
      cy="100"
      r="30"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="6"
    ></circle>
    <circle
      cx="100"
      cy="100"
      r="60"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="3"
      strokeDasharray="10 5"
    ></circle>
  </svg>
)

export const Signup: React.FC = () => {
  const { register, handleSubmit } = useForm<FormValues>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const authenticated = useAppSelector(selectAuthenticated)
  const error = useAppSelector(selectError)

  const submitForm: SubmitHandler<FormValues> = async data => {
    dispatch(signup(data))
  }

  useEffect(() => {
    if (authenticated) {
      navigate("/charts")
    }
  }, [authenticated, navigate])

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="w-full md:w-1/2 flex items-center justify-center p-4 md:p-0">
        <div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
          <div className="flex items-center justify-center">
            <Logo className="mr-2" />
            <h2 className="text-2xl font-bold text-gray-800">Sign Up</h2>
          </div>
          <div className="mb-8 text-xs flex justify-center text-gray-400">
            <span>30 days free trial, no credit card required!</span>
          </div>
          {error && <Alert type="error">{error || "An error occurred"}</Alert>}
          <form onSubmit={handleSubmit(submitForm)}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", { required: true })}
                placeholder="Enter your name"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                {...register("email", { required: true })}
                placeholder="Enter your email"
                autoComplete="email"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                {...register("password", { required: true })}
                placeholder="Enter your password"
                autoComplete="new-password"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Sign Up
            </button>
          </form>
          <p className="text-center mt-6 text-sm text-gray-600">
            Already have an account?{" "}
            <Link
              to="/auth/login"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
      <div className="hidden md:flex md:w-1/2 bg-blue-600 items-center justify-center overflow-hidden">
        <img src={Background1} width="100%" alt="Signup" />
      </div>
    </div>
  )
}

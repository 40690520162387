import type { Toast } from "@/model"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

interface ToastState {
  toasts: Toast[]
}

const initialState: ToastState = {
  toasts: [],
}

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<Toast>) => {
      const toast = action.payload
      if (toast.id === undefined) {
        toast.id = uuidv4()
      }
      if (toast.isHidden === undefined) {
        toast.isHidden = false
      }
      state.toasts.push(action.payload)
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter(toast => toast.id !== action.payload)
    },
    hideToast: (state, action: PayloadAction<string>) => {
      const toast = state.toasts.find(t => t.id === action.payload)
      if (toast) {
        toast.isHidden = true
      }
    },
    showToast: (state, action: PayloadAction<string>) => {
      const toast = state.toasts.find(t => t.id === action.payload)
      if (toast) {
        toast.isHidden = false
      }
    },
    clearAllToasts: state => {
      state.toasts = []
    },
  },
})

export const { addToast, removeToast, hideToast, showToast, clearAllToasts } =
  toastSlice.actions
export default toastSlice.reducer
export const selectToasts = (state: { toast: ToastState }) => state.toast.toasts

import * as SwitchPrimitive from "@radix-ui/react-switch"
import { forwardRef, useCallback, useEffect, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

export interface SwitchProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof SwitchPrimitive.Root>,
    "onChange" | "checked"
  > {
  error?: string
  variant?: "default" | "compact"
  className?: string
  onChange?: (checked: boolean) => void
  debounceTime?: number
  checked?: boolean
}

const getVariantStyles = (
  variant: "default" | "compact",
  disabled: boolean,
  error?: string,
) => {
  const baseStyles = disabled
    ? "data-[state=checked]:bg-gray-400 data-[state=unchecked]:bg-gray-200 cursor-not-allowed"
    : "data-[state=checked]:bg-blue-600 data-[state=unchecked]:bg-gray-200 focus:outline-none"

  const errorStyles = error ? "border-red-300 focus:ring-red-500" : ""

  const variants = {
    default: {
      root: `relative inline-flex h-6 w-11 items-center rounded-full ${baseStyles} ${errorStyles}`,
      thumb:
        "block h-4 w-4 rounded-full bg-white transition-transform data-[state=checked]:translate-x-6 data-[state=unchecked]:translate-x-1",
      error: "mt-1 text-xs text-red-500",
    },
    compact: {
      root: `relative inline-flex h-4 w-8 items-center rounded-full ${baseStyles} ${errorStyles}`,
      thumb:
        "block h-3 w-3 rounded-full bg-white transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0.5",
      error: "mt-0.5 text-[10px] text-red-500",
    },
  }
  return variants[variant]
}

export const Switch = forwardRef<
  React.ElementRef<typeof SwitchPrimitive.Root>,
  SwitchProps
>(
  (
    {
      error,
      disabled = false,
      className = "",
      variant = "default",
      onChange,
      checked,
      debounceTime = 300,
      ...props
    },
    ref,
  ) => {
    const styles = getVariantStyles(variant, disabled, error)
    const [internalChecked, setInternalChecked] = useState<boolean>(
      checked ?? false,
    )

    const debouncedOnChange = useDebouncedCallback((value: boolean) => {
      onChange?.(value)
    }, debounceTime)

    const handleCheckedChange = useCallback(
      (value: boolean) => {
        setInternalChecked(value)
        debouncedOnChange(value)
      },
      [debouncedOnChange],
    )

    useEffect(() => {
      if (checked !== undefined) {
        setInternalChecked(checked)
      }
    }, [checked])

    return (
      <div className={className}>
        <SwitchPrimitive.Root
          ref={ref}
          disabled={disabled}
          checked={internalChecked}
          onCheckedChange={handleCheckedChange}
          className={styles.root}
          {...props}
        >
          <SwitchPrimitive.Thumb className={styles.thumb} />
        </SwitchPrimitive.Root>
        {error && <div className={styles.error}>{error}</div>}
      </div>
    )
  },
)

Switch.displayName = "Switch"

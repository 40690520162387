import { setBreadcrumbs, useAppDispatch } from "@/store"
import { useEffect } from "react"

export const Templates = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "Templates" }]))
  }, [dispatch])

  return (
    <div className="px-4 py-4 sm:px-6 lg:px-8">
      <div className="mt-4">Coming soon</div>
    </div>
  )
}

import type { Task, TaskNode } from "@/model"
import type React from "react"

interface TaskButtonProps {
  task: TaskNode
  activeTask?: Task
  onClick: (task: Task) => void
}

export const TaskButton: React.FC<TaskButtonProps> = ({
  task,
  activeTask,
  onClick,
}) => {
  const isActive = activeTask?.task === task.id
  const Icon =
    isActive && activeTask.subtask
      ? ((task.subtaskIcons || {})[activeTask.subtask] ?? task.icon)
      : task.icon
  return (
    <button
      onClick={() => onClick({ task: task.id, subtask: activeTask?.subtask })}
      className={`
              w-6 h-6 rounded-full flex items-center justify-center
              outline-none transition-colors duration-200
              ${
                isActive
                  ? "text-white bg-blue-500"
                  : "text-gray-500 bg-white hover:text-white hover:bg-blue-500"
              }
            `}
      aria-label={`Select ${task.id} task`}
    >
      <Icon size={15} />
    </button>
  )
}

export default TaskButton

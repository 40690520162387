import type {
  RisingTimeResdponseData,
  SeriesFunction,
  StepFunctionData,
} from "@/model"
import { defaultColor } from "@/preference"
import { Line } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import LabeledText from "../LabeledText"
import { LinePath } from "../LinePath"
import { addTransparency } from "../SeriesViewer"
import { getTimeValue } from "../util"

export const RisingTimeViewer = ({
  step,
  func,
  style = {},
  innerWidth,
  innerHeight,
  xScale,
  yScale,
  pointerEvents,
  formatX,
  formatY,
}: {
  func: SeriesFunction
  step: StepFunctionData
  style?: Record<string, any>
  innerWidth: number
  innerHeight: number
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  pointerEvents: "none" | "auto"
  formatX: (v: number) => string
  formatY: (v: number) => string
}) => {
  const data = step.data as RisingTimeResdponseData
  const points = step.data_points || []

  // check if it is not a number
  if (isNaN(data.start_value) || isNaN(data.end_value)) {
    return null
  }

  const color = style.color || defaultColor

  return (
    <>
      {style.show_line && (
        <LinePath
          key={step.id}
          data={points}
          xScale={xScale}
          yScale={yScale}
          strokeWidth={1.5}
          strokeDasharray="5,5"
          stroke={addTransparency(color, 0.8) || defaultColor}
        />
      )}
      {!func.style?.hidden && (
        <>
          {/* start rising time horizontal line */}
          <Line
            from={{
              x: 0,
              y: yScale(data.start_value),
            }}
            to={{
              x: innerWidth,
              y: yScale(data.start_value),
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={0}
            y={yScale(data.start_value)}
            text={formatY(data.start_value)}
            orientation="horizontal"
            color={color}
            anchor="start"
          />

          <Line
            from={{
              x: 0,
              y: yScale(data.end_value),
            }}
            to={{
              x: innerWidth,
              y: yScale(data.end_value),
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={0}
            y={yScale(data.end_value)}
            text={formatY(data.end_value)}
            orientation="horizontal"
            color={color}
            anchor="start"
          />

          {/* start rising time vertical line */}
          <Line
            from={{
              x: xScale(data.rising_time_start),
              y: innerHeight,
            }}
            to={{
              x: xScale(data.rising_time_start),
              y: 0,
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={xScale(data.rising_time_start)}
            y={innerHeight - 8}
            text={formatX(data.rising_time_start)}
            orientation="vertical"
            color={color}
          />

          {/* end rising time vertical line */}
          <Line
            from={{
              x: xScale(data.rising_time_end),
              y: innerHeight,
            }}
            to={{
              x: xScale(data.rising_time_end),
              y: 0,
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={xScale(data.rising_time_end)}
            y={innerHeight - 8}
            text={formatX(data.rising_time_end)}
            orientation="vertical"
            color={color}
          />

          {/* final value */}
          <Line
            from={{
              x: 0,
              y: yScale(data.final_value),
            }}
            to={{
              x: innerWidth,
              y: yScale(data.final_value),
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={0}
            y={yScale(data.final_value)}
            text={formatY(data.final_value)}
            orientation="horizontal"
            color={color}
            anchor="start"
          />
        </>
      )}

      {data.is_rising && (
        <>
          {/* show settling band */}
          {style.show_settling_band && (
            <rect
              x={xScale(data.rising_time_start)}
              y={yScale(data.settling_band_max)}
              width={
                xScale(data.settling_start_time) -
                xScale(data.rising_time_start)
              }
              height={Math.abs(
                yScale(data.settling_band_max) - yScale(data.settling_band_min),
              )}
              fill={addTransparency(color, 0.5)}
              pointerEvents={"none"}
            />
          )}
          {/* rising time/settling time text */}
          <text
            x={xScale(data.rising_time_end) + 10}
            y={
              yScale(data.end_value) +
              Math.abs(yScale(data.end_value) - yScale(data.start_value)) / 2
            }
            fontSize={10}
            fill={color}
            pointerEvents={"none"}
          >
            <tspan
              x={xScale(data.rising_time_end) + 10}
              dy="0"
              pointerEvents={"none"}
            >
              Rise time: {getTimeValue(data.rising_time)}
            </tspan>
            <tspan
              x={xScale(data.rising_time_end) + 10}
              dy="20"
              pointerEvents={"none"}
            >
              Settling time: {getTimeValue(data.settling_time)}
            </tspan>
          </text>
        </>
      )}

      {!data.is_rising && (
        <>
          {/* show settling band */}

          {style.show_settling_band && (
            <rect
              x={xScale(data.rising_time_start)}
              y={yScale(data.settling_band_min)}
              width={
                xScale(data.settling_start_time) -
                xScale(data.rising_time_start)
              }
              height={Math.abs(
                yScale(data.settling_band_max) - yScale(data.settling_band_min),
              )}
              fill={addTransparency(color, 0.5)}
              pointerEvents={"none"}
              style={{
                userSelect: "none",
                WebkitUserSelect: "none", // For Safari
                MozUserSelect: "none", // For Firefox
                msUserSelect: "none", // For IE/Edge
              }}
            />
          )}
          <text
            x={xScale(data.rising_time_end) + 3}
            y={
              yScale(data.end_value) -
              Math.abs(yScale(data.end_value) - yScale(data.start_value)) / 2
            }
            fontSize={10}
            fill={color}
            pointerEvents={"none"}
            style={{
              userSelect: "none",
              WebkitUserSelect: "none", // For Safari
              MozUserSelect: "none", // For Firefox
              msUserSelect: "none", // For IE/Edge
            }}
          >
            <tspan
              x={xScale(data.rising_time_end) + 10}
              dy="0"
              pointerEvents={"none"}
              style={{
                userSelect: "none",
                WebkitUserSelect: "none", // For Safari
                MozUserSelect: "none", // For Firefox
                msUserSelect: "none", // For IE/Edge
              }}
            >
              Rise time: {getTimeValue(data.rising_time)}
            </tspan>
            <tspan
              x={xScale(data.rising_time_end) + 10}
              dy="20"
              pointerEvents={"none"}
              style={{
                userSelect: "none",
                WebkitUserSelect: "none", // For Safari
                MozUserSelect: "none", // For Firefox
                msUserSelect: "none", // For IE/Edge
              }}
            >
              Settling time: {getTimeValue(data.settling_time)}
            </tspan>
          </text>
        </>
      )}
    </>
  )
}

import { Datasources } from "@/components/datasource/Datasources"
import { setBreadcrumbs, useAppDispatch } from "@/store"
import { useEffect } from "react"

export const ListDatasources: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "Datasources" }]))
  }, [dispatch])

  return <Datasources />
}

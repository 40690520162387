import * as Tooltip from "@radix-ui/react-tooltip"

interface ButtonTooltipProps {
  children: React.ReactNode
  tooltip: string
  isActive?: boolean
}

export const ButtonTooltip: React.FC<ButtonTooltipProps> = ({
  children,
  tooltip,
  isActive = false,
}) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="inline-block">{children}</div>
        </Tooltip.Trigger>
        {!isActive && (
          <Tooltip.Portal>
            <Tooltip.Content
              className="bg-white  text-xs rounded-md shadow-md p-2 border border-gray-200"
              side="left"
              sideOffset={5}
              align="center"
            >
              {tooltip}
              <Tooltip.Arrow className="fill-white" />
            </Tooltip.Content>
          </Tooltip.Portal>
        )}
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

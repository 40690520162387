import type { ActiveLocalTask } from "@/model"
import { disableLocalTask, setLocalTaskPosition, useAppDispatch } from "@/store"
import { GripVertical } from "lucide-react"
import { useCallback, useEffect, useRef, useState } from "react"
import { colorCompId, newCompId } from "../../util"

interface Position {
  x: number
  y: number
}

export const LocalToolbar = ({
  containerRef,
  top = 20,
  left = 20,
  localToolbar,
  children,
}: {
  containerRef: React.RefObject<HTMLDivElement>
  top?: number
  left?: number
  localToolbar: ActiveLocalTask
  children: React.ReactNode
}) => {
  // hack in case the local toolbar is outside the screen
  left = Math.max(100, left)
  top = Math.max(100, top)

  const [position, setPosition] = useState<Position>({ x: left, y: top })
  const [isDragging, setIsDragging] = useState(false)
  const controlRef = useRef<HTMLDivElement>(null)
  const initialMousePos = useRef<Position>({ x: 0, y: 0 })
  const initialElementPos = useRef<Position>({ x: 0, y: 0 })
  const dispatch = useAppDispatch()

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!controlRef.current) return

    setIsDragging(true)
    initialMousePos.current = { x: e.clientX, y: e.clientY }
    initialElementPos.current = { ...position }
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !containerRef.current || !controlRef.current) return

      const deltaX = e.clientX - initialMousePos.current.x
      const deltaY = e.clientY - initialMousePos.current.y

      const containerRect = containerRef.current.getBoundingClientRect()
      const controlRect = controlRef.current.getBoundingClientRect()

      const newX = Math.max(
        0,
        Math.min(
          initialElementPos.current.x + deltaX,
          containerRect.width - controlRect.width,
        ),
      )
      const newY = Math.max(
        0,
        Math.min(
          initialElementPos.current.y + deltaY,
          containerRect.height - controlRect.height,
        ),
      )

      setPosition({ x: newX, y: newY })
      dispatch(setLocalTaskPosition({ x: newX, y: newY }))
    },
    [
      isDragging,
      containerRef,
      controlRef,
      initialMousePos,
      initialElementPos,
      dispatch,
    ],
  )

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        controlRef.current &&
        !controlRef.current.contains(event.target as Node)
      ) {
        const clickedElement = event.target as HTMLElement

        // If the click is on the document element, we don't want to close the local toolbar
        if (clickedElement === document.documentElement) {
          return
        }

        const hasMatchingId =
          clickedElement.closest(".control-container") ||
          clickedElement.closest(`.${newCompId(localToolbar.id)}`) ||
          clickedElement.closest(`#${colorCompId}`)

        if (!hasMatchingId) {
          dispatch(disableLocalTask())
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dispatch, localToolbar.id])

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [isDragging, handleMouseMove])

  return (
    <div
      ref={controlRef}
      className={`local-toolbar absolute p-2 bg-white/95 backdrop-blur shadow-lg select-none z-50 rounded-lg border border-gray-200 transition-opacity duration-150 ease-out ${isDragging ? "cursor-grabbing" : "cursor-grab"}`}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        touchAction: "none",
        userSelect: "none",
      }}
    >
      <div className="flex items-center space-x-2">
        <div
          className="p-1.5 hover:bg-gray-100 rounded transition-colors duration-200"
          onMouseDown={handleMouseDown}
        >
          <GripVertical className="w-4 h-4 text-gray-400" />
        </div>
        {children}
      </div>
    </div>
  )
}

export default LocalToolbar

import { AlertCircle, CheckCircle, Info, XCircle } from "lucide-react"

interface AlertProps {
  type: "success" | "error" | "warning" | "info"
  children: React.ReactNode
}

export const Alert: React.FC<AlertProps> = ({ type, children }) => {
  const getAlertStyles = () => {
    switch (type) {
      case "success":
        return "bg-green-100 border-green-400 text-green-700"
      case "error":
        return "bg-red-100 border-red-400 text-red-700"
      case "warning":
        return "bg-yellow-100 border-yellow-400 text-yellow-700"
      case "info":
        return "bg-blue-100 border-blue-400 text-blue-700"
    }
  }

  const getIcon = () => {
    switch (type) {
      case "success":
        return <CheckCircle className="w-5 h-5 mr-2" />
      case "error":
        return <XCircle className="w-5 h-5 mr-2" />
      case "warning":
        return <AlertCircle className="w-5 h-5 mr-2" />
      case "info":
        return <Info className="w-5 h-5 mr-2" />
    }
  }

  return (
    <div
      className={`${getAlertStyles()} border px-4 py-3 rounded relative mb-4 flex items-center`}
      role="alert"
    >
      {getIcon()}
      <span>{children}</span>
    </div>
  )
}

export default Alert

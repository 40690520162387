import { removeToken } from "@/api/storage"
import {
  clearAllToasts,
  removeToast,
  selectProfile,
  selectToasts,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react"
import { Bell, Trash2, X } from "lucide-react"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Fragment } from "react/jsx-runtime"
import { Breadcrumbs } from "./Breadcrumbs"

export const Header = () => {
  const userInfo = useAppSelector(selectProfile)
  const toasts = useAppSelector(selectToasts)
  const dispatch = useAppDispatch()
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  const notificationRef = useRef<HTMLDivElement | null>(null)

  const totalToasts = toasts.length

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setIsNotificationOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleClearAll = () => {
    dispatch(clearAllToasts())
    setIsNotificationOpen(false) // Close the notification dropdown
  }

  return (
    <header className="bg-white shadow-[0_3px_2px_-1px_rgba(0,0,0,0.1)] z-50">
      <div className="px-4 sm:px-4 lg:px-4">
        <div className="flex h-16 justify-between items-center">
          <div className="flex-shrink-0">
            <Breadcrumbs />
          </div>
          <div className="flex items-center">
            {/* Notification Icon */}
            <div className="relative mr-4" ref={notificationRef}>
              <button
                className="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
              >
                <Bell size={20} />
                {totalToasts > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-3 h-3 flex items-center justify-center text-[10px]">
                    {totalToasts}
                  </span>
                )}
              </button>
              {isNotificationOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-50">
                  <div className="py-2">
                    {toasts.length === 0 ? (
                      <p className="text-center text-gray-500 py-4">
                        No notifications
                      </p>
                    ) : (
                      <>
                        <div className="px-4 py-2 flex justify-between items-center border-b border-gray-200">
                          <h3 className="text-sm font-medium text-gray-700">
                            Notifications
                          </h3>
                          <button
                            className="text-xs text-red-500 hover:text-red-700 flex items-center"
                            onClick={handleClearAll}
                          >
                            <Trash2 size={14} className="mr-1" />
                            Clear All
                          </button>
                        </div>
                        {toasts.map(toast => (
                          <div key={toast.id} className="px-4 py-2">
                            <div className="flex justify-between items-start">
                              <p className="text-sm text-gray-800 pr-4">
                                {toast.message || "Notification"}
                              </p>
                              <button
                                className="text-gray-400 hover:text-gray-600 focus:outline-none"
                                onClick={() => dispatch(removeToast(toast.id!))}
                                aria-label="Remove notification"
                              >
                                <X size={16} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* User Menu */}
            <Menu as="div" className="relative ml-3">
              <div>
                <MenuButton className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                    {userInfo!.avatar ? (
                      <img src={userInfo!.avatar} alt="avatar" />
                    ) : (
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    )}
                  </span>
                </MenuButton>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem>
                    <Link
                      to="/account"
                      className="block px-4 py-2 text-sm text-gray-700"
                    >
                      My Account
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      onClick={e => {
                        e.preventDefault()
                        removeToken()
                        window.location.href = "/"
                      }}
                      to="/auth/logout"
                      className="block px-4 py-2 text-sm text-gray-700"
                    >
                      Logout
                    </Link>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  )
}

import type { Series } from "@/model"
import { Text } from "@visx/text"

export const Legend = ({ series }: { series: Series }) => {
  return (
    <>
      <rect
        width={10}
        height={10}
        fill={series.visible ? series.color || "#1f77b4" : "none"}
        stroke={series.visible ? "none" : "#808080"}
        strokeWidth={1}
        pointerEvents={"none"}
        style={{
          userSelect: "none",
          WebkitUserSelect: "none", // For Safari
          MozUserSelect: "none", // For Firefox
          msUserSelect: "none", // For IE/Edge
        }}
      />
      <Text
        x={25}
        y={4}
        dy=".33em"
        textAnchor="start"
        style={{
          fontSize: 12,
          fill: series.visible ? "#808080" : "#CCCCCC",
          userSelect: "none",
          WebkitUserSelect: "none", // For Safari
          MozUserSelect: "none", // For Firefox
          msUserSelect: "none", // For IE/Edge
        }}
        pointerEvents={"none"}
      >
        {series.y}
      </Text>
    </>
  )
}

import * as api from "@/api"
import type { AskAIChartResponse } from "@/model"
import { chartLineColors } from "@/preference"
import { Sparkles } from "lucide-react"
import { useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"

const steps = ["Preparing data...", "Creating plots...", "Final check..."]

const stepAnalyseData = async (chartId: string, question: string) => {
  const response = await api.askChartAI(chartId, question)
  return response
}

const stepCreateVisualizations = async (
  chartId: string,
  resp: AskAIChartResponse,
) => {
  for (const pl of resp.plots) {
    const plotId = uuidv4()
    const plot = {
      id: plotId,
      name: pl.name,
      serieses: pl.serieses.map((s, idx) => ({
        id: uuidv4(),
        plot_id: plotId,
        x: s.x,
        y: s.y,
        color: chartLineColors[idx % chartLineColors.length],
        datasource_id: s.datasource_id,
        priority: idx,
      })),
      functions: [],
      label_x: "",
      label_y: "",
    }
    // call to create plot with serieses
    await api.addPlot(chartId, plot)
  }
}

const stepGenerateInsights = async () => {
  await new Promise(resolve => setTimeout(resolve, 2000))
}

export const AIProgress = ({
  chartId,
  question,
}: {
  chartId: string
  question: string
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  useEffect(() => {
    const processSteps = async () => {
      setCurrentStep(1)
      const step1Resp = await stepAnalyseData(chartId, question)

      setCurrentStep(2)
      await stepCreateVisualizations(chartId, step1Resp)

      setCurrentStep(3)
      await stepGenerateInsights()

      // refresh the page
      window.location.reload()
    }

    processSteps()
  }, [])

  return (
    <div className="w-full rounded-xl border bg-white p-8 shadow-[0_4px_20px_-4px_rgba(0,0,0,0.1)]">
      <div className="space-y-6">
        <div className="flex items-center gap-3 text-blue-600">
          <Sparkles className="w-5 h-5" />
          <h3 className="font-semibold">Working on your insights</h3>
        </div>

        <div className="space-y-4">
          {steps.map((step, index) => {
            const isActive = index === currentStep
            const isCompleted = index < currentStep

            return (
              <div
                key={step}
                className={`flex items-center gap-3 text-sm transition-all duration-300
                  ${isActive ? "text-blue-600" : ""}
                  ${isCompleted ? "text-gray-400" : "text-gray-300"}`}
              >
                <div
                  className={`relative w-4 h-4 rounded-full border-2 flex items-center justify-center
                  ${isActive ? "border-blue-600" : ""}
                  ${isCompleted ? "border-gray-400 bg-gray-400" : "border-gray-300"}`}
                >
                  {isActive && (
                    <div className="absolute inset-0 border-2 border-blue-600 rounded-full animate-ping" />
                  )}
                  {isCompleted && (
                    <div className="w-1.5 h-1.5 bg-white rounded-full" />
                  )}
                </div>

                <span
                  className={`font-medium ${isActive ? "animate-pulse" : ""}`}
                >
                  {step}
                </span>
              </div>
            )
          })}
        </div>

        <div className="pt-2">
          <div className="h-1 w-full bg-gray-100 rounded-full overflow-hidden">
            <div
              className="h-full bg-blue-600 transition-all duration-500 ease-out"
              style={{
                width: `${(currentStep / steps.length) * 100}%`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AIProgress

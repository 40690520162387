import { useCommands } from "@/commands"
import {
  FUNC_FIT_CURVE,
  FUNC_GAUSSIAN_FILTER,
  FUNC_LOW_PASS_FILTER,
  FUNC_MEDIAN_FILTER,
  FUNC_MOVING_AVERAGE_FILTER,
  FUNC_OSCILLATIONS,
  FUNC_RISING_TIME,
  FUNC_SAVGOL_FILTER,
  FUNC_SLOPE,
  LOW_PASS_FILTER_CUTOFF_FREQ,
  LOW_PASS_FILTER_ORDER,
  MEDIAN_FILTER_WINDOW_SIZE,
  OSCILLATION_PROMINENCE_THRESHOLD,
  OSCILLATION_WINDOW_SIZE,
  RISING_TIME_SETTLING_BAND,
  RISING_TIME_SMOOTH_SIGMA,
  SAVGOL_FILTER_POLY_ORDER,
  SAVGOL_FILTER_WINDOW_SIZE,
  SLOPE_SMOOTH_SIGMA,
  type ActiveLocalTaskDataSeriesFunction,
  type SeriesFunctionStep,
} from "@/model"
import {
  selectChart,
  selectLocalTask,
  selectSerieses,
  setLocalTaskSubCollapsed,
  setLocalTaskSubPosition,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { IconButton } from "@/ui"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import { PlusIcon } from "lucide-react"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { DraggableControlContainer } from "../DraggableControlContainer"
import { FitCurveConfig } from "./function/FitCurveConfig"
import { FunctionItemConfigContainer } from "./function/FunctionItemConfigContainer"
import { GaussianConfig } from "./function/GaussianConfig"
import { LowPassFilterConfig } from "./function/LowPassFilterConfig"
import { MedianFilterConfig } from "./function/MedianFilterConfig"
import { MovingAverageConfig } from "./function/MovingAverageConfig"
import { RisingTimeConfig } from "./function/RisingTimeConfig"
import { SavgolFilterConfig } from "./function/SavgolFilterConfig"
import { SlopeConfig } from "./function/SlopeConfig"

interface SeriesFunctionConfigProps {
  enabled: boolean
  plotId: string
  containerRef: React.RefObject<HTMLDivElement>
  initialTop?: number
  initialLeft?: number
  initialCollapsed?: boolean
}

type FunctionComponentProps = {
  step: SeriesFunctionStep
  onStyleChanged: (style: any) => void
  onConfigChanged: (config: any) => void
}

type FunctionComponent = React.ComponentType<FunctionComponentProps>

const FUNCTION_COMPONENTS: Record<
  SeriesFunctionStep["type"],
  FunctionComponent
> = {
  [FUNC_RISING_TIME]: RisingTimeConfig,
  [FUNC_FIT_CURVE]: FitCurveConfig,
  [FUNC_SLOPE]: SlopeConfig,
  // [FUNC_OSCILLATIONS]: OscillationsConfig,
  [FUNC_MOVING_AVERAGE_FILTER]: MovingAverageConfig,
  [FUNC_MEDIAN_FILTER]: MedianFilterConfig,
  [FUNC_GAUSSIAN_FILTER]: GaussianConfig,
  [FUNC_LOW_PASS_FILTER]: LowPassFilterConfig,
  [FUNC_SAVGOL_FILTER]: SavgolFilterConfig,
}

const FUNCTION_CONFIGS: Record<
  SeriesFunctionStep["type"],
  {
    config: any
    style?: any
  }
> = {
  [FUNC_FIT_CURVE]: {
    config: {
      type: "linear",
      degree: 3,
    },
  },
  [FUNC_GAUSSIAN_FILTER]: {
    config: {
      sigma: 100,
    },
  },
  [FUNC_RISING_TIME]: {
    config: {
      settling_band: RISING_TIME_SETTLING_BAND,
      smooth_sigma: RISING_TIME_SMOOTH_SIGMA,
    },
  },
  [FUNC_SLOPE]: {
    config: {
      sigma: SLOPE_SMOOTH_SIGMA,
      window_size: 20,
    },
    style: {
      show_line: true,
    },
  },
  [FUNC_MOVING_AVERAGE_FILTER]: {
    config: {
      window_size: 20,
    },
  },
  [FUNC_LOW_PASS_FILTER]: {
    config: {
      cutoff_freq: LOW_PASS_FILTER_CUTOFF_FREQ,
      filter_order: LOW_PASS_FILTER_ORDER,
    },
  },
  [FUNC_MEDIAN_FILTER]: {
    config: {
      window_size: MEDIAN_FILTER_WINDOW_SIZE,
    },
  },
  [FUNC_SAVGOL_FILTER]: {
    config: {
      window_size: SAVGOL_FILTER_WINDOW_SIZE,
      poly_order: SAVGOL_FILTER_POLY_ORDER,
    },
  },
  [FUNC_OSCILLATIONS]: {
    config: {
      window_size: OSCILLATION_WINDOW_SIZE,
      prominence_threshold: OSCILLATION_PROMINENCE_THRESHOLD,
    },
    style: {
      show_moving_average: true,
    },
  },
} as const

export const SeriesFunctionConfig: React.FC<SeriesFunctionConfigProps> = ({
  enabled,
  plotId,
  containerRef,
  initialTop = 20,
  initialLeft = 400,
  initialCollapsed = false,
}) => {
  const [openStepId, setOpenStepId] = useState<string | null>(null)
  const chart = useAppSelector(selectChart)
  const serieses = useAppSelector(selectSerieses(plotId))
  const localTask = useAppSelector(selectLocalTask)
  const localTaskData = localTask?.data as ActiveLocalTaskDataSeriesFunction
  const {
    addSeriesFunctionStepCommand,
    deleteSeriesFunctionStepCommand,
    updateSeriesFunctionStepConfigCommand,
    updateSeriesFunctionStepStyleCommand,
  } = useCommands()
  const dispatch = useAppDispatch()

  const addFunctionStep = async (type: SeriesFunctionStep["type"]) => {
    const stepId = uuidv4()
    await addSeriesFunctionStepCommand({
      plotId,
      seriesId: localTaskData.seriesId,
      seriesFunctionId: localTaskData.seriesFunctionId,
      step: {
        id: stepId,
        type,
        style: FUNCTION_CONFIGS[type]["style"] || {},
        config: FUNCTION_CONFIGS[type]["config"],
      },
    })
    setOpenStepId(stepId)
  }

  const deleteStep = (stepId: string): void => {
    deleteSeriesFunctionStepCommand({
      stepId,
      plotId,
      seriesId: localTaskData.seriesId,
      seriesFunctionId: localTaskData.seriesFunctionId,
    })
  }

  if (!enabled || !chart || !localTaskData || (serieses || []).length === 0) {
    return null
  }

  const steps: SeriesFunctionStep[] =
    (serieses || [])
      .find(s => s.id === localTaskData.seriesId)
      ?.functions?.find(f => f.id === localTaskData.seriesFunctionId)?.data
      ?.steps || []

  const handleToggle = (stepId: string) => {
    setOpenStepId(openStepId === stepId ? null : stepId)
  }

  const handleStyleUpdate = (stepId: string) => (style: any) => {
    updateSeriesFunctionStepStyleCommand({
      stepId,
      plotId,
      seriesId: localTaskData.seriesId,
      seriesFunctionId: localTaskData.seriesFunctionId,
      style,
    })
  }

  const handleConfigUpdate = (stepId: string) => (config: any) => {
    updateSeriesFunctionStepConfigCommand({
      stepId,
      plotId,
      seriesId: localTaskData.seriesId,
      seriesFunctionId: localTaskData.seriesFunctionId,
      config,
    })
  }

  return (
    <DraggableControlContainer
      containerRef={containerRef}
      initialTop={initialTop}
      initialLeft={initialLeft}
      initialCollapsed={initialCollapsed}
      onDragEnd={position => {
        dispatch(setLocalTaskSubPosition(position))
      }}
      onCollapsedChange={isCollapsed => {
        dispatch(setLocalTaskSubCollapsed(isCollapsed))
      }}
      title="Functions"
    >
      <div className="control-container text-xs flex flex-col space-y-3 w-[200px] overflow-hidden">
        <div>
          {steps.map(step => {
            const StepComponent = FUNCTION_COMPONENTS[step.type]
            return (
              <FunctionItemConfigContainer
                key={step.id}
                isOpen={openStepId === step.id}
                step={step}
                onDelete={deleteStep}
                onToggle={handleToggle}
              >
                <StepComponent
                  step={step}
                  onStyleChanged={handleStyleUpdate(step.id)}
                  onConfigChanged={handleConfigUpdate(step.id)}
                />
              </FunctionItemConfigContainer>
            )
          })}
        </div>
        <div className="flex justify-end">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className="inline-flex items-center justify-center">
              <IconButton
                as="span"
                icon={<PlusIcon className="w-3 h-3" />}
                text="Add Function"
                size="small"
                variant="ghost"
              />
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="control-container z-50 min-w-[220px] bg-white rounded-md shadow-lg p-1 border border-gray-200"
                sideOffset={5}
              >
                {Object.entries(FUNCTION_COMPONENTS).map(([type, _]) => (
                  <DropdownMenu.Item
                    key={type}
                    onClick={() =>
                      addFunctionStep(type as keyof typeof FUNCTION_COMPONENTS)
                    }
                    className="group text-xs rounded-md flex items-center px-2 py-2 hover:bg-blue-500 hover:text-white outline-none cursor-pointer"
                  >
                    {type
                      .split("_")
                      .map(
                        word =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase(),
                      )
                      .join(" ")}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </div>
      </div>
    </DraggableControlContainer>
  )
}

import type { Zoom } from "@/model"
import { scaleLinear } from "@visx/scale"
import { extent } from "@visx/vendor/d3-array"

interface DataPoint {
  x: number
  y: number
}

interface UseScalesProps {
  datasets: DataPoint[][]
  innerWidth: number
  innerHeight: number
  zoom?: Zoom
  xType: string
}

const numberScales = ({
  datasets,
  innerWidth,
  innerHeight,
  zoom,
}: UseScalesProps) => {
  const allPoints = datasets.flat()
  const [minX, maxX] = extent(allPoints, d => d.x) as [number, number]
  const [minY, maxY] = extent(allPoints, d => d.y) as [number, number]

  const xDomain = [zoom?.x_min ?? minX, zoom?.x_max ?? maxX]
  const yDomain = [zoom?.y_min ?? minY, zoom?.y_max ?? maxY]

  // Add a small buffer (5% of the range) to both axes
  const xBuffer = (xDomain[1] - xDomain[0]) * 0.05
  const yBuffer = (yDomain[1] - yDomain[0]) * 0.05

  const xScale = scaleLinear({
    domain: [xDomain[0] - xBuffer, xDomain[1] + xBuffer],
    range: [0, innerWidth],
  })

  const yScale = scaleLinear({
    domain: [yDomain[0] - yBuffer, yDomain[1] + yBuffer],
    range: [innerHeight, 0],
  })

  return { xScale, yScale }
}

const timeScales = ({
  datasets,
  innerWidth,
  innerHeight,
  zoom,
}: UseScalesProps) => {
  const allPoints = datasets.flat()
  const [minX, maxX] = extent(allPoints, d => d.x) as [number, number]
  const [minY, maxY] = extent(allPoints, d => d.y) as [number, number]

  const xDomain = [zoom?.x_min ?? minX, zoom?.x_max ?? maxX]
  const yDomain = [zoom?.y_min ?? minY, zoom?.y_max ?? maxY]

  // Add a small buffer (5% of the range) to both axes
  const xBuffer = (xDomain[1] - xDomain[0]) * 0.05
  const yBuffer = (yDomain[1] - yDomain[0]) * 0.05

  const xScale = scaleLinear({
    domain: [xDomain[0] - xBuffer, xDomain[1] + xBuffer],
    range: [0, innerWidth],
  })

  const yScale = scaleLinear({
    domain: [yDomain[0] - yBuffer, yDomain[1] + yBuffer],
    range: [innerHeight, 0],
  })

  return { xScale, yScale }
}

export const useScales = (props: UseScalesProps) => {
  if (props.xType === "number") {
    return numberScales(props)
  }

  return timeScales(props)
}

export const defaultNumberOfPoints = 1500
export const FUNC_RISING_TIME = "rising_time"
export const FUNC_STEPS_FUNCTION = "stepsfunction"
export const FUNC_FIT_CURVE = "fit_curve"
export const FUNC_OSCILLATIONS = "oscillations"
export const FUNC_GAUSSIAN_FILTER = "gaussian_filter"
export const FUNC_SLOPE = "slope"
export const FUNC_MOVING_AVERAGE_FILTER = "moving_average_filter"
export const FUNC_LOW_PASS_FILTER = "low_pass_filter"
export const FUNC_MEDIAN_FILTER = "median_filter"
export const FUNC_SAVGOL_FILTER = "savgol_filter"

export const TASK_MEASURE = "measure"
export const TASK_EDIT = "edit"
export const TASK_ANNOTATION = "annotation"

export const RISING_TIME_SMOOTH_SIGMA = 100
export const RISING_TIME_SETTLING_BAND = 0.05
export const SLOPE_SMOOTH_SIGMA = 10
export const MOVING_AVERAGE_WINDOW_SIZE = 20
export const MEDIAN_FILTER_WINDOW_SIZE = 20
export const LOW_PASS_FILTER_CUTOFF_FREQ = 0.1
export const LOW_PASS_FILTER_ORDER = 1
export const SAVGOL_FILTER_WINDOW_SIZE = 5
export const SAVGOL_FILTER_POLY_ORDER = 2
export const OSCILLATION_WINDOW_SIZE = 20
export const OSCILLATION_PROMINENCE_THRESHOLD = 0.1

export const funcNames: Record<string, string> = {
  [FUNC_RISING_TIME]: "Rise Time",
  [FUNC_STEPS_FUNCTION]: "Combined Functions",
  [FUNC_FIT_CURVE]: "Fit Curve",
  [FUNC_OSCILLATIONS]: "Oscillations",
  [FUNC_GAUSSIAN_FILTER]: "Gaussian Filter",
  [FUNC_SLOPE]: "Slope",
  [FUNC_MOVING_AVERAGE_FILTER]: "Moving Average Filter",
  [FUNC_LOW_PASS_FILTER]: "Low Pass Filter",
  [FUNC_MEDIAN_FILTER]: "Median Filter",
  [FUNC_SAVGOL_FILTER]: "Savitzky-Golay Filter",
}

export const getFuncName = (func: string) => funcNames[func]

export interface Profile {
  id: number
  communication_email: string
  first_name: string
  last_name: string
  avatar: string
}

export interface Chart {
  id: string
  name: string
  datetime_format: string
  updated_at: string
}

export interface Column {
  id: string
  name: string
  friendly_name: string
  type: string
  disabled?: boolean
}

export interface ColumnUpdate {
  name: string
  friendly_name: string
}

export interface TaskNode {
  id:
    | "edit"
    | "measure"
    | "annotation"
    | "delete_plot"
    | "export_plot_image"
    | "copy_as_image"
  label: string
  icon: any
  shortcut?: string
  subtaskIcons?: Record<string, any>
}

export interface SubtaskNode {
  id: string
  label: string
  shortcut?: string
  icon: any
  disabled?: boolean
  disabledLabel?: string
  localTasks?: LocalTask[]
}

export interface LocalTask {
  id: string
  label: string
  icon: any
  onClick?: () => void
}

export interface ActiveLocalTaskData {
  plotId: string
}

export interface ActiveLocalTaskDataSeriesFunction extends ActiveLocalTaskData {
  seriesId: string
  seriesFunctionId: string
}
export interface ActiveLocalTask {
  id: string
  type: string
  x: number
  y: number
  data: ActiveLocalTaskData | ActiveLocalTaskDataSeriesFunction
}

export interface Task {
  task: string
  subtask?: SubtaskNode["id"]
  localtask?: LocalTask["id"]
}

export interface Datasource {
  id: string
  name: string
  is_parent: boolean
  parent_id: string
  parent_name: string
  original_type: string
  columns: Column[]
  disabled?: boolean
  is_pending?: boolean
}

export interface Zoom {
  x_min?: number
  x_max?: number
  y_min?: number
  y_max?: number
}

export interface ZoomRequest {
  x_pixel_min: number
  x_pixel_max: number
  y_pixel_min: number
  y_pixel_max: number
}

export interface Plot {
  id: string
  name: string
  zoom?: Zoom
  reference_lines?: ReferenceLine[]
  annotations?: Annotation[]
  label_x: string
  label_y: string
  position?: number
}

export type DataPoint = {
  x: number
  y: number
}

export interface PlotData {
  plot_id: string
  series_data: SeriesData[]
  error: string
  loading?: boolean
}

export interface StepFunctionData {
  id: string
  type: string
  data_points: DataPoint[]
  data: {
    [key: string]: any
  }
}

export interface StepsFunctionData {
  steps: StepFunctionData[]
}

export interface OscillationsResponseData {
  metrics: {
    stability_index: number
    trend_coefficient: number
    mean_value: number
    std_dev: number
    rms_value: number
    peak_to_peak: number
    sampling_rate: number
  }
  stability: {
    moving_average_points: DataPoint[]
  }
  peaks_and_valleys: {
    peaks: {
      points: [number, number][]
      prominences: number[]
    }
    valleys: {
      points: [number, number][]
      prominences: number[]
    }
  }
  limits: {
    limits: {
      operating: {
        upper: number
        lower: number
      }
      critical: {
        upper: number
        lower: number
      }
    }
  }
  // frequency_analysis: {
  //   dominant_frequencies: number[]
  //   spectrum_points: number[]
  // }
  // 'peaks_and_valleys': peak_data,
  //       'stability': {
  //           'moving_average_points': stability_data['moving_average_points'],
  //           'trend_points': stability_data['trend_points']
  //       },
  //       'limits': limit_data,
  //       'data_points': signal_points
}

export interface RisingTimeResdponseData {
  is_rising: boolean
  min_value: number
  max_value: number
  min_time: number
  max_time: number
  rising_time_start: number
  rising_time_end: number
  start_value: number
  end_value: number
  rising_time: number
  peak_time: number
  peak_value: number
  peak_overshoot_percent: number
  settling_start_time: number
  settling_time: number
  settling_band_min: number
  settling_band_max: number
  final_value: number
  data_points: DataPoint[]
}

export interface SlopeResponseData {
  slope: number
  start_point: [number, number]
  end_point: [number, number]
}

export interface OscillationsData {
  metrics: {
    amplitudes: number[]
    dominant_frequencies: number[]
    longest_violation_duration: number
    max_deviation: number
    mean_value: number
    min_deviation: number
    oscillation_intervals: [number, number][]
    phase_angles: number[]
    stability_index: number
    std_dev: number
    trend: number
    violation_percentage: number
  }
  visual_data: {
    frequencies: number[]
    nominal_value: number
    power_spectrum: number[]
    rolling_std: number[]
    sampling_info: {
      displayed_points: number
      original_points: number
      reduction_ratio: number
    }
    times: number[]
    tolerance_band: number
    values: number[]
    values_smooth: number[]
    violations: boolean[]
  }
}

export interface StepData {
  data_points: DataPoint[]
}
export interface FunctionData {
  id: string
  type: string
  data_points: DataPoint[]
  data:
    | RisingTimeResdponseData
    | OscillationsData
    | StepsFunctionData
    | SlopeResponseData
  boundary?: {
    x_min: number
    x_max: number
    y_min: number
    y_max: number
  }
}

export interface SeriesData {
  series_id: string
  data_points: DataPoint[]
  functions_data?: FunctionData[]
}

export interface Filter {
  column: string
  operator: ">=" | "<="
  value: string | number
  scale?: number
}

export interface ReferenceLine {
  id: string
  orientation: "horizontal" | "vertical"
  value: number
}

export interface AnnotationArrow {
  from_x: number
  from_y: number
  to_x: number
  to_y: number
  color?: string
}

export interface AnnotationText {
  x: number
  y: number
  text: string
  color?: string
}

export interface Annotation {
  id: string
  type: "text" | "arrow"
  data: AnnotationArrow | AnnotationText
}

export interface SeriesFunctionStep {
  id: string
  type: string
  style: Record<string, any>
  config: Record<string, any>
}

export interface SeriesFunctionData {
  x_min: number
  x_max: number
  y_min: number
  y_max: number
  steps?: SeriesFunctionStep[]
}

export interface RisingTimeData extends SeriesFunctionData {
  smooth_sigma: number
  settling_band: number
}

export interface SeriesFunctionOscillationData extends SeriesFunctionData {
  nominal_value: number
  tolerance_band: number
  sampling_rate: number
}

export interface SeriesFunction {
  id: string
  type: string
  data: SeriesFunctionData | SeriesFunctionOscillationData | RisingTimeData
  style: Record<string, any>
}

export interface PlotFunction {
  id: string
  plot_id: string
  type: string
  data: Record<string, any>
  style: Record<string, any>
}

export interface Series {
  id: string
  plot_id: string
  x: string
  y: string
  color: string
  datasource_id: string
  priority: number
  x_offset?: number
  y_offset?: number
  y_scale?: number
  visible?: boolean
  functions?: SeriesFunction[]
}

export interface SeriesQuery {
  series_id: string
  datasource_id: string
  x: {
    name: string
    scale?: number
    offset?: number
  }
  y: {
    name: string
    scale?: number
    offset?: number
  }
  functions: FunctionQuery[]
}

export interface FunctionQuery {
  id: string
  type: string
  data: Record<string, any>
}

export interface PlotQuery {
  plot_id: string
  number_of_points: number
  series: SeriesQuery[]
  functions: FunctionQuery[]
  zoom?: Zoom
}

export interface HoverValues {
  xScale?: number
  yScale?: number
  xPixel?: number
  yPixel?: number
}

export interface ZoomSelection {
  x: [number, number]
  y: [number, number]
  rect: {
    x: number
    y: number
    width: number
    height: number
  }
}

export interface Toast {
  id?: string
  message: string
  type: "success" | "error" | "info"
  isHidden?: boolean
  destroy?: boolean
}

export interface Message {
  text: string
  sender: "user" | "ai"
}

export interface AskAIChartResponse {
  plots: {
    name: string
    serieses: {
      datasource_id: string
      x: string
      y: string
    }[]
  }[]
}

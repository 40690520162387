import * as Label from "@radix-ui/react-label"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDebouncedCallback } from "use-debounce"

export interface InputProps {
  name: string
  value?: string
  step?: number
  id?: string
  type?: string
  min?: number
  max?: number
  label?: string
  className?: string
  placeholder?: string
  onChange?: (v: string) => void
  debounceTime?: number
  selectAllOnFocus?: boolean
  suffix?: React.ReactNode // Flexible suffix that can be an icon or text
}

export const Input: React.FC<InputProps> = ({
  id,
  name,
  label,
  value: externalValue,
  min,
  max,
  step = "any",
  placeholder,
  type = "text",
  className = "",
  onChange,
  debounceTime = 300,
  selectAllOnFocus = false,
  suffix, // Flexible suffix
}) => {
  const [internalValue, setInternalValue] = useState(externalValue || "")
  const inputRef = useRef<HTMLInputElement>(null)

  const debouncedOnChange = useDebouncedCallback((value: string) => {
    onChange && onChange(value)
  }, debounceTime)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      setInternalValue(newValue)
      debouncedOnChange(newValue)
    },
    [debouncedOnChange],
  )

  const handleFocus = useCallback(() => {
    if (selectAllOnFocus && inputRef.current) {
      inputRef.current.select()
    }
  }, [selectAllOnFocus])

  const preventDrop = (e: React.DragEvent<HTMLInputElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'none';
  }

  useEffect(() => {
    if (externalValue !== undefined) {
      setInternalValue(externalValue)
    }
  }, [externalValue])

  return (
    <div className={`relative text-xs ${className}`}>
      <div className="flex w-full bg-white border border-gray-300 rounded-md shadow-sm overflow-hidden">
        {label && (
          <Label.Root
            className="flex-shrink-0 flex items-center px-3 py-2 text-xs font-semibold text-gray-700 bg-gray-100 whitespace-nowrap"
            htmlFor={id || name}
          >
            {label}
          </Label.Root>
        )}
        <div className="flex-grow flex items-center relative">
          <input
            className={`w-full bg-transparent border-none outline-none placeholder-gray-400 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none pl-3 pr-3 py-1`}
            id={id || name}
            name={name}
            min={min}
            max={max}
            step={step}
            type={type}
            value={internalValue}
            onChange={handleChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            ref={inputRef}
            onDragOver={preventDrop}
            onDragEnter={preventDrop}
            onDragLeave={preventDrop}
            onDrop={preventDrop}
            autoComplete="off"
          />
          {suffix && (
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center text-gray-400 text-xs italic">
              {suffix}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Input

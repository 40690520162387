import type { ZoomSelection } from "@/model"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import { getTimeValue } from "./util"

export const BoxSelection = ({
  zoomSelection,
  showValues,
  xScale,
  yScale,
  xType,
}: {
  zoomSelection: ZoomSelection
  showValues: boolean
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  xType: string | undefined
}) => {
  const xStart = xScale.invert(zoomSelection.rect.x)
  const xEnd = xScale.invert(zoomSelection.rect.x + zoomSelection.rect.width)
  const yStart = yScale.invert(zoomSelection.rect.y)
  const yEnd = yScale.invert(zoomSelection.rect.y + zoomSelection.rect.height)

  const width =
    xType === "datetime"
      ? getTimeValue(Math.abs(xEnd - xStart))
      : Math.abs(xEnd - xStart).toFixed(2)
  const height = Math.abs(yEnd - yStart).toFixed(2)

  return (
    <>
      <rect
        x={zoomSelection.rect.x}
        y={zoomSelection.rect.y}
        width={zoomSelection.rect.width}
        height={zoomSelection.rect.height}
        fill="rgba(0, 0, 255, 0.1)"
        stroke="blue"
        strokeWidth={1}
        style={{ pointerEvents: "none" }}
      />
      {showValues && (
        <>
          <text
            x={zoomSelection.rect.x + zoomSelection.rect.width / 2}
            y={zoomSelection.rect.y - 5}
            textAnchor="middle"
            dominantBaseline="bottom"
            fill="blue"
            fontSize="12"
          >
            {`${width}`}
          </text>
          <text
            x={zoomSelection.rect.x - 5}
            y={zoomSelection.rect.y + zoomSelection.rect.height / 2}
            textAnchor="middle"
            dominantBaseline="middle"
            fill="blue"
            fontSize="12"
            transform={`rotate(-90 ${zoomSelection.rect.x - 5} ${zoomSelection.rect.y + zoomSelection.rect.height / 2})`}
          >
            {`${height}`}
          </text>
        </>
      )}
    </>
  )
}

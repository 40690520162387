import * as api from "@/api"
import type { Chart } from "@/model"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

interface ChartsState {
  charts?: Chart[]
}

const initialState: ChartsState = {
  charts: undefined,
}

export const listCharts = createAsyncThunk("charts/listCharts", async () => {
  const charts = await api.getCharts()
  return charts
})

export const deleteChart = createAsyncThunk(
  "charts/deleteChart",
  async (chartId: string) => {
    await api.deleteChart(chartId)
    return chartId
  },
)

export const chartsSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listCharts.fulfilled, (state, action) => {
        state.charts = action.payload
      })
      .addCase(deleteChart.pending, (state, action) => {
        state.charts = (state.charts || []).filter(
          chart => chart.id !== action.meta.arg,
        )
      })
  },
})

export default chartsSlice.reducer

export const selectCharts = (state: { charts: ChartsState }) =>
  state.charts.charts

import { useParentSize } from "@visx/responsive"

export const useCanvasSize = (margin: {
  top: number
  right: number
  bottom: number
  left: number
}) => {
  const { parentRef, width, height } = useParentSize()
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom

  return { parentRef, width, height, innerWidth, innerHeight }
}

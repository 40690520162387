import {
  getProfile as getProfileApi,
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
} from "@/api"
import type { Profile } from "@/model"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

interface AuthState {
  user: Profile | undefined
  authenticated: boolean | undefined
  error: string | null
}

const initialState: AuthState = {
  user: undefined,
  authenticated: undefined,
  error: null,
}

interface LoginCredentials {
  email: string
  password: string
}

interface SignupCredentials {
  name: string
  email: string
  password: string
}

export const login = createAsyncThunk(
  "auth/login",
  async (credentials: LoginCredentials) => {
    return await loginApi(credentials)
  },
)

export const getProfile = createAsyncThunk(
  "auth/me",
  async (_, { rejectWithValue }) => {
    try {
      const profile = await getProfileApi()
      return profile
    } catch (error) {
      return rejectWithValue((error as Error).message)
    }
  },
)

export const signup = createAsyncThunk(
  "auth/signup",
  async (credentials: SignupCredentials) => {
    return await signupApi(credentials)
  },
)

export const logout = createAsyncThunk("auth/logout", async () => {
  await logoutApi()
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action: PayloadAction<Profile>) => {
        state.user = action.payload
        state.authenticated = true
      })
      .addCase(login.rejected, (state, action) => {
        state.error = action.error.message || null
        state.authenticated = false
      })
      .addCase(signup.fulfilled, (state, action: PayloadAction<Profile>) => {
        state.user = action.payload
        state.authenticated = true
      })
      .addCase(signup.rejected, (state, action) => {
        state.error = action.error.message || null
      })
      .addCase(
        getProfile.fulfilled,
        (state, action: PayloadAction<Profile>) => {
          state.user = action.payload
          state.authenticated = true
        },
      )
      .addCase(getProfile.rejected, (state, action) => {
        state.authenticated = false
      })
      .addCase(logout.fulfilled, state => {
        state.user = undefined
        state.authenticated = false
        state.error = null
      })
  },
})

export default authSlice.reducer
export const selectProfile = (state: { auth: AuthState }) => state.auth.user
export const selectAuthenticated = (state: { auth: AuthState }) =>
  state.auth.authenticated
export const selectError = (state: { auth: AuthState }) => state.auth.error

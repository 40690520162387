import type {
  OscillationsResponseData,
  SeriesFunction,
  StepFunctionData,
} from "@/model"
import { defaultColor } from "@/preference"
import { Circle, Line } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import LabeledText from "../LabeledText"
import { LinePath } from "../LinePath"
import { addTransparency } from "../SeriesViewer"
import React from "react"

export const OscillationsViewer = ({
  step,
  func,
  style = {},
  innerWidth,
  innerHeight,
  xScale,
  yScale,
  pointerEvents,
  formatX,
  formatY,
}: {
  func: SeriesFunction
  step: StepFunctionData
  style?: Record<string, any>
  innerWidth: number
  innerHeight: number
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  pointerEvents: "none" | "auto"
  formatX: (v: number) => string
  formatY: (v: number) => string
}) => {
  const data = step.data as OscillationsResponseData
  const points = data.stability.moving_average_points || []
  const color = style.color || defaultColor
  return (
    <>
      {style.show_operating_limits && (
        <>
          <Line
            from={{
              x: 0,
              y: yScale(data.limits.limits.operating.lower),
            }}
            to={{
              x: innerWidth,
              y: yScale(data.limits.limits.operating.lower),
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={0}
            y={yScale(data.limits.limits.operating.lower)}
            text={formatY(data.limits.limits.operating.lower)}
            orientation="horizontal"
            color={color}
          />

          <Line
            from={{
              x: 0,
              y: yScale(data.limits.limits.operating.upper),
            }}
            to={{
              x: innerWidth,
              y: yScale(data.limits.limits.operating.upper),
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={0}
            y={yScale(data.limits.limits.operating.upper)}
            text={formatY(data.limits.limits.operating.upper)}
            orientation="horizontal"
            color={color}
          />
        </>
      )}

      {style.show_critical_limits && (
        <>
          <Line
            from={{
              x: 0,
              y: yScale(data.limits.limits.critical.lower),
            }}
            to={{
              x: innerWidth,
              y: yScale(data.limits.limits.critical.lower),
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={0}
            y={yScale(data.limits.limits.critical.lower)}
            text={formatY(data.limits.limits.critical.lower)}
            orientation="horizontal"
            color={color}
          />

          <Line
            from={{
              x: 0,
              y: yScale(data.limits.limits.critical.upper),
            }}
            to={{
              x: innerWidth,
              y: yScale(data.limits.limits.critical.upper),
            }}
            stroke={color}
            strokeWidth={1.5}
            strokeDasharray="5,5"
            pointerEvents={"none"}
          />
          <LabeledText
            x={0}
            y={yScale(data.limits.limits.critical.upper)}
            text={formatY(data.limits.limits.critical.upper)}
            orientation="horizontal"
            color={color}
          />
        </>
      )}

      {style.show_moving_average && (
        <LinePath
          key={step.id}
          data={points}
          xScale={xScale}
          yScale={yScale}
          strokeWidth={1.5}
          strokeDasharray="5,5"
          stroke={addTransparency(color, 0.8) || defaultColor}
        />
      )}

      {style.show_peaks &&
        data.peaks_and_valleys.peaks.points.map((p, i) => (
          <React.Fragment key={i}>
            <Line
              from={{
                x: xScale(p[0]),
                y: 0,
              }}
              to={{
                x: xScale(p[0]),
                y: innerHeight,
              }}
              stroke={color}
              strokeWidth={1.5}
              strokeDasharray="5,5"
              pointerEvents={"none"}
            />
            <LabeledText
              x={xScale(p[0])}
              y={innerHeight - 8}
              text={formatX(p[0])}
              orientation="vertical"
              color={color}
            />
          </React.Fragment>
        ))}

      {style.show_valleys &&
        data.peaks_and_valleys.valleys.points.map((p, i) => (
          <React.Fragment key={i}>
            <Line
              from={{
                x: xScale(p[0]),
                y: 0,
              }}
              to={{
                x: xScale(p[0]),
                y: innerHeight,
              }}
              stroke={color}
              strokeWidth={1.5}
              strokeDasharray="5,5"
              pointerEvents={"none"}
            />
            <LabeledText
              x={xScale(p[0])}
              y={innerHeight - 8}
              text={formatX(p[0])}
              orientation="vertical"
              color={color}
            />
          </React.Fragment>
        ))}
    </>
  )
}

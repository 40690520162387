import type { Annotation, AnnotationText, Task } from "@/model"
import { useState } from "react"

export const Circle = ({
  annotation,
  x,
  y,
  isActive,
  task,
  onClick,
}: {
  annotation: Annotation
  x: number
  y: number
  isActive: boolean
  task: Task
  onClick: (annotationId: string, text: string) => void
}) => {
  const [hoveredAnnotationId, setHoveredAnnotationId] = useState<
    string | undefined
  >()

  const circleRadius = 4
  const padding = 6 // Added padding

  return (
    <g>
      {/* Added a larger transparent circle for padding */}
      <circle
        cx={x}
        cy={y}
        r={circleRadius + padding}
        fill="transparent"
        className={"cursor-pointer"}
        onClick={e => {
          e.stopPropagation()
          onClick(annotation.id, (annotation.data as AnnotationText).text)
        }}
        onMouseEnter={() => setHoveredAnnotationId(annotation.id)}
        onMouseLeave={() => setHoveredAnnotationId(undefined)}
      />
      {/* Original circle */}
      <circle
        cx={x}
        cy={y}
        r={circleRadius}
        fill={
          isActive
            ? "#999999"
            : hoveredAnnotationId === annotation.id
              ? "gray"
              : "red"
        }
        pointerEvents="none"
      />
    </g>
  )
}

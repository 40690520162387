import * as api from "@/api"
import type { Datasource } from "@/model"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

interface DatasourcesState {
  datasources?: Datasource[]
  usages?: { [datasourceId: string]: boolean }
}

const initialState: DatasourcesState = {
  datasources: undefined,
  usages: {},
}

export const getDatasources = createAsyncThunk(
  "datasources/getDatasources",
  async () => {
    const datasources = await api.getDatasources()
    return datasources
  },
)

export const deleteDatasource = createAsyncThunk(
  "datasources/deleteDatasource",
  async (datasourceId: string) => {
    return api.deleteDatasource(datasourceId)
  },
)

export const datasourcesSlice = createSlice({
  name: "datasources",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDatasources.fulfilled, (state, action) => {
        state.datasources = action.payload.datasources
        state.usages = action.payload.usages
      })
      .addCase(deleteDatasource.pending, (state, action) => {
        state.datasources = state.datasources?.filter(
          datasource => datasource.id !== action.meta.arg,
        )
      })
  },
})

export default datasourcesSlice.reducer

export const selectAllDatasources = (state: {
  datasources: DatasourcesState
}) => state.datasources.datasources

export const selectDatasourceUsages = (state: {
  datasources: DatasourcesState
}) => state.datasources.usages

import type { SeriesFunction, SlopeResponseData } from "@/model"
import { defaultColor } from "@/preference"
import { Line } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import LabeledText from "../LabeledText"

export const SlopeViewer = ({
  data,
  func,
  style = {},
  innerWidth,
  innerHeight,
  xScale,
  yScale,
  pointerEvents,
  formatX,
  formatY,
}: {
  func: SeriesFunction
  data: SlopeResponseData
  style?: Record<string, any>
  innerWidth: number
  innerHeight: number
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  pointerEvents: "none" | "auto"
  formatX: (v: number) => string
  formatY: (v: number) => string
}) => {
  if (!data) {
    return null
  }

  const [startX, startY] = data.start_point
  const [endX, endY] = data.end_point

  const color = style.color || defaultColor
  return (
    <>
      {!func.style?.hidden && (
        <>
          {style?.show_line && (
            <>
              <Line
                from={{
                  x: xScale(startX),
                  y: yScale(startY),
                }}
                to={{
                  x: xScale(endX),
                  y: yScale(endY),
                }}
                stroke={color}
                strokeWidth={1.5}
                strokeDasharray="5,5"
                pointerEvents={"none"}
              />
              <Line
                from={{
                  x: xScale(startX),
                  y: 0,
                }}
                to={{
                  x: xScale(startX),
                  y: innerHeight,
                }}
                stroke={color}
                strokeWidth={1.5}
                strokeDasharray="5,5"
                pointerEvents={"none"}
              />
              <LabeledText
                x={xScale(endX)}
                y={innerHeight - 8}
                text={formatX(endX)}
                orientation="vertical"
                color={color}
                anchor="start"
              />
              <Line
                from={{
                  x: xScale(endX),
                  y: 0,
                }}
                to={{
                  x: xScale(endX),
                  y: innerHeight,
                }}
                stroke={color}
                strokeWidth={1.5}
                strokeDasharray="5,5"
                pointerEvents={"none"}
              />
              <LabeledText
                x={xScale(startX)}
                y={innerHeight - 8}
                text={formatX(startX)}
                orientation="vertical"
                color={color}
                anchor="start"
              />
            </>
          )}
          <text
            x={xScale(startX + (endX - startX) / 2) + 5}
            y={yScale(startY + (endY - startY) / 2)}
            fontSize={10}
            fill={color}
            pointerEvents={"none"}
          >
            Slope: {data.slope.toFixed(2)}
          </text>
        </>
      )}
    </>
  )
}

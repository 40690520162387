import { TASK_MEASURE, type Task } from "@/model"
import { selectPlot, useAppSelector } from "@/store"
import { Line } from "@visx/shape"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import React from "react"
import LabeledText from "./LabeledText"
import { CreateMinusIcon } from "./util"

export const ReferenceLines = ({
  plotId,
  xScale,
  yScale,
  innerWidth,
  innerHeight,
  formatNumberY,
  formatX,
  handleReferenceLineDelete,
  task,
}: {
  plotId: string
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  innerWidth: number
  innerHeight: number
  formatNumberY: (v: number) => string
  formatX: (v: number) => string
  handleReferenceLineDelete: (id: string) => () => void
  task: Task
}) => {
  const plot = useAppSelector(selectPlot(plotId))
  const lines = plot.reference_lines || []

  if (lines.length === 0 || !plot) {
    return null
  }

  return lines.map(line => {
    return (
      <React.Fragment key={line.id}>
        <Line
          from={{
            x: line.orientation === "horizontal" ? 0 : xScale(line.value),
            y: line.orientation === "horizontal" ? yScale(line.value) : 0,
          }}
          to={{
            x:
              line.orientation === "horizontal"
                ? innerWidth
                : xScale(line.value),
            y:
              line.orientation === "horizontal"
                ? yScale(line.value)
                : innerHeight,
          }}
          stroke="gray"
          strokeWidth={1}
          strokeDasharray="5,5"
          style={{ pointerEvents: "none" }}
        />
        <LabeledText
          x={line.orientation === "horizontal" ? 0 : xScale(line.value)}
          y={
            line.orientation === "horizontal" ? yScale(line.value) : innerHeight
          }
          text={
            line.orientation === "horizontal"
              ? formatNumberY(line.value)
              : formatX(line.value)
          }
          orientation={line.orientation}
          variant="basic"
        />
        {task.task === TASK_MEASURE && (
          <CreateMinusIcon
            x={
              line.orientation === "horizontal"
                ? innerWidth + 13
                : xScale(line.value)
            }
            y={line.orientation === "horizontal" ? yScale(line.value) : -13}
            onClick={handleReferenceLineDelete(line.id)}
          />
        )}
      </React.Fragment>
    )
  })
}

import * as api from "@/api/chart"
import {
  defaultNumberOfPoints,
  FUNC_RISING_TIME,
  TASK_EDIT,
  type ActiveLocalTask,
  type ActiveLocalTaskData,
  type ActiveLocalTaskDataSeriesFunction,
  type Annotation,
  type Chart,
  type Datasource,
  type HoverValues,
  type Message,
  type Plot,
  type PlotFunction,
  type PlotQuery,
  type ReferenceLine,
  type Series,
  type SeriesData,
  type SeriesFunction,
  type SeriesQuery,
  type Task,
  type Zoom,
  type ZoomRequest,
  type ZoomSelection,
} from "@/model"
import { chartLineColors, defaultColor } from "@/preference"
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

let cachePlotsData: { [plotId: string]: SeriesData[] } = {}

interface ChartState {
  chart: Chart | undefined
  plots: {
    byId: Record<string, Plot>
    allIds: string[]
  }
  serieses: {
    byId: Record<string, Series>
    allIds: {
      [plotId: string]: string[]
    }
  }
  functions: {
    byId: Record<string, PlotFunction>
    allIds: {
      [seriesId: string]: string[]
    }
  }
  plotsDataLoading: Record<string, boolean>
  plotsHoverValues: {
    [plotId: string]: HoverValues
  }
  // This is to keep the state of box selection,
  // so that we can disable the pointerEvents
  // which will prevent the mouse over calculation
  isSelectingBox: boolean
  plotCurrentTask: {
    [plotId: string]: Task
  }
  activeLocalTask: {
    current: ActiveLocalTask | undefined
    positions: {
      [id: string]: { x: number; y: number }
    }
    subPositions: {
      [id: string]: { x: number; y: number; collapsed?: boolean }
    }
  }
  plotsZoomSelection: {
    [plotId: string]: ZoomSelection | undefined
  }
  plotsZoomRequest: {
    [plotId: string]: ZoomRequest | "zoomout"
  }
  datasources: Record<string, Datasource>
  isLoadingDatasources: boolean
  activePlotId: string | undefined
  functionActiveSeriesId: { [plotId: string]: string }
  copilot: {
    messages: Message[]
    isTyping: boolean
  }
  configComponentEnabled: boolean
  datasourceComponentEnabled: boolean
}

const initialState: ChartState = {
  chart: undefined,
  plots: {
    byId: {},
    allIds: [],
  },
  plotsHoverValues: {},
  plotsZoomSelection: {},
  plotsZoomRequest: {},
  serieses: {
    byId: {},
    allIds: {},
  },
  activeLocalTask: {
    current: undefined,
    positions: {},
    subPositions: {},
  },
  plotCurrentTask: {},
  functions: {
    byId: {},
    allIds: {},
  },
  plotsDataLoading: {},
  isSelectingBox: false,
  datasources: {},
  isLoadingDatasources: false,
  activePlotId: undefined,
  functionActiveSeriesId: {},
  copilot: {
    messages: [],
    isTyping: false,
  },
  configComponentEnabled: false,
  datasourceComponentEnabled: false,
}

let maxPoints: number
const getMaxPoints = () => {
  if (maxPoints) {
    return maxPoints
  }

  if (typeof window === "undefined") {
    maxPoints = defaultNumberOfPoints
    return maxPoints
  }

  if (!window.screen) {
    return defaultNumberOfPoints
  }

  maxPoints = Math.min(
    Math.floor(window.screen.width * 0.95) || defaultNumberOfPoints,
    2000,
  )

  return maxPoints
}

export const seriesToQuery = (series: Series): SeriesQuery => ({
  series_id: series.id,
  datasource_id: series.datasource_id,
  x: {
    name: series.x,
    offset: series.x_offset,
  },
  y: {
    name: series.y,
    scale: series.y_scale,
    offset: series.y_offset,
  },
  functions: (series.functions || []).map(f => ({
    id: f.id,
    type: f.type,
    data: f.data,
  })),
})

export const plotToQuery = (
  plot: Plot,
  serieses: Series[],
  functions: PlotFunction[],
): PlotQuery => ({
  plot_id: plot.id,
  number_of_points: getMaxPoints(),
  series: (serieses || []).map(seriesToQuery),
  functions: functions.map(f => ({
    id: f.id,
    type: f.type,
    data: f.data,
  })),
  zoom: plot.zoom,
})

export const loadChart = createAsyncThunk(
  "chart/loadChart",
  async (chartId: string) => {
    const chart = await api.getChart(chartId)
    return chart
  },
)

export const updateChart = createAsyncThunk(
  "chart/updateChart",
  async (chart: Partial<Chart>, { getState }) => {
    const state = getState() as { chart: ChartState }
    api.updateChart(state.chart.chart!.id, chart)
  },
)

export const loadDatasources = createAsyncThunk(
  "chart/loadDatasources",
  async (chartId: string) => {
    return (await api.getChartDatasources(chartId)) || {}
  },
)

export const loadPlots = createAsyncThunk(
  "chart/loadPlots",
  async (
    {
      chartId,
      zoom,
    }: {
      chartId: string
      zoom?: {
        x_max?: number
        x_min?: number
        y_max?: number
        y_min?: number
      }
    },
    { dispatch },
  ) => {
    const plots = ((await api.getChartPlots(chartId)) || []).map(plot => ({
      ...plot,
      serieses: plot.serieses || [],
      zoom: zoom || plot.zoom || {},
    }))

    dispatch(
      queryPlotsDatas({
        chartId: chartId,
        plots: plots,
        functions: plots.flatMap(plot => plot.functions || []),
        serieses: plots.flatMap(plot => plot.serieses || []),
      }),
    )

    return plots
  },
)

export const queryPlotsDatas = createAsyncThunk(
  "chart/queryPlotsDatas",
  async ({
    chartId,
    plots,
    serieses,
    functions,
  }: {
    chartId: string
    plots: Plot[]
    serieses: Series[]
    functions: PlotFunction[]
  }) => {
    const queries = plots.map(plot =>
      plotToQuery(
        plot,
        serieses.filter(s => s.plot_id === plot.id),
        functions.filter(f => f.plot_id === plot.id),
      ),
    )
    const plotsDatas = (await api.queryPlotsDatas(chartId, queries)) || []
    return plotsDatas
  },
)

export const addPlot = createAsyncThunk(
  "chart/addPlot",
  async (plot: Plot, { getState, dispatch }) => {
    const state = getState() as { chart: ChartState }

    const newPlot = await api.addPlot(state.chart.chart!.id, {
      ...plot,
      serieses: [],
      functions: [],
    })
    return newPlot
  },
)

export const deletePlot = createAsyncThunk(
  "chart/deletePlot",
  async ({ chartId, plotId }: { chartId: string; plotId: string }) => {
    await api.deletePlot(chartId, plotId)
    return plotId
  },
)

export const zoomPlot = createAsyncThunk(
  "chart/zoom",
  async (
    {
      plotId,
      zoom,
      readonly,
    }: {
      plotId: string
      zoom: Zoom
      readonly?: boolean
    },
    { getState, dispatch },
  ) => {
    const state = getState() as { chart: ChartState }
    const plot = { ...state.chart.plots.byId[plotId], zoom }
    const serieses = Object.values(state.chart.serieses.byId).filter(
      s => s.plot_id === plotId,
    )
    const functions = Object.values(state.chart.functions.byId).filter(
      f => f.plot_id === plotId,
    )

    if (!readonly) {
      await api.updatePlot(state.chart.chart!.id, plotId, {
        zoom,
      })
    }

    await dispatch(
      queryPlotsDatas({
        chartId: state.chart.chart!.id,
        plots: [plot],
        functions,
        serieses,
      }),
    )
  },
)

export const updatePlot = createAsyncThunk(
  "chart/updatePlot",
  async (
    {
      plotId,
      plot: data,
    }: {
      plotId: string
      plot: Partial<Plot>
    },
    { getState },
  ) => {
    const state = getState() as { chart: ChartState }

    const updatedPlot = await api.updatePlot(
      state.chart.chart!.id,
      plotId,
      data,
    )

    return updatedPlot
  },
)

export const movePlots = createAsyncThunk(
  "chart/movePlots",
  async ({ chartId, plotIds }: { chartId: string; plotIds: string[] }) => {
    await api.updatePlotPositions(chartId, plotIds)
    return plotIds
  },
)

const createNewSeries = (
  plotId: string,
  state: { chart: ChartState },
): Series => {
  const serieses = (state.chart.serieses.allIds[plotId] || []).map(
    id => state.chart.serieses.byId[id],
  )

  const selectedColors = (serieses || []).reduce(
    (acc, c) => {
      acc[c.color] = true
      return acc
    },
    {} as { [color: string]: boolean },
  )

  let newColor = defaultColor
  for (const color of chartLineColors) {
    if (!selectedColors[color]) {
      newColor = color
      break
    }
  }

  return {
    id: uuidv4(),
    plot_id: plotId,
    priority: new Date().getTime(),
    x: "",
    y: "",
    color: newColor,
    datasource_id: "",
    visible: true,
  }
}

export const removeAllRisingTimes = createAsyncThunk(
  "charts/removeAlLRisingTimes",
  async (
    {
      chartId,
      plotId,
      deletionTasks,
    }: {
      chartId: string
      plotId: string
      deletionTasks: {
        seriesId: string
        functionId: string
      }[]
    },
    { getState },
  ) => {
    await Promise.all(
      deletionTasks.map(({ seriesId, functionId }) =>
        api.deleteSeriesFunction(chartId, plotId, seriesId, functionId),
      ),
    )
  },
)

export const addRisingTimes = createAsyncThunk(
  "charts/addRisingTimes",
  async (
    {
      plotId,
      seriesId,
      settling_band,
      smooth_sigma,
    }: {
      plotId: string
      seriesId: string
      settling_band: number
      smooth_sigma: number
    },
    { getState, dispatch },
  ) => {
    const state = getState() as { chart: ChartState }
    const chart = state.chart.chart
    if (!seriesId) {
      return
    }

    const series = state.chart.serieses.byId[seriesId]
    if (!series) {
      return
    }

    const boxes = await api.findAbnormalBoxes(chart!.id, plotId, {
      series: seriesToQuery(series),
    })

    dispatch(
      addSeriesFunctions({
        chartId: chart!.id,
        plotId: series.plot_id,
        seriesId: series.id,
        seriesFunctions: boxes.map(box => ({
          id: uuidv4(),
          type: FUNC_RISING_TIME,
          data: {
            x_min: box.x_min,
            x_max: box.x_max,
            y_min: box.y_min,
            y_max: box.y_max,
            settling_band,
            smooth_sigma,
          },
          style: {
            color: defaultColor,
          },
        })),
        refreshData: true,
      }),
    )
  },
)

export const addSeriesFunctions = createAsyncThunk(
  "chart/addSeriesFunctions",
  async (
    {
      chartId,
      plotId,
      seriesId,
      seriesFunctions,
      refreshData,
    }: {
      chartId: string
      plotId: string
      seriesId: string
      seriesFunctions: SeriesFunction[]
      refreshData?: boolean
    },
    { getState, dispatch },
  ) => {
    await Promise.all(
      seriesFunctions.map(f =>
        api.createSeriesFunction(chartId, plotId, seriesId, f),
      ),
    )

    const state = getState() as { chart: ChartState }
    if (refreshData) {
      const plot = state.chart.plots.byId[plotId]
      const serieses = Object.values(state.chart.serieses.byId).filter(
        s => s.plot_id === plotId,
      )
      const functions = Object.values(state.chart.functions.byId).filter(
        f => f.plot_id === plotId,
      )

      dispatch(
        queryPlotsDatas({
          chartId: state.chart.chart!.id,
          plots: [plot],
          functions,
          serieses: [...serieses],
        }),
      )
    }
  },
)

export const addSeriesFunction = createAsyncThunk(
  "chart/addSeriesFunction",
  async (
    {
      chartId,
      plotId,
      seriesId,
      seriesFunction,
      refreshData,
      position,
    }: {
      chartId: string
      plotId: string
      seriesId: string
      seriesFunction: SeriesFunction
      refreshData?: boolean
      position?: {
        x: number
        y: number
        subx: number
        suby: number
      }
    },
    { getState, dispatch },
  ) => {
    await api.createSeriesFunction(chartId, plotId, seriesId, seriesFunction)

    const state = getState() as { chart: ChartState }
    if (refreshData) {
      const plot = state.chart.plots.byId[plotId]
      const serieses = Object.values(state.chart.serieses.byId).filter(
        s => s.plot_id === plotId,
      )
      const functions = Object.values(state.chart.functions.byId).filter(
        f => f.plot_id === plotId,
      )

      if (position) {
        dispatch(
          setActiveLocalTask({
            id: seriesFunction.id,
            type: seriesFunction.type,
            x: position.x,
            y: position.y,
            subx: position.subx,
            suby: position.suby,
            data: {
              plotId,
              seriesId,
              seriesFunctionId: seriesFunction.id,
            } as ActiveLocalTaskDataSeriesFunction,
          }),
        )
      }

      dispatch(
        setPlotCurrentTask({
          plotId,
          task: {
            task: TASK_EDIT,
          },
        }),
      )

      dispatch(
        queryPlotsDatas({
          chartId: state.chart.chart!.id,
          plots: [plot],
          functions,
          serieses: [...serieses],
        }),
      )
    }
  },
)

export const updateSeriesFunctionStyle = createAsyncThunk(
  "chart/updateSeriesFunctionStyle",
  async (
    {
      plotId,
      seriesId,
      seriesFunctionId,
      style,
    }: {
      plotId: string
      seriesId: string
      seriesFunctionId: string
      style: Partial<SeriesFunction["style"]>
    },
    { getState },
  ) => {
    const state = getState() as { chart: ChartState }
    const chartId = state.chart.chart!.id
    const seriesFn = state.chart.serieses.byId[seriesId]?.functions?.find(
      f => f.id === seriesFunctionId,
    )
    if (!seriesFn) {
      return
    }

    await api.updateSeriesFunction(
      chartId,
      plotId,
      seriesId,
      seriesFunctionId,
      { style: { ...seriesFn.style, ...style } },
    )
  },
)

export const updateSeriesFunction = createAsyncThunk(
  "chart/updateSeriesFunction",
  async (
    {
      chartId,
      plotId,
      seriesId,
      seriesFunction,
      refreshData,
    }: {
      chartId: string
      plotId: string
      seriesId: string
      seriesFunction: Partial<SeriesFunction> & { id: string }
      refreshData?: boolean
    },
    { getState, dispatch },
  ) => {
    await api.updateSeriesFunction(
      chartId,
      plotId,
      seriesId,
      seriesFunction.id!,
      seriesFunction,
    )

    if (refreshData) {
      const state = getState() as { chart: ChartState }
      const plot = state.chart.plots.byId[plotId]
      const serieses = Object.values(state.chart.serieses.byId)
        .filter(s => s.plot_id === plotId)
        .map(s => {
          if (s.id === seriesId) {
            const functions = s.functions || []
            const newS = {
              ...s,
              functions: functions.map(f => {
                return f.id === seriesFunction.id!
                  ? { ...f, ...seriesFunction }
                  : f
              }),
            }

            return newS
          }
          return s
        })
      const functions = Object.values(state.chart.functions.byId).filter(
        f => f.plot_id === plotId,
      )

      dispatch(
        queryPlotsDatas({
          chartId: state.chart.chart!.id,
          plots: [plot],
          functions,
          serieses: [...serieses],
        }),
      )
    }
  },
)

export const deleteSeriesFunction = createAsyncThunk(
  "chart/deleteSeriesFunction",
  async (
    {
      plotId,
      seriesId,
      seriesFunctionId,
    }: {
      plotId: string
      seriesId: string
      seriesFunctionId: string
    },
    { getState, dispatch },
  ) => {
    const state = getState() as { chart: ChartState }
    const chartId = state.chart.chart!.id

    await api.deleteSeriesFunction(chartId, plotId, seriesId, seriesFunctionId)
    const plot = state.chart.plots.byId[plotId]
    const serieses = Object.values(state.chart.serieses.byId).filter(
      s => s.plot_id === plotId,
    )
    const functions = Object.values(state.chart.functions.byId).filter(
      f => f.plot_id === plotId,
    )

    dispatch(
      queryPlotsDatas({
        chartId: state.chart.chart!.id,
        plots: [plot],
        functions,
        serieses,
      }),
    )
  },
)

export const addPlotFunction = createAsyncThunk(
  "chart/addPlotFunction",
  async (
    {
      chartId,
      plotId,
      plotFunction,
      refreshData,
    }: {
      chartId: string
      plotId: string
      plotFunction: PlotFunction
      refreshData?: boolean
    },
    { getState },
  ) => {
    await api.createPlotFunction(chartId, plotId, plotFunction)

    if (refreshData) {
      const state = getState() as { chart: ChartState }
      const plot = state.chart.plots.byId[plotId]
      const serieses = Object.values(state.chart.serieses.byId).filter(
        s => s.plot_id === plotId,
      )

      const functions = Object.values(state.chart.functions.byId).filter(
        f => f.plot_id === plotId,
      )

      await queryPlotsDatas({
        chartId: state.chart.chart!.id,
        plots: [plot],
        serieses: serieses,
        functions: [...functions, plotFunction],
      })
    }
  },
)

export const updatePlotFunction = createAsyncThunk(
  "chart/updatePlotFunction",
  async (
    {
      chartId,
      plotId,
      plotFunction,
      refreshData,
    }: {
      chartId: string
      plotId: string
      plotFunction: Partial<PlotFunction> & { id: string }
      refreshData?: boolean
    },
    { getState },
  ) => {
    await api.updatePlotFunction(
      chartId,
      plotId,
      plotFunction.id!,
      plotFunction,
    )

    if (refreshData) {
      const state = getState() as { chart: ChartState }
      const plot = state.chart.plots.byId[plotId]
      const serieses = Object.values(state.chart.serieses.byId).filter(
        s => s.plot_id === plotId,
      )
      const functions = Object.values(state.chart.functions.byId)
        .filter(f => f.plot_id === plotId)
        .map(f => (f.id === plotFunction.id ? { ...f, ...plotFunction } : f))

      await queryPlotsDatas({
        chartId: state.chart.chart!.id,
        plots: [plot],
        serieses,
        functions,
      })
    }
  },
)

export const deletePlotFunction = createAsyncThunk(
  "chart/deletePlotFunction",
  async (
    {
      chartId,
      plotId,
      plotFunctionId,
    }: {
      chartId: string
      plotId: string
      plotFunctionId: string
    },
    { getState },
  ) => {
    await api.deletePlotFunction(chartId, plotId, plotFunctionId)

    const state = getState() as { chart: ChartState }
    const plot = state.chart.plots.byId[plotId]
    const serieses = Object.values(state.chart.serieses.byId).filter(
      s => s.plot_id === plotId,
    )
    const functions = Object.values(state.chart.functions.byId)
      .filter(f => f.plot_id === plotId)
      .filter(f => f.id !== plotFunctionId)

    await queryPlotsDatas({
      chartId: state.chart.chart!.id,
      plots: [plot],
      serieses,
      functions,
    })
  },
)

export const addSeries = createAsyncThunk(
  "chart/addSeries",
  async (
    {
      plotId,
      data = {},
      refreshData = false,
    }: {
      plotId: string
      data?: Partial<Series>
      refreshData?: boolean
    },
    { getState, dispatch },
  ) => {
    const state = getState() as { chart: ChartState }
    const plot = state.chart.plots.byId[plotId]

    let series = createNewSeries(plotId, state)
    series = { ...series, ...data }

    dispatch(
      chartSlice.actions.addSeriesOptimistic({
        plotId,
        series,
      }),
    )

    const newSeries = await api.addSeries(state.chart.chart!.id, plotId, series)

    if (refreshData) {
      const serieses = Object.values(state.chart.serieses.byId).filter(
        s => s.plot_id === plotId,
      )
      const functions = Object.values(state.chart.functions.byId).filter(
        f => f.plot_id === plotId,
      )

      dispatch(
        queryPlotsDatas({
          chartId: state.chart.chart!.id,
          plots: [plot],
          serieses: [...serieses, newSeries],
          functions,
        }),
      )
    }

    return { plotId, series: newSeries }
  },
)

export const updateSeries = createAsyncThunk(
  "chart/updateSeries",
  async (
    {
      plotId,
      series,
      seriesId,
      refreshData,
    }: {
      plotId: string
      seriesId: string
      series: Partial<Series>
      refreshData?: boolean
    },
    { getState, dispatch },
  ) => {
    const state = getState() as { chart: ChartState }
    api.updateSeries(state.chart.chart!.id, plotId, series)

    if (!refreshData) {
      return
    }

    const plot = state.chart.plots.byId[plotId]
    const serieses = Object.values(state.chart.serieses.byId)
      .filter(s => s.plot_id === plotId)
      .map(s => (s.id === seriesId ? { ...s, ...series } : s))
    const functions = Object.values(state.chart.functions.byId).filter(
      f => f.plot_id === plotId,
    )

    dispatch(
      queryPlotsDatas({
        chartId: state.chart.chart!.id,
        plots: [plot],
        serieses,
        functions,
      }),
    )
  },
)

export const deleteSeries = createAsyncThunk(
  "chart/deleteSeries",
  async (
    {
      plotId,
      seriesId,
    }: {
      plotId: string
      seriesId: string
    },
    { getState, dispatch },
  ) => {
    const state = getState() as { chart: ChartState }
    const plot = state.chart.plots.byId[plotId]
    const serieses = Object.values(state.chart.serieses.byId)
      .filter(s => s.plot_id === plotId)
      .filter(s => s.id !== seriesId)
    const functions = Object.values(state.chart.functions.byId).filter(
      f => f.plot_id === plotId,
    )

    await api.deleteSeries(state.chart.chart!.id, plotId, seriesId)

    dispatch(
      queryPlotsDatas({
        chartId: state.chart.chart!.id,
        plots: [plot],
        serieses,
        functions,
      }),
    )

    return { plotId, seriesId }
  },
)

export const addReferenceLine = createAsyncThunk(
  "chart/addReferenceLine",
  async (
    {
      plotId,
      referenceLine,
    }: {
      plotId: string
      referenceLine: ReferenceLine
    },
    { getState },
  ) => {
    const state = getState() as { chart: ChartState }
    await api.addReferenceLine(state.chart.chart!.id, plotId, referenceLine)
    return { plotId, referenceLine }
  },
)

export const deleteReferenceLine = createAsyncThunk(
  "chart/deleteReferenceLine",
  async (
    {
      plotId,
      referenceLineId,
    }: {
      plotId: string
      referenceLineId: string
    },
    { getState },
  ) => {
    const state = getState() as { chart: ChartState }
    await api.deleteReferenceLine(
      state.chart.chart!.id,
      plotId,
      referenceLineId,
    )
    return { plotId, referenceLineId }
  },
)

export const addAnnotation = createAsyncThunk(
  "chart/addAnnotation",
  async (
    {
      plotId,
      annotation,
    }: {
      plotId: string
      annotation: Annotation
    },
    { getState, dispatch },
  ) => {
    const state = getState() as { chart: ChartState }
    api.addAnnotation(state.chart.chart!.id, plotId, annotation)

    dispatch(
      setPlotCurrentTask({
        plotId,
        task: {
          task: TASK_EDIT,
        },
      }),
    )

    return { plotId, annotation }
  },
)

export const deleteAnnotation = createAsyncThunk(
  "chart/deleteAnnotation",
  async (
    {
      plotId,
      annotationId,
    }: {
      plotId: string
      annotationId: string
    },
    { getState },
  ) => {
    const state = getState() as { chart: ChartState }
    await api.deleteAnnotation(state.chart.chart!.id, plotId, annotationId)
    return { plotId, annotationId }
  },
)

export const doFitToY = createAsyncThunk(
  "chart/doFitToY",
  async ({ plotId }: { plotId: string }, { getState, dispatch }) => {
    const state = getState() as { chart: ChartState }
    const plot = state.chart.plots.byId[plotId]
    const chartId = state.chart.chart!.id

    if (!plot.zoom || !plot.zoom.x_min || !plot.zoom.x_max) {
      return
    }

    const [y_min, y_max] = await api.getYRange(
      chartId,
      plotId,
      plot.zoom.x_min,
      plot.zoom.x_max,
    )

    const zoom = {
      x_min: plot.zoom.x_min,
      x_max: plot.zoom.x_max,
      y_min,
      y_max,
    }

    dispatch(zoomPlot({ plotId, zoom }))
  },
)

export const updateAnnotation = createAsyncThunk(
  "chart/updateAnnotation",
  async (
    {
      plotId,
      annotationId,
      data,
    }: {
      plotId: string
      annotationId: string
      data: Partial<Annotation["data"]>
    },
    { getState },
  ) => {
    const state = getState() as { chart: ChartState }
    const annotation = state.chart.plots.byId[plotId].annotations?.find(
      a => a.id === annotationId,
    )
    if (!annotation) {
      return
    }

    await api.updateAnnotation(state.chart.chart!.id, plotId, annotationId, {
      data: { ...annotation.data, ...data },
    })
    return { plotId, annotation }
  },
)

export const askCopilot = createAsyncThunk(
  "chart/askCopilot",
  async (message: string) => {
    await new Promise(resolve => setTimeout(resolve, 1500))
    return {
      message: "AI response is here",
    }
  },
)

const isSeriesMatched = (series1: Series, series2: Series) =>
  series1.x === series2.x && series1.datasource_id === series2.datasource_id

const isPlotSeriesMatched = (
  state: ChartState,
  plotId: string,
  toCompareSeries: Series,
) => {
  const plotSeriesIds = state.serieses.allIds[plotId] || []
  if (plotSeriesIds.length === 0) {
    return false
  }

  for (const seriesId of plotSeriesIds) {
    const series = state.serieses.byId[seriesId]
    if (!isSeriesMatched(series, toCompareSeries)) {
      return false
    }
  }

  return true
}

const isPlotZoomMatched = (
  state: ChartState,
  plot1Id: string,
  plot2Id: string,
) => {
  const plot1 = state.plots.byId[plot1Id]
  const plot2 = state.plots.byId[plot2Id]

  if (!plot1.zoom && !plot2.zoom) {
    return true
  }

  if (!plot1.zoom || !plot2.zoom) {
    return false
  }

  if (plot1.zoom === plot2.zoom) {
    return true
  }

  return (
    plot1.zoom.x_min === plot2.zoom.x_min &&
    plot1.zoom.x_max === plot2.zoom.x_max
  )
}

const getToCompareSeries = (state: ChartState, plotId: string) => {
  const plotSeriesIds = state.serieses.allIds[plotId] || []
  if (plotSeriesIds.length === 0) {
    // should never happens
    return
  }
  return state.serieses.byId[plotSeriesIds[0]]
}

const isPlotMatched = (
  state: ChartState,
  plot1Id: string,
  plot2Id: string,
  toCompareSeries: Series,
) => {
  return (
    isPlotSeriesMatched(state, plot1Id, toCompareSeries) &&
    isPlotSeriesMatched(state, plot2Id, toCompareSeries) &&
    isPlotZoomMatched(state, plot1Id, plot2Id)
  )
}

export const chartSlice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    resetChart: () => {
      cachePlotsData = {}
      return initialState
    },
    setActivePlotId: (state, action) => {
      state.activePlotId = action.payload
    },
    addSeriesOptimistic: (
      state,
      action: PayloadAction<{ plotId: string; series: Series }>,
    ) => {
      const { plotId, series } = action.payload
      state.serieses.byId[series.id] = series
      state.serieses.allIds[plotId] = [
        ...(state.serieses.allIds[plotId] || []),
        series.id,
      ]
    },
    setPlotHoverValues: (
      state,
      action: PayloadAction<{ plotId: string; hoverValues: HoverValues }>,
    ) => {
      const { plotId, hoverValues } = action.payload
      state.plotsHoverValues[plotId] = hoverValues

      const toCompareSeries = getToCompareSeries(state, plotId)
      if (!toCompareSeries) {
        return
      }

      if (!isPlotSeriesMatched(state, plotId, toCompareSeries)) {
        return
      }

      const otherPlotIds = state.plots.allIds.filter(id => id !== plotId)
      for (const otherPlotId of otherPlotIds) {
        if (!isPlotMatched(state, plotId, otherPlotId, toCompareSeries)) {
          continue
        }

        state.plotsHoverValues[otherPlotId] = hoverValues
      }
    },
    setPlotZoomSelection: (
      state,
      action: PayloadAction<{ plotId: string; zoom?: ZoomSelection }>,
    ) => {
      const { plotId, zoom } = action.payload
      state.plotsZoomSelection[plotId] = zoom

      const toCompareSeries = getToCompareSeries(state, plotId)
      if (!toCompareSeries) {
        return
      }

      const otherPlotIds = state.plots.allIds.filter(id => id !== plotId)
      for (const otherPlotId of otherPlotIds) {
        if (!isPlotMatched(state, plotId, otherPlotId, toCompareSeries)) {
          continue
        }

        state.plotsZoomSelection[otherPlotId] = zoom
      }
    },
    doZoomRequest: (
      state,
      action: PayloadAction<{
        plotId: string
        zoomRequest: ZoomRequest | "zoomout"
      }>,
    ) => {
      const { plotId, zoomRequest } = action.payload
      state.plotsZoomRequest[plotId] = zoomRequest

      const toCompareSeries = getToCompareSeries(state, plotId)
      if (!toCompareSeries) {
        return
      }

      const otherPlotIds = state.plots.allIds.filter(id => id !== plotId)
      for (const otherPlotId of otherPlotIds) {
        if (!isPlotMatched(state, plotId, otherPlotId, toCompareSeries)) {
          continue
        }

        state.plotsZoomRequest[otherPlotId] = zoomRequest
      }
    },
    doZoomOutRequest: (state, action: PayloadAction<{ plotId: string }>) => {
      const { plotId } = action.payload
      state.plotsZoomRequest[plotId] = "zoomout"

      const toCompareSeries = getToCompareSeries(state, plotId)
      if (!toCompareSeries) {
        return
      }

      const otherPlotIds = state.plots.allIds.filter(id => id !== plotId)
      for (const otherPlotId of otherPlotIds) {
        if (!isPlotMatched(state, plotId, otherPlotId, toCompareSeries)) {
          continue
        }

        state.plotsZoomRequest[otherPlotId] = "zoomout"
      }
    },

    addChatMessage: (state, action: PayloadAction<Message>) => {
      state.copilot.messages.push(action.payload)
    },
    restartChat: state => {
      state.copilot.messages = []
    },
    setIsTyping: (state, action: PayloadAction<boolean>) => {
      state.copilot.isTyping = action.payload
    },
    setConfigComponentEnabled: (state, action: PayloadAction<boolean>) => {
      state.configComponentEnabled = action.payload
    },
    setDatasourceComponentEnabled: (state, action: PayloadAction<boolean>) => {
      state.datasourceComponentEnabled = action.payload
    },
    setIsSelectingBox: (state, action: PayloadAction<boolean>) => {
      state.isSelectingBox = action.payload
    },
    setFunctionActiveSeriesId: (
      state,
      action: PayloadAction<{ plotId: string; seriesId: string }>,
    ) => {
      state.functionActiveSeriesId[action.payload.plotId] =
        action.payload.seriesId
    },
    setActiveLocalTask: (
      state,
      action: PayloadAction<{
        id: string
        type: string
        data: ActiveLocalTaskData | ActiveLocalTaskDataSeriesFunction
        x: number
        y: number
        subx?: number
        suby?: number
      }>,
    ) => {
      const current: ActiveLocalTask = {
        id: action.payload.id,
        type: action.payload.type,
        x: action.payload.x,
        y: action.payload.y,
        data: { ...action.payload.data },
      }
      const position = state.activeLocalTask.positions[action.payload.id] || {
        x: action.payload.x,
        y: action.payload.y,
      }

      state.activeLocalTask.current = current
      state.activeLocalTask.positions[action.payload.id] = position
      if (
        action.payload.subx !== undefined &&
        action.payload.suby !== undefined
      ) {
        state.activeLocalTask.subPositions[action.payload.id] = state
          .activeLocalTask.subPositions[action.payload.id] || {
          x: action.payload.subx,
          y: action.payload.suby,
        }
      }
    },
    setLocalTaskPosition: (
      state,
      action: PayloadAction<{ x: number; y: number }>,
    ) => {
      if (!state.activeLocalTask.current) {
        return
      }

      state.activeLocalTask.positions[state.activeLocalTask.current.id] = {
        x: action.payload.x,
        y: action.payload.y,
      }
    },
    setLocalTaskSubCollapsed: (state, action: PayloadAction<boolean>) => {
      if (!state.activeLocalTask.current) {
        return
      }

      state.activeLocalTask.subPositions[state.activeLocalTask.current.id] = {
        ...state.activeLocalTask.subPositions[state.activeLocalTask.current.id],
        collapsed: action.payload,
      }
    },
    setLocalTaskSubPosition: (
      state,
      action: PayloadAction<{ x: number; y: number }>,
    ) => {
      if (!state.activeLocalTask.current) {
        return
      }

      state.activeLocalTask.subPositions[state.activeLocalTask.current.id] = {
        x: action.payload.x,
        y: action.payload.y,
      }
    },
    disableLocalTask: state => {
      state.activeLocalTask.current = undefined
    },
    setPlotCurrentTask: (
      state,
      action: PayloadAction<{
        plotId: string
        task: Task
      }>,
    ) => {
      state.plotCurrentTask[action.payload.plotId] = action.payload.task
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadChart.fulfilled, (state, action) => {
        state.chart = action.payload
      })
      .addCase(updateChart.pending, (state, action) => {
        state.chart = { ...state.chart!, ...action.meta.arg }
      })
      .addCase(loadDatasources.pending, state => {
        state.isLoadingDatasources = true
      })
      .addCase(loadDatasources.fulfilled, (state, action) => {
        state.datasources = action.payload
        state.isLoadingDatasources = false
      })
      .addCase(loadPlots.fulfilled, (state, action) => {
        const plots = action.payload || []

        state.plots.byId = plots.reduce(
          (acc, plot) => {
            acc[plot.id] = plot
            return acc
          },
          {} as Record<string, Plot>,
        )
        state.plots.allIds = plots.map(plot => plot.id)

        state.serieses.byId = plots
          .flatMap(plot => plot.serieses || [])
          .reduce(
            (acc, series) => {
              acc[series.id] = series
              return acc
            },
            {} as Record<string, Series>,
          )

        state.serieses.allIds = plots.reduce(
          (acc, plot) => {
            acc[plot.id] = (plot.serieses || []).map(series => series.id)
            return acc
          },
          {} as { [plotId: string]: string[] },
        )

        state.functions.byId = plots
          .flatMap(plot => plot.functions || [])
          .reduce(
            (acc, f) => {
              acc[f.id] = f
              return acc
            },
            {} as Record<string, PlotFunction>,
          )

        state.functions.allIds = plots.reduce(
          (acc, plot) => {
            acc[plot.id] = (plot.functions || []).map(f => f.id)
            return acc
          },
          {} as { [plotId: string]: string[] },
        )

        state.plotCurrentTask = plots.reduce(
          (acc, plot) => {
            acc[plot.id] = {
              task: TASK_EDIT,
              subtask: "view",
            }
            return acc
          },
          {} as { [plotId: string]: Task },
        )

        if (state.activePlotId === undefined && state.plots.allIds.length > 0) {
          state.activePlotId = state.plots.byId[state.plots.allIds[0]].id
        }
      })
      .addCase(queryPlotsDatas.pending, (state, action) => {
        for (const plot of action.meta.arg.plots || []) {
          state.plotsDataLoading[plot.id] = true
        }
      })
      .addCase(queryPlotsDatas.fulfilled, (state, action) => {
        for (const plotData of action.payload) {
          state.plotsDataLoading[plotData.plot_id] = false
          cachePlotsData[plotData.plot_id] = plotData.series_data
        }
      })
      .addCase(addPlot.pending, (state, action) => {
        state.plots.byId[action.meta.arg.id] = action.meta.arg
        state.plots.allIds.push(action.meta.arg.id)
        state.plotsHoverValues[action.meta.arg.id] = {}
        state.activePlotId = action.meta.arg.id
        state.plotCurrentTask[action.meta.arg.id] = {
          task: TASK_EDIT,
          subtask: "view",
        }
      })
      .addCase(deletePlot.pending, (state, action) => {
        state.plots.allIds = state.plots.allIds.filter(
          id => id !== action.meta.arg.plotId,
        )
        delete state.plots.byId[action.meta.arg.plotId]
        delete state.plotsHoverValues[action.meta.arg.plotId]
        delete state.plotCurrentTask[action.meta.arg.plotId]
      })
      .addCase(updatePlot.pending, (state, action) => {
        state.plots.byId[action.meta.arg.plotId] = {
          ...state.plots.byId[action.meta.arg.plotId],
          ...action.meta.arg.plot,
        }
      })
      .addCase(movePlots.pending, (state, action) => {
        const plotIds = action.meta.arg.plotIds
        state.plots.allIds = plotIds
      })
      .addCase(updateSeries.pending, (state, action) => {
        const { series, seriesId } = action.meta.arg
        state.serieses.byId[seriesId] = {
          ...state.serieses.byId[seriesId],
          ...series,
        }
      })
      .addCase(deleteSeries.pending, (state, action) => {
        const { plotId, seriesId } = action.meta.arg
        state.serieses.allIds[plotId] = state.serieses.allIds[plotId].filter(
          id => id !== seriesId,
        )
        delete state.serieses.byId[seriesId]
        if (state.functionActiveSeriesId[plotId] === seriesId) {
          delete state.functionActiveSeriesId[plotId]
        }
      })
      .addCase(addReferenceLine.pending, (state, action) => {
        const { plotId, referenceLine } = action.meta.arg
        const plot = state.plots.byId[plotId]
        plot.reference_lines = [...(plot.reference_lines || []), referenceLine]
      })
      .addCase(deleteReferenceLine.pending, (state, action) => {
        const { plotId, referenceLineId } = action.meta.arg
        const plot = state.plots.byId[plotId]
        plot.reference_lines = plot.reference_lines?.filter(
          rl => rl.id !== referenceLineId,
        )
      })
      .addCase(deleteAnnotation.pending, (state, action) => {
        const { plotId, annotationId } = action.meta.arg
        const plot = state.plots.byId[plotId]
        state.activeLocalTask.current = undefined
        plot.annotations = plot.annotations?.filter(a => a.id !== annotationId)
      })
      .addCase(zoomPlot.fulfilled, (state, action) => {
        const { plotId, zoom } = action.meta.arg
        const plot = state.plots.byId[plotId]
        plot.zoom = zoom
      })
      .addCase(addAnnotation.pending, (state, action) => {
        const { plotId, annotation } = action.meta.arg
        const plot = state.plots.byId[plotId]
        plot.annotations = [...(plot.annotations || []), annotation]
      })
      .addCase(updateAnnotation.pending, (state, action) => {
        const { plotId, annotationId, data } = action.meta.arg
        const plot = state.plots.byId[plotId]
        const annotation = plot.annotations?.find(a => a.id === annotationId)
        if (!annotation) {
          return
        }
        annotation.data = { ...annotation.data, ...data }

        plot.annotations = plot.annotations?.map(a =>
          a.id === annotationId ? annotation : a,
        )
      })
      .addCase(askCopilot.pending, state => {
        state.copilot.isTyping = true
      })
      .addCase(askCopilot.fulfilled, (state, action) => {
        state.copilot.isTyping = false
        state.copilot.messages.push({
          text: action.payload.message,
          sender: "ai",
        })
      })
      .addCase(updateSeriesFunctionStyle.pending, (state, action) => {
        const { seriesId, seriesFunctionId, style } = action.meta.arg
        const series = state.serieses.byId[seriesId]
        series.functions = (series.functions || []).map(f => {
          if (f.id === seriesFunctionId) {
            f.style = { ...f.style, ...style }
          }
          return f
        })
      })
      .addCase(addSeriesFunctions.pending, (state, action) => {
        const { seriesId, seriesFunctions } = action.meta.arg
        const series = state.serieses.byId[seriesId]
        series.functions = series.functions || []
        series.functions = [...series.functions, ...seriesFunctions]
      })
      .addCase(addSeriesFunction.pending, (state, action) => {
        const { seriesId, seriesFunction } = action.meta.arg
        const series = state.serieses.byId[seriesId]
        series.functions = series.functions || []
        series.functions = [...series.functions, seriesFunction]

        // make the new function active
      })
      .addCase(updateSeriesFunction.pending, (state, action) => {
        const { seriesId, seriesFunction } = action.meta.arg

        const series = state.serieses.byId[seriesId]
        series.functions = (series.functions || []).map(f => {
          const newF =
            f.id === seriesFunction.id ? { ...f, ...seriesFunction } : f
          return newF
        })
      })
      .addCase(deleteSeriesFunction.pending, (state, action) => {
        const { seriesId, seriesFunctionId } = action.meta.arg
        const series = state.serieses.byId[seriesId]
        state.activeLocalTask.current = undefined

        series.functions = series.functions || []
        series.functions = series.functions.filter(
          f => f.id !== seriesFunctionId,
        )

        // delete in cache
        cachePlotsData[series.plot_id] = (
          cachePlotsData[series.plot_id] || []
        ).map(seriesData => {
          seriesData.functions_data = (seriesData.functions_data || []).filter(
            f => f.id !== seriesFunctionId,
          )

          return seriesData
        })
      })
      .addCase(addPlotFunction.pending, (state, action) => {
        const { plotId, plotFunction } = action.meta.arg
        state.functions.byId[plotFunction.id] = plotFunction
        state.functions.allIds[plotId] = [
          ...(state.functions.allIds[plotId] || []),
          plotFunction.id,
        ]
      })
      .addCase(updatePlotFunction.pending, (state, action) => {
        const { plotFunction } = action.meta.arg
        state.functions.byId[plotFunction.id] = {
          ...state.functions.byId[plotFunction.id],
          ...plotFunction,
        }
      })
      .addCase(deletePlotFunction.pending, (state, action) => {
        const { plotId, plotFunctionId } = action.meta.arg
        state.functions.allIds[plotId] = state.functions.allIds[plotId].filter(
          id => id !== plotFunctionId,
        )
        delete state.functions.byId[plotFunctionId]
      })
      .addCase(removeAllRisingTimes.pending, (state, action) => {
        // remove all functions in serieses that are rising times
        const { plotId, deletionTasks } = action.meta.arg
        for (const { seriesId, functionId } of deletionTasks) {
          const series = state.serieses.byId[seriesId]
          series.functions = series.functions?.filter(f => f.id !== functionId)
        }

        // remove serieses data in cache
        cachePlotsData[plotId] = (cachePlotsData[plotId] || []).map(
          seriesData => {
            seriesData.functions_data = seriesData.functions_data?.filter(
              f => !deletionTasks.find(t => t.functionId === f.id),
            )
            return seriesData
          },
        )
      })
  },
})

export const {
  resetChart,
  setActivePlotId,
  setPlotHoverValues,
  setPlotZoomSelection,
  addChatMessage,
  restartChat,
  setIsTyping,
  doZoomRequest,
  doZoomOutRequest,
  setConfigComponentEnabled,
  setDatasourceComponentEnabled,
  setIsSelectingBox,
  setFunctionActiveSeriesId,
  setActiveLocalTask,
  setLocalTaskPosition,
  setLocalTaskSubPosition,
  setLocalTaskSubCollapsed,
  disableLocalTask,
  setPlotCurrentTask,
} = chartSlice.actions

export default chartSlice.reducer

const selectChartState = (state: { chart: ChartState }) => state.chart

export const selectPlot =
  (plotId: string) =>
  (state: { chart: ChartState }): Plot => {
    return state.chart.plots.byId[plotId]
  }
export const selectChart = (state: { chart: ChartState }) => state.chart.chart

export const selectAllSerieses = (state: { chart: ChartState }) =>
  state.chart.serieses.byId

export const selectSerieses = (plotId: string) =>
  createSelector([selectChartState], chartState => {
    const seriesIds = chartState.serieses.allIds[plotId]
    return (seriesIds || []).map(id => chartState.serieses.byId[id])
  })

export const selectSeriesMap = (plotId: string) =>
  createSelector([selectChartState], chartState => {
    return (chartState.serieses.allIds[plotId] || []).reduce(
      (acc, id) => {
        acc[id] = chartState.serieses.byId[id]
        return acc
      },
      {} as Record<string, Series>,
    )
  })

export const selectPlotXType = (plotId: string) =>
  createSelector([selectChartState], chartState => {
    const { datasources, serieses } = chartState
    const series = Object.values(serieses.byId).find(s => s.plot_id === plotId)

    if (!series || !series.datasource_id) {
      return undefined
    }

    const datasource = datasources[series.datasource_id]
    const column = datasource?.columns.find(c => c.name === series.x)

    return column?.type
  })

export const selectActivePlotId = (state: { chart: ChartState }) =>
  state.chart.activePlotId
export const selectDatasources = (state: { chart: ChartState }) =>
  state.chart.datasources
const selectPlotsAllIds = (state: { chart: ChartState }) =>
  state.chart.plots.allIds
const selectPlotsById = (state: { chart: ChartState }) => state.chart.plots.byId
export const selectPlots = createSelector(
  [selectPlotsAllIds, selectPlotsById],
  (allIds, byId) => allIds.map(id => byId[id]),
)
export const getPlotData = (plotId: string) => cachePlotsData[plotId]
export const selectPlotHoverValues =
  (plotId: string) => (state: { chart: ChartState }) =>
    state.chart.plotsHoverValues[plotId]
export const selectPlotZoomSelection =
  (plotId: string) => (state: { chart: ChartState }) =>
    state.chart.plotsZoomSelection[plotId]
export const selectPlotZoomRequest =
  (plotId: string) => (state: { chart: ChartState }) =>
    state.chart.plotsZoomRequest[plotId]
export const selectChatMessages = (state: { chart: ChartState }) =>
  state.chart.copilot.messages
export const selectIsTyping = (state: { chart: ChartState }) =>
  state.chart.copilot.isTyping
export const selectConfigComponentEnabled = (state: { chart: ChartState }) =>
  state.chart.configComponentEnabled
export const selectDatasourceComponentEnabled = (state: {
  chart: ChartState
}) => state.chart.datasourceComponentEnabled
export const selectIsLoadingDatasources = (state: { chart: ChartState }) =>
  state.chart.isLoadingDatasources
export const selectPlotDataIsLoading =
  (plotId: string) => (state: { chart: ChartState }) =>
    !!state.chart.plotsDataLoading[plotId]
export const selectIsSelectingBox = (state: { chart: ChartState }) =>
  state.chart.isSelectingBox
export const selectLocalTask = (state: { chart: ChartState }) => {
  const { current, positions, subPositions } = state.chart.activeLocalTask
  if (!current) {
    return undefined
  }

  return {
    id: current.id,
    type: current.type,
    data: current.data,
    x: positions[current.id].x || 0,
    y: positions[current.id].y || 0,
    subCollapsed: !!subPositions[current.id]?.collapsed,
    subx: subPositions[current.id]?.x,
    suby: subPositions[current.id]?.y,
  }
}

export const selectSeriesFunction = (
  seriesId: string,
  seriesFunctionId: string,
) =>
  createSelector([selectChartState], chartState => {
    const series = chartState.serieses.byId[seriesId]
    return series?.functions?.find(f => f.id === seriesFunctionId)
  })

export const selectAnnotation = (plotId: string, annotationId: string) =>
  createSelector([selectChartState], chartState => {
    const plot = chartState.plots.byId[plotId]
    return plot?.annotations?.find(a => a.id === annotationId)
  })

export const selectFunctionActiveSeriesId = (plotId: string) =>
  createSelector([selectChartState], chartState => {
    return chartState.functionActiveSeriesId[plotId]
  })

export const selectPlotCurrentTask = (plotId: string) =>
  createSelector([selectChartState], chartState => {
    return chartState.plotCurrentTask[plotId] as Task | undefined
  })

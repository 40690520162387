import type { SeriesData } from "@/model"
import { utcFormat } from "d3-time-format"
import { useState } from "react"

// Cohen-Sutherland line clipping algorithm
const INSIDE = 0 // 0000
const LEFT = 1 // 0001
const RIGHT = 2 // 0010
const BOTTOM = 4 // 0100
const TOP = 8 // 1000

function computeOutCode(
  x: number,
  y: number,
  xmin: number,
  ymin: number,
  xmax: number,
  ymax: number,
): number {
  let code = INSIDE

  if (x < xmin) code |= LEFT
  else if (x > xmax) code |= RIGHT
  if (y < ymin) code |= BOTTOM
  else if (y > ymax) code |= TOP

  return code
}

export const clipLine = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  xmin: number,
  ymin: number,
  xmax: number,
  ymax: number,
): [number, number, number, number] | null => {
  let outcode1 = computeOutCode(x1, y1, xmin, ymin, xmax, ymax)
  let outcode2 = computeOutCode(x2, y2, xmin, ymin, xmax, ymax)
  let accept = false

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (!(outcode1 | outcode2)) {
      accept = true
      break
    } else if (outcode1 & outcode2) {
      break
    } else {
      let x: number, y: number
      const outcodeOut = outcode1 ? outcode1 : outcode2

      if (outcodeOut & TOP) {
        x = x1 + ((x2 - x1) * (ymax - y1)) / (y2 - y1)
        y = ymax
      } else if (outcodeOut & BOTTOM) {
        x = x1 + ((x2 - x1) * (ymin - y1)) / (y2 - y1)
        y = ymin
      } else if (outcodeOut & RIGHT) {
        y = y1 + ((y2 - y1) * (xmax - x1)) / (x2 - x1)
        x = xmax
      } else {
        y = y1 + ((y2 - y1) * (xmin - x1)) / (x2 - x1)
        x = xmin
      }

      if (outcodeOut === outcode1) {
        x1 = x
        y1 = y
        outcode1 = computeOutCode(x1, y1, xmin, ymin, xmax, ymax)
      } else {
        x2 = x
        y2 = y
        outcode2 = computeOutCode(x2, y2, xmin, ymin, xmax, ymax)
      }
    }
  }

  if (accept) {
    return [x1, y1, x2, y2]
  }
  return null
}

export const CreatePlusIcon = ({
  x,
  y,
  onClick,
  size = 12,
}: {
  x: number
  y: number
  onClick: () => void
  size?: number
}) => {
  const [hovered, setHovered] = useState(false)
  const stroke = hovered ? "red" : "black"

  return (
    <g
      transform={`translate(${x},${y})`}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle r={size / 2} fill="white" stroke={stroke} strokeWidth={1} />
      <line
        x1={-size / 3}
        y1={0}
        x2={size / 3}
        y2={0}
        stroke={stroke}
        strokeWidth={1}
      />
      <line
        x1={0}
        y1={-size / 3}
        x2={0}
        y2={size / 3}
        stroke={stroke}
        strokeWidth={1}
      />
    </g>
  )
}

export const CreateMinusIcon = ({
  x,
  y,
  onClick,
  size = 12,
}: {
  x: number
  y: number
  onClick: () => void
  size?: number
}) => {
  const [hovered, setHovered] = useState(false)
  const stroke = hovered ? "red" : "black"

  return (
    <g
      transform={`translate(${x},${y})`}
      onClick={onClick}
      style={{ cursor: "pointer" }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle r={size / 2} fill="white" stroke={stroke} strokeWidth={1} />
      <line
        x1={-size / 3}
        y1={0}
        x2={size / 3}
        y2={0}
        stroke={stroke}
        strokeWidth={1}
      />
    </g>
  )
}

export function createDatasetAxisFormatter(
  opts: { xType: string; dateFormat?: string },
  data?: SeriesData[],
) {
  if (!data) {
    return {
      formatAxisX: (value: number | null) => "",
      formatAxisY: (value: number | null) => "",
    }
  }

  const processDataPoints = (values: number[]) => {
    let maxDecimalPlaces = 0
    let allIntegers = true
    let min = Number.POSITIVE_INFINITY
    let max = Number.NEGATIVE_INFINITY

    values.forEach(value => {
      const decimalPlaces = (value.toString().split(".")[1] || "").length
      maxDecimalPlaces = Math.max(maxDecimalPlaces, decimalPlaces)
      if (decimalPlaces > 0) {
        allIntegers = false
      }
      min = Math.min(min, value)
      max = Math.max(max, value)
    })

    // Cap the maximum decimal places to 3 for readability
    maxDecimalPlaces = Math.min(maxDecimalPlaces, 3)

    return { maxDecimalPlaces, allIntegers, min, max }
  }

  const xValues: number[] = []
  const yValues: number[] = []

  data.forEach(series => {
    series.data_points.forEach(point => {
      xValues.push(point.x)
      yValues.push(point.y)
    })
  })

  if (opts.xType === "auto") {
    // take the first value, check if it is datetime in ns
    if (xValues.length === 0) {
      opts.xType = "number"
    } else {
      const firstVal = xValues[0].toString()
      if (firstVal.length === 19) {
        opts.xType = "datetime"
      } else {
        opts.xType = "number"
      }
    }
  }

  const xStats = processDataPoints(xValues)
  const yStats = processDataPoints(yValues)

  const createFormatter = (stats: {
    maxDecimalPlaces: number
    allIntegers: boolean
  }) => {
    return (value: number | null): string => {
      if (value === null) return ""
      // For integers or numbers very close to integers
      if (Math.abs(Math.round(value) - value) < 1e-10) {
        return stats.allIntegers
          ? Math.round(value).toString()
          : value.toLocaleString("en-US", {
              maximumFractionDigits: stats.maxDecimalPlaces,
              minimumFractionDigits: Math.min(2, stats.maxDecimalPlaces),
            })
      }

      // For other numbers, use the determined number of decimal places
      return value.toLocaleString("en-US", {
        maximumFractionDigits: stats.maxDecimalPlaces,
        minimumFractionDigits: Math.min(2, stats.maxDecimalPlaces),
      })
    }
  }

  const formatAxisX =
    opts.xType === "datetime"
      ? (value: number | null) =>
          value !== null
            ? formatDate(value, xStats.min, xStats.max, opts.dateFormat)
            : ""
      : createFormatter(xStats)

  return {
    formatAxisX,
    formatAxisY: createFormatter(yStats),
  }
}

export const formatDate = (
  nanoseconds: number,
  minNanoseconds: number,
  maxNanoseconds: number,
  formatString?: string,
): string => {
  const date = new Date(nanoseconds / 1e6)
  const hasMilliseconds = date.getMilliseconds() !== 0

  if (formatString === "auto" || !formatString) {
    const minDate = new Date(minNanoseconds / 1e6)
    const maxDate = new Date(maxNanoseconds / 1e6)
    const range = maxDate.getTime() - minDate.getTime()
    const rangeYears = range / (1000 * 60 * 60 * 24 * 365)
    const rangeMonths = range / (1000 * 60 * 60 * 24 * 30)
    const rangeDays = range / (1000 * 60 * 60 * 24)
    const rangeMinutes = range / (1000 * 60)
    const rangeSeconds = range / 1000

    if (rangeYears > 1) {
      return utcFormat("%Y")(date)
    }

    if (rangeYears === 1) {
      return utcFormat("%m/%Y")(date)
    }

    if (rangeMonths > 1) {
      return utcFormat("%m/%Y")(date)
    }

    if (rangeMonths === 1) {
      return utcFormat("%d/%m")(date)
    }

    if (rangeDays > 1) {
      return utcFormat("%d/%m")(date)
    }

    if (rangeDays === 1) {
      return utcFormat("%d/%m")(date)
    }

    if (rangeMinutes > 1) {
      return utcFormat("%d/%m %H:%M")(date)
    }

    if (rangeMinutes === 1) {
      return utcFormat(hasMilliseconds ? "%M:%S.%L" : "%M:%S")(date)
    }

    if (rangeSeconds > 1) {
      return utcFormat(hasMilliseconds ? "%M:%S.%L" : "%M:%S")(date)
    }

    return utcFormat(hasMilliseconds ? "%S.%L" : "%S")(date)
  }

  // For custom format strings, we'll need to handle the %L format specifier manually
  if (formatString.includes("%L") && !hasMilliseconds) {
    formatString = formatString.replace(/%L/g, "")
    // Clean up any double dots that might result from removing %L
    formatString = formatString.replace(/\.\./g, ".")
    // Remove trailing dot if it exists
    formatString = formatString.replace(/\.$/, "")
  }

  return utcFormat(formatString)(date)
}

export const getTimeValue = (nanoSec: number): string => {
  if (nanoSec < 60e9) {
    // For times less than 60 seconds, convert to milliseconds first then to seconds
    const milliseconds = nanoSec / 1e6
    const seconds = milliseconds / 1000
    return `${seconds.toFixed(4)}s`
  }

  const time = nanoSec / 1e9

  if (time < 3600) {
    const minutes = Math.floor(time / 60)
    const seconds = Math.round(time % 60)
    return `${minutes}m${seconds > 0 ? ` ${seconds}s` : ""}`
  } else if (time < 86400) {
    const hours = Math.floor(time / 3600)
    const minutes = Math.round((time % 3600) / 60)
    return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`
  } else if (time < 2592000) {
    const days = Math.floor(time / 86400)
    const hours = Math.round((time % 86400) / 3600)
    return `${days}d${hours > 0 ? ` ${hours}h` : ""}`
  } else if (time < 31536000) {
    const months = Math.floor(time / 2592000)
    const days = Math.round((time % 2592000) / 86400)
    return `${months}mo${days > 0 ? ` ${days}d` : ""}`
  } else {
    const years = Math.floor(time / 31536000)
    const months = Math.round((time % 31536000) / 2592000)
    return `${years}y${months > 0 ? ` ${months}mo` : ""}`
  }
}

import type { Chart } from "@/model"
import { addPlot, useAppDispatch } from "@/store"
import { Plus, Sparkles, Wand2 } from "lucide-react"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import AIProgress from "./AIProgress"

export const AIDialog = ({ chart }: { chart: Chart }) => {
  const [prompt, setPrompt] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const dispatch = useAppDispatch()

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!prompt.trim()) return

    setShowProgress(true)
  }

  const addNewPlot = (e: React.MouseEvent) => {
    e.preventDefault()
    const newPlot = {
      id: uuidv4(),
      name: "Plot 1",
      series_configs: [],
      label_x: "",
      label_y: "",
      zoom: {},
    }
    dispatch(addPlot(newPlot))
  }

  if (showProgress) {
    return <AIProgress chartId={chart.id} question={prompt} />
  }

  return (
    <div className="max-w-3xl mx-auto space-y-8">
      <div className="w-full rounded-xl border bg-white p-8 shadow-[0_4px_20px_-4px_rgba(0,0,0,0.1)]">
        <div className="space-y-6">
          {/* Header */}
          <div className="space-y-2 border-b pb-6">
            <div className="flex items-center gap-2 text-blue-600">
              <Sparkles className="w-5 h-5" />
              <h3 className="font-semibold">AI Assistant</h3>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                Analyze Your Data Visualization
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Get powerful insights and analysis from your data using AI
              </p>
            </div>
          </div>

          {/* Form Section */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <textarea
                placeholder="What would you like to know about your data?"
                value={prompt}
                onChange={e => setPrompt(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault()
                    handleSubmit(e)
                  }
                }}
                className="w-full min-h-[140px] p-4 rounded-lg border border-gray-200 bg-gray-50/50 resize-none text-sm placeholder:text-gray-400 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-all"
              />
              <button
                type="button"
                className="absolute right-3 top-3 p-1.5 rounded-md text-gray-400 hover:text-blue-600 hover:bg-blue-50 transition-colors"
              >
                <Sparkles className="w-4 h-4" />
              </button>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                disabled={!prompt.trim()}
                className="inline-flex items-center gap-2 py-2.5 px-5 bg-blue-600 hover:bg-blue-700 text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed transition-colors text-sm font-medium shadow-sm"
              >
                <Wand2 className="w-4 h-4" />
                Analyze Data
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="flex flex-col items-center gap-3">
        <div className="flex items-center gap-3">
          <div className="h-px w-16 bg-gray-200" />
          <span className="text-sm text-gray-400 font-medium">or</span>
          <div className="h-px w-16 bg-gray-200" />
        </div>

        <button
          onClick={addNewPlot}
          className="inline-flex items-center gap-2 py-2.5 px-5 bg-white hover:bg-gray-50 border shadow-sm rounded-lg transition-colors text-sm font-medium text-gray-700"
        >
          <Plus className="w-4 h-4" />
          Add new Plot
        </button>
      </div>
    </div>
  )
}

import { CompactDatasource } from "@/components/charts/CompactDatasource"
import { ConfigPanel } from "@/components/charts/ConfigPanel"
import { PlotsPanel } from "@/components/charts/PlotsPanel"
import { getZoomParams } from "@/components/charts/util"
import { ButtonTooltip } from "@/components/tooltip/Tooltip"
import { isAIEnabled } from "@/helpers"
import {
  addPlot,
  loadChart,
  loadDatasources,
  loadPlots,
  resetChart,
  selectChart,
  selectConfigComponentEnabled,
  selectDatasourceComponentEnabled,
  selectPlots,
  setBreadcrumbs,
  setConfigComponentEnabled,
  setDatasourceComponentEnabled,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { IconButton } from "@/ui"
import { ChartSpline, Database, PlusIcon } from "lucide-react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { AIDialog } from "./AIDialog"

export const EditChart = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const plotConfigCompEnabled = useAppSelector(selectConfigComponentEnabled)
  const datasourceCompEnabled = useAppSelector(selectDatasourceComponentEnabled)

  const chart = useAppSelector(selectChart)
  const plots = useAppSelector(selectPlots)
  useEffect(() => {
    if (params.id) {
      dispatch(loadChart(params.id))

      dispatch(
        loadPlots({
          chartId: params.id,
          zoom: getZoomParams(),
        }),
      )
      dispatch(loadDatasources(params.id))
    }

    return () => {
      dispatch(resetChart())
    }
  }, [dispatch, params.id])

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Charts", path: "/charts" },
        { label: chart?.name || "" },
      ]),
    )
  }, [chart, dispatch])

  const onAddPlot = (e: React.MouseEvent) => {
    e.preventDefault()
    const newPlot = {
      id: uuidv4(),
      name: "Plot " + ((plots?.length || 0) + 1),
      series_configs: [],
      label_x: "",
      label_y: "",
      zoom: {},
    }
    dispatch(addPlot(newPlot))
  }

  const aiEnabled = isAIEnabled()

  if (!chart || !plots) {
    return <div></div>
  }

  return (
    <div className="flex h-full overflow-hidden">
      <div className="flex-grow overflow-auto p-6">
        {aiEnabled && !plots.length && <AIDialog chart={chart} />}
        <PlotsPanel />
        {(!aiEnabled || !!plots.length) && (
          <div className="flex justify-center mt-4 outline-none">
            <IconButton
              icon={<PlusIcon className="w-4 h-4" />}
              onClick={onAddPlot}
              text="Add plot"
              variant="primary"
              size="small"
            />
          </div>
        )}
      </div>
      {plotConfigCompEnabled && (
        <div className="h-full flex overflow-y-auto shadow-[-2px_0_3px_rgba(0,0,0,0.1)]">
          <ConfigPanel chart={chart} />
        </div>
      )}
      {datasourceCompEnabled && (
        <div className="h-full flex overflow-y-auto w-[300px] shadow-[-2px_0_3px_rgba(0,0,0,0.1)]">
          <CompactDatasource chart={chart} />
        </div>
      )}
      <div className="border-l">
        <div className="w-12 z-10 space-y-2 flex flex-col items-center justify-between py-2 bg-white mt-3">
          <ButtonTooltip tooltip="Plot Config">
            <button
              className={`p-2 rounded-full hover:bg-gray-100 transition-colors  ${
                plotConfigCompEnabled
                  ? "text-blue-500 bg-gray-100"
                  : "text-gray-500"
              }`}
              onClick={() =>
                dispatch(setConfigComponentEnabled(!plotConfigCompEnabled))
              }
            >
              <ChartSpline size={20} />
            </button>
          </ButtonTooltip>
          <ButtonTooltip tooltip="Datasources">
            <button
              className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${datasourceCompEnabled ? "text-blue-500 bg-gray-100" : "text-gray-500"}`}
              onClick={() =>
                dispatch(setDatasourceComponentEnabled(!datasourceCompEnabled))
              }
            >
              <Database size={20} />
            </button>
          </ButtonTooltip>
        </div>
      </div>
    </div>
  )
}

import type { Middleware } from "@reduxjs/toolkit"
import { addToast } from "./toastSlice"

export const errorMiddleware: Middleware = store => next => action => {
  if (
    typeof action === "object" &&
    action !== null &&
    "type" in action &&
    typeof action.type === "string" &&
    action.type.endsWith("/rejected") &&
    action.type.startsWith("charts") &&
    "error" in action &&
    typeof action.error === "object" &&
    action.error !== null
  ) {
    console.error(action.error)
    store.dispatch(
      addToast({
        id: Date.now().toString(),
        message:
          (action.error as { message?: string }).message || "An error occurred",
        type: "error",
        isHidden: false,
      }),
    )
  }
  return next(action)
}

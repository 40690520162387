import { Text } from "@visx/text"

interface LabeledTextProps {
  x: number
  y: number
  text: string
  color?: string
  anchor?: "start" | "middle" | "end"
  variant?: "basic" | "highlighted"
  orientation: "horizontal" | "vertical"
}

const estimateWidth = (text: string): number => {
  // Assuming an average character width of 6 pixels at font size 9
  const estimatedWidth = text.length * 6
  // Add padding
  return Math.max(estimatedWidth + 12, 30) // Ensure a minimum width
}

export const LabeledText: React.FC<LabeledTextProps> = ({
  x,
  y,
  text,
  color,
  anchor = "middle",
  orientation,
  variant = "highlighted",
}) => {
  const height = 16 // Reduced height
  const width = estimateWidth(text)

  if (orientation === "horizontal") {
    return (
      <>
        {variant === "highlighted" && (
          <>
            <rect
              x={x} // Centered horizontally
              y={y - 8} // Moved down by removing the - height / 2
              width={width}
              height={height}
              fill={color ? color : "rgba(224, 224, 224, 1)"} // Semi-transparent light gray
              rx={2}
              ry={2}
              pointerEvents={"none"}
            />

            <Text
              x={x + width / 2}
              y={y} // Centered vertically within the new rectangle position
              textAnchor="middle"
              verticalAnchor="middle"
              style={{
                fontSize: 11,
                userSelect: "none",
                WebkitUserSelect: "none", // For Safari
                MozUserSelect: "none", // For Firefox
                msUserSelect: "none", // For IE/Edge
              }}
              pointerEvents={"none"}
            >
              {text}
            </Text>
          </>
        )}

        {variant === "basic" && (
          <Text
            x={x + width / 2}
            y={y - height / 2} // Centered vertically within the new rectangle position
            textAnchor="middle"
            verticalAnchor="middle"
            style={{
              fontSize: 11,
              userSelect: "none",
              WebkitUserSelect: "none", // For Safari
              MozUserSelect: "none", // For Firefox
              msUserSelect: "none", // For IE/Edge
            }}
            pointerEvents={"none"}
          >
            {text}
          </Text>
        )}
      </>
    )
  }

  return (
    <>
      {variant === "highlighted" && (
        <>
          <rect
            x={x - width / 2} // Centered horizontally
            y={y - 8} // Moved down by removing the - height / 2
            width={width}
            height={height}
            fill={color ? color : "rgba(224, 224, 224, 1)"} // Semi-transparent light gray
            rx={2}
            ry={2}
            pointerEvents={"none"}
            style={{
              userSelect: "none",
              WebkitUserSelect: "none", // For Safari
              MozUserSelect: "none", // For Firefox
              msUserSelect: "none", // For IE/Edge
            }}
          />

          <Text
            x={x}
            y={y} // Centered vertically within the new rectangle position
            textAnchor="middle"
            verticalAnchor="middle"
            style={{
              fontSize: 11,
              userSelect: "none",
              WebkitUserSelect: "none", // For Safari
              MozUserSelect: "none", // For Firefox
              msUserSelect: "none", // For IE/Edge
            }}
            pointerEvents={"none"}
          >
            {text}
          </Text>
        </>
      )}
      {variant === "basic" && (
        <Text
          x={x}
          y={y - height / 2} // Centered vertically within the new rectangle position
          textAnchor="middle"
          verticalAnchor="middle"
          style={{
            fontSize: 11,
            userSelect: "none",
            WebkitUserSelect: "none", // For Safari
            MozUserSelect: "none", // For Firefox
            msUserSelect: "none", // For IE/Edge
          }}
          pointerEvents={"none"}
        >
          {text}
        </Text>
      )}
    </>
  )
}

export default LabeledText

import { isUsedForX, isUsedForY } from "@/helpers"
import type { Column, Datasource, Series } from "@/model"
import { selectCurrentDragDropItem, useAppSelector } from "@/store"
import { useState } from "react"

interface UseAxisInputProps {
  axis: "x" | "y"
  remainingSeries: Series[]
  series: Series
  datasources: Record<string, Datasource>
}

interface UseAxisInputReturn {
  isDroppable: boolean
  isDragOver: boolean
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void
  handleDragLeave: (e: React.DragEvent) => void
  handleDrop: (
    e: React.DragEvent,
    onChange: (data: { datasourceId: string; column: Column }) => void,
  ) => void
}

export const useAxisInput = ({
  axis,
  remainingSeries,
  datasources,
  series,
}: UseAxisInputProps): UseAxisInputReturn => {
  const [isDroppable, setIsDroppable] = useState(true)
  const [isDragOver, setIsDragOver] = useState(false)
  const currentDragDropItem = useAppSelector(selectCurrentDragDropItem) as
    | { datasourceId: string; column: Column }
    | undefined

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    if (!currentDragDropItem) {
      return
    }
    setIsDragOver(true)
    const usedForX = isUsedForX(currentDragDropItem.column.type)
    const usedForY = isUsedForY(currentDragDropItem.column.type)

    // check if the current column can be used for the selected axis
    const validAxisTypeValue =
      (axis === "x" && usedForX) || (axis === "y" && usedForY)

    // make sure the new field is added when there's no datasource selected (for the first series)
    // or the datasource matches the current series datasource
    const isValidDatasource =
      currentDragDropItem.datasourceId === series.datasource_id ||
      !series.datasource_id

    // make sure the column on the new X axis is same type as the column X on the other series
    // column type can be found from the Datasource
    const newColumnType = currentDragDropItem.column.type

    const otherSeriesXTypes = remainingSeries.map(
      s =>
        datasources[s.datasource_id]?.columns.find(col => col.name === s.x)
          ?.type,
    )
    const validXAxisType =
      axis === "y" ||
      (axis === "x" &&
        otherSeriesXTypes.every(
          colType => colType === newColumnType || !colType || !newColumnType,
        ))

    setIsDroppable(isValidDatasource && validAxisTypeValue && validXAxisType)
  }

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault()
    setIsDragOver(false)
    setIsDroppable(true)
  }

  const handleDrop = (
    e: React.DragEvent,
    onChange: (data: { datasourceId: string; column: Column }) => void,
  ) => {
    e.preventDefault()
    setIsDragOver(false)
    setIsDroppable(true)

    if (!!currentDragDropItem && isDroppable) {
      onChange(currentDragDropItem)
      return
    }
  }

  return {
    isDroppable,
    isDragOver,
    handleDragOver,
    handleDragLeave,
    handleDrop,
  }
}

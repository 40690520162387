import Background1 from "@/images/background1.jpg"
import { Alert } from "@/ui"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

interface FormValues {
  email: string
}

interface LogoProps extends React.SVGProps<SVGSVGElement> {}

const Logo: React.FC<LogoProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    width="40"
    height="40"
    {...props}
  >
    <defs>
      <linearGradient id="boldGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#2563eb"></stop>
        <stop offset="100%" stopColor="#1e40af"></stop>
      </linearGradient>
    </defs>
    <circle cx="100" cy="100" r="90" fill="url(#boldGradient)"></circle>
    <path
      d="M100 30 L130 90 L110 90 L140 170 L60 110 L80 110 Z"
      fill="#FFFFFF"
    ></path>
    <circle
      cx="100"
      cy="100"
      r="30"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="6"
    ></circle>
    <circle
      cx="100"
      cy="100"
      r="60"
      fill="none"
      stroke="#FFFFFF"
      strokeWidth="3"
      strokeDasharray="10 5"
    ></circle>
  </svg>
)

export const ResetPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()
  const [error, setError] = React.useState<string | null>(null)
  const [success, setSuccess] = React.useState<boolean>(false)

  const submitForm: SubmitHandler<FormValues> = async data => {
    try {
      // Implement your reset password logic here
      console.log("Reset password for:", data.email)
      setSuccess(true)
      setError(null)
    } catch (err) {
      setError(
        "An error occurred while processing your request. Please try again.",
      )
      setSuccess(false)
    }
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100">
      <div className="w-full md:w-1/2 flex items-center justify-center p-4 md:p-0">
        <div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
          <div className="flex items-center justify-center mb-8">
            <Logo className="mr-2" />
            <h2 className="text-2xl font-bold text-gray-800">Reset Password</h2>
          </div>
          {error && <Alert type="error">{error}</Alert>}
          {success && (
            <Alert type="success">
              Password reset instructions have been sent to your email.
            </Alert>
          )}
          {!success && (
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  placeholder="Enter your email"
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Reset Password
              </button>
            </form>
          )}
          <div className="mt-6 flex justify-between text-sm">
            <Link
              to="/auth/login"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Back to Login
            </Link>
            <Link
              to="/auth/signup"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Create an account
            </Link>
          </div>
        </div>
      </div>
      <div className="hidden md:flex md:w-1/2 bg-blue-600 items-center justify-center overflow-hidden">
        <img src={Background1} width="100%" alt="Reset Password" />
      </div>
    </div>
  )
}

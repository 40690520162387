import type { Column, Datasource } from "@/model"
import type { GridReadyEvent } from "ag-grid-community"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import { AgGridReact } from "ag-grid-react"
import { useCallback, useMemo } from "react"
import "react-loading-skeleton/dist/skeleton.css"
import { CustomHeader } from "./CustomHeader"

export const EditDatasource = ({
  datasource,
  columns,
  records,
  total,
}: {
  datasource: Datasource
  columns: Column[]
  records: Record<string, any>[]
  total: number
}) => {
  const columnDefs = useMemo(
    () =>
      (columns || []).map(col => ({
        field: col.name,
        headerName: col.name,
        suppressSizeToFit: true,
      })),
    [columns],
  )

  const defaultColDef = useMemo(
    () => ({
      sortable: false,
      filter: false,
      resizable: false,
      suppressHeaderMenuButton: true,
      headerComponent: CustomHeader,
    }),
    [],
  )

  const gridOptions = useMemo(
    () => ({
      suppressMovableColumns: true,
      enableBrowserTooltips: true,
    }),
    [],
  )

  const onGridReady = useCallback((event: GridReadyEvent) => {
    event.api.sizeColumnsToFit()
  }, [])

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center">
        <div>&nbsp;</div>
        <div className="text-sm">
          Showing {(records || []).length}/{total} records
        </div>
      </div>
      <div className="flex-grow">
        <div className="ag-theme-quartz w-full h-full">
          <AgGridReact
            rowData={records}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
            domLayout="normal"
            onGridReady={onGridReady}
            headerHeight={40}
          />
        </div>
      </div>
    </div>
  )
}

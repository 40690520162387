import * as Dialog from "@radix-ui/react-dialog"
import { Cross2Icon } from "@radix-ui/react-icons"

interface DeleteDialogProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  title?: string
  description?: string
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  isOpen,
  onClose,
  onDelete,
  title = "Confirm Deletion",
  description = "Are you sure you want to delete this item? This action cannot be undone.",
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={open => !open && onClose()}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        <Dialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-4 sm:p-6 w-11/12 sm:w-96 max-w-md shadow-xl">
          <Dialog.Title className="text-lg font-bold mb-3 sm:mb-4 text-gray-900">
            {title}
          </Dialog.Title>
          <Dialog.Description className="mb-4 text-gray-600">
            {description}
          </Dialog.Description>
          <div className="flex justify-end space-x-2">
            <Dialog.Close asChild>
              <button className="px-3 sm:px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors">
                Cancel
              </button>
            </Dialog.Close>
            <button
              className="px-3 sm:px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              onClick={onDelete}
            >
              Delete
            </button>
          </div>
          <Dialog.Close asChild>
            <button
              className="absolute top-2 right-2 inline-flex items-center justify-center rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

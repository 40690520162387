import { Database, LineChart } from "lucide-react"
import { Link } from "react-router-dom"

export const NavBar = () => {
  return (
    <div className="w-16 bg-gradient-to-b from-indigo-600 to-purple-700 fixed inset-y-0 left-0 z-50 flex flex-col items-center py-4 space-y-8">
      <Link to="/charts" className="text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          width="40"
          height="40"
        >
          <defs>
            <linearGradient
              id="boldGradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop
                offset="0%"
                style={{ stopColor: "#4f46e5", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#7e22ce", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <circle cx="100" cy="100" r="90" fill="url(#boldGradient)" />
          <path
            d="M100 30 L130 90 L110 90 L140 170 L60 110 L80 110 Z"
            fill="#FFFFFF"
          />
          <circle
            cx="100"
            cy="100"
            r="30"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="6"
          />
          <circle
            cx="100"
            cy="100"
            r="60"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="3"
            strokeDasharray="10 5"
          />
        </svg>
      </Link>
      <nav className="flex flex-col space-y-8">
        <Link to="/charts" className="text-white hover:text-indigo-200">
          <LineChart size={24} />
        </Link>
        {/* <Link to="/datasources" className="text-white hover:text-indigo-200">
          <Database size={24} />
        </Link> */}
        {/* <Link to="/templates" className="text-white hover:text-indigo-200">
          <LayoutTemplate size={24} />
        </Link> */}
      </nav>
    </div>
  )
}

import { type Series, FUNC_RISING_TIME, SeriesFunctionStep } from "@/model"
import {
  removeAllRisingTimes,
  RootState,
  selectChart,
  updateSeriesFunction,
  useAppDispatch,
  useAppSelector,
} from "@/store"

export const useCommands = () => {
  const dispatch = useAppDispatch()
  const chart = useAppSelector(selectChart)
  const chartId = chart!.id
  const chartState = useAppSelector((state: RootState) => state.chart)

  const getPlotSerieses = (plotId: string): Series[] => {
    const seriesIds = chartState.serieses.allIds[plotId]
    if (!seriesIds) return []

    return seriesIds
      .map(id => chartState.serieses.byId[id])
      .filter((series): series is Series => series !== undefined)
  }

  const getSeries = (seriesId: string): Series | undefined => {
    return chartState.serieses.byId[seriesId]
  }

  const getSeriesFunction = (seriesId: string, functionId: string) => {
    const series = getSeries(seriesId)
    if (!series) {
      return
    }

    return series.functions?.find(f => f.id === functionId)
  }

  const updateSeriesFunctionStepStyleCommand = ({
    plotId,
    seriesId,
    seriesFunctionId,
    stepId,
    style,
  }: {
    plotId: string
    seriesId: string
    seriesFunctionId: string
    stepId: string
    style: Record<string, any>
  }) => {
    const series = getSeries(seriesId)
    if (!series) {
      return
    }

    const seriesFunction = getSeriesFunction(seriesId, seriesFunctionId)
    if (!seriesFunction) {
      return
    }

    let step = seriesFunction.data?.steps?.find(step => step.id === stepId)
    if (!step) {
      return
    }

    step = { ...step, style: { ...step.style, ...style } }

    return dispatch(
      updateSeriesFunction({
        chartId,
        plotId,
        seriesId,
        seriesFunction: {
          id: seriesFunctionId,
          data: {
            ...seriesFunction.data,
            steps: seriesFunction.data?.steps?.map(s =>
              s.id === stepId ? step : s,
            ),
          },
        },
        refreshData: false,
      }),
    )
  }

  const updateSeriesFunctionStepConfigCommand = ({
    plotId,
    seriesId,
    seriesFunctionId,
    stepId,
    config,
  }: {
    plotId: string
    seriesId: string
    seriesFunctionId: string
    stepId: string
    config: Record<string, any>
  }) => {
    const series = getSeries(seriesId)
    if (!series) {
      return
    }

    const seriesFunction = getSeriesFunction(seriesId, seriesFunctionId)
    if (!seriesFunction) {
      return
    }

    let step = seriesFunction.data?.steps?.find(step => step.id === stepId)
    if (!step) {
      return
    }

    step = { ...step, config: { ...step.config, ...config } }

    return dispatch(
      updateSeriesFunction({
        chartId,
        plotId,
        seriesId,
        seriesFunction: {
          id: seriesFunctionId,
          data: {
            ...seriesFunction.data,
            steps: seriesFunction.data?.steps?.map(s =>
              s.id === stepId ? step : s,
            ),
          },
        },
        refreshData: true,
      }),
    )
  }

  const deleteSeriesFunctionStepCommand = ({
    plotId,
    seriesId,
    seriesFunctionId,
    stepId,
  }: {
    plotId: string
    seriesId: string
    seriesFunctionId: string
    stepId: string
  }) => {
    const series = getSeries(seriesId)
    if (!series) {
      return
    }

    const seriesFunction = getSeriesFunction(seriesId, seriesFunctionId)
    if (!seriesFunction) {
      return
    }

    let data = seriesFunction.data || {}
    data = {
      ...data,
      steps: (data.steps || []).filter(step => step.id !== stepId),
    }

    return dispatch(
      updateSeriesFunction({
        chartId,
        plotId,
        seriesId,
        seriesFunction: {
          id: seriesFunctionId,
          data,
        },
        refreshData: true,
      }),
    )
  }

  const addSeriesFunctionStepCommand = ({
    plotId,
    seriesId,
    seriesFunctionId,
    step,
  }: {
    plotId: string
    seriesId: string
    seriesFunctionId: string
    step: SeriesFunctionStep
  }) => {
    const series = getSeries(seriesId)
    if (!series) {
      return
    }

    const seriesFunction = getSeriesFunction(seriesId, seriesFunctionId)
    if (!seriesFunction) {
      return
    }

    let data = seriesFunction.data || {}
    data = { ...data, steps: (data.steps || []).concat(step) }

    return dispatch(
      updateSeriesFunction({
        chartId,
        plotId,
        seriesId,
        seriesFunction: {
          id: seriesFunctionId,
          data,
        },
        refreshData: true,
      }),
    )
  }

  const removeAllRisingTimesCommand = async ({
    plotId,
  }: {
    plotId: string
  }) => {
    const serieses = getPlotSerieses(plotId)
    if (serieses.length === 0) {
      return
    }

    const deletionTasks = serieses.flatMap(s => {
      return (s.functions || [])
        .filter(f => f.type === FUNC_RISING_TIME)
        .map(f => ({
          seriesId: s.id,
          functionId: f.id,
        }))
    })

    if (deletionTasks.length === 0) {
      return
    }

    return dispatch(removeAllRisingTimes({ chartId, plotId, deletionTasks }))
  }

  return {
    removeAllRisingTimesCommand,
    addSeriesFunctionStepCommand,
    deleteSeriesFunctionStepCommand,
    updateSeriesFunctionStepConfigCommand,
    updateSeriesFunctionStepStyleCommand,
  }
}

import { forwardRef } from "react"

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | "default"
    | "destructive"
    | "destructive-text"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
  size?: "default" | "xs" | "sm" | "lg" | "icon"
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = "default", size = "default", ...props }, ref) => {
    const baseStyles =
      "inline-flex items-center justify-center rounded-md text-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none"

    const variants = {
      default:
        "bg-primary text-white hover:from-indigo-700 hover:to-purple-800",
      destructive: "bg-red-600 text-white hover:bg-red-700",
      "destructive-text": "text-red-600 hover:text-red-700 hover:bg-red-50",
      outline:
        "border border-input hover:bg-accent hover:text-accent-foreground",
      secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "underline-offset-4 text-primary",
    }

    const sizes = {
      default: "h-9 px-3 text-sm rounded-md",
      sm: "h-9 px-3 text-sm rounded-md",
      xs: "h-8 px-2 text-xs rounded-md",
      lg: "h-11 px-8 rounded-md",
      icon: "h-10 w-10",
    }

    const classes = `${baseStyles} ${variants[variant]} ${sizes[size]} ${
      className || ""
    }`

    return <button className={classes} ref={ref} {...props} />
  },
)

Button.displayName = "Button"

export { Button }

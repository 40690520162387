import { TASK_ANNOTATION, TASK_EDIT, TASK_MEASURE, type Task } from "@/model"
import { useHotkeys as useHotKeysHook } from "react-hotkeys-hook"

export const useHotKeys = ({
  isActiveContainer,
  onTaskChanged,
  zoomOut,
  fitToY,
}: {
  isActiveContainer: boolean
  onTaskChanged: (task: Task) => void
  zoomOut: () => void
  fitToY: () => void
}) => {
  const enabled = { enabled: isActiveContainer }

  useHotKeysHook("v", () => onTaskChanged({ task: TASK_EDIT }), enabled)

  useHotKeysHook("0", zoomOut, enabled)

  useHotKeysHook(
    "a",
    () => onTaskChanged({ task: TASK_ANNOTATION, subtask: "text" }),
    enabled,
  )
  useHotKeysHook(
    "m",
    () => onTaskChanged({ task: TASK_MEASURE, subtask: "distance" }),
    enabled,
  )

  useHotKeysHook("y", fitToY, enabled)
}

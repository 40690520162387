import { downloadImage } from "@/api"
import type { Zoom } from "@/model"

export const newCompId = (name: string) => {
  return `comp-${name}`
}

export const colorCompId = "color-panel"
export const defaultColor = "#FF0000"

export const getZoomParams = () => {
  // build zoom from the URL query string x_min, x_max, y_min, y_max
  const urlParams = new URLSearchParams(window.location.search)
  const x_min = urlParams.get("x_min")
  const x_max = urlParams.get("x_max")
  const y_min = urlParams.get("y_min")
  const y_max = urlParams.get("y_max")
  if (x_min && x_max && y_min && y_max) {
    return {
      x_min: parseFloat(x_min),
      x_max: parseFloat(x_max),
      y_min: parseFloat(y_min),
      y_max: parseFloat(y_max),
    }
  }

  return undefined
}

export const copyPlotToClipboardAsImage = async ({
  chartId,
  plotId,
  zoom,
}: {
  chartId: string
  plotId: string
  zoom?: Zoom
}) => {
  const resp = await downloadImage({ chartId, plotId, zoom })
  if (!resp.ok) {
    throw new Error("Failed to copy plot to clipboard")
  }

  const blob = await resp.blob()

  const data = [new ClipboardItem({ [blob.type]: blob })]
  await navigator.clipboard.write(data)
}

export const downloadPlotAsImage = async ({
  chartId,
  plotId,
  zoom,
}: {
  chartId: string
  plotId: string
  zoom?: Zoom
}) => {
  const resp = await downloadImage({ chartId, plotId, zoom })
  if (!resp.ok) {
    throw new Error("Failed to download plot")
  }

  const blob = await resp.blob()

  const url = window.URL.createObjectURL(blob)

  const a = document.createElement("a")
  a.href = url
  a.download = `plot-${plotId}.png`
  document.body.appendChild(a)
  a.click()

  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

import { getFuncName, type SeriesFunctionStep } from "@/model"
import { IconButton } from "@/ui"
import { ChevronDown, ChevronRight, X } from "lucide-react"
export interface FunctionItemConfigContainerProps {
  step: SeriesFunctionStep
  onDelete: (stepId: string) => void
  children: React.ReactNode
  isOpen: boolean
  onToggle: (stepId: string) => void
}

export const FunctionItemConfigContainer: React.FC<
  FunctionItemConfigContainerProps
> = ({ step, onDelete, children, isOpen, onToggle }) => {
  return (
    <div className="border border-gray-200 rounded-md mb-2 p-2">
      <div className="flex items-center justify-between">
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={() => onToggle(step.id)}
        >
          {isOpen ? (
            <ChevronDown className="w-4 h-4 text-gray-600" />
          ) : (
            <ChevronRight className="w-4 h-4 text-gray-600" />
          )}
          <h4 className="text-xs font-semibold">{getFuncName(step.type)}</h4>
        </div>
        <IconButton
          icon={<X className="w-3 h-3" />}
          onClick={() => onDelete(step.id)}
          text="Delete"
          size="small"
          variant="danger"
        />
      </div>
      {isOpen && <div className="mt-2 pl-5">{children}</div>}
    </div>
  )
}

import type { Chart } from "@/model"
import { PlotsConfig } from "./config/PlotsConfig"

export const ConfigPanel = ({ chart }: { chart: Chart }) => {
  return (
    <div className="flex">
      <div className="h-full bg-white p-3">
        <div>
          <PlotsConfig />
        </div>
      </div>
    </div>
  )
}

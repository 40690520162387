import type { Profile } from "@/model"
import { authorisedFetch } from "./base"
import { removeToken, setToken } from "./storage"

interface LoginCredentials {
  email: string
  password: string
}

interface SignupCredentials {
  name: string
  email: string
  password: string
}

export const login = async (
  credentials: LoginCredentials,
): Promise<Profile> => {
  const data = await authorisedFetch("/auth/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(credentials),
  })
  if ("token" in data && "refresh_token" in data) {
    setToken(data.token, data.refresh_token)
  }
  return data
}

export const signup = async (
  credentials: SignupCredentials,
): Promise<Profile> => {
  const data = await authorisedFetch("/auth/signup", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(credentials),
  })
  if ("token" in data && "refresh_token" in data) {
    setToken(data.token, data.refresh_token)
  }
  return data
}

export const getProfile = async (): Promise<Profile> => {
  return await authorisedFetch("/auth/me")
}

export const logout = async (): Promise<void> => {
  await authorisedFetch("/auth/logout", { method: "POST" })
  removeToken()
}

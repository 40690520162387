import { useEffect, useState } from "react"

type SpinnerSize = "sm" | "md" | "lg"
type SpinnerColor = "blue" | "red" | "green" | "yellow" | "purple" | "gray"

interface SpinnerProps {
  /**
   * Size of the spinner
   * @default 'md'
   */
  size?: SpinnerSize
  /**
   * Color of the spinner
   * @default 'blue'
   */
  color?: SpinnerColor
  /**
   * Additional CSS classes
   */
  className?: string
  /**
   * Whether the spinner is loading
   * @default true
   */
  isLoading?: boolean
  /**
   * Delay in milliseconds before showing the spinner
   * @default 0
   */
  delay?: number
}

// Size and color mappings as constant objects
const SPINNER_SIZES: Record<SpinnerSize, string> = {
  sm: "w-4 h-4",
  md: "w-8 h-8",
  lg: "w-12 h-12",
} as const

const SPINNER_COLORS: Record<SpinnerColor, string> = {
  blue: "border-blue-500",
  red: "border-red-500",
  green: "border-green-500",
  yellow: "border-yellow-500",
  purple: "border-purple-500",
  gray: "border-gray-500",
} as const

export const Spinner: React.FC<SpinnerProps> = ({
  size = "md",
  color = "blue",
  className = "",
  isLoading = true,
  delay = 0,
}) => {
  const [shouldShow, setShouldShow] = useState(delay === 0 ? isLoading : false)

  useEffect(() => {
    if (!isLoading) {
      setShouldShow(false)
      return
    }

    if (delay === 0) {
      setShouldShow(true)
      return
    }

    const timeoutId = setTimeout(() => {
      if (isLoading) {
        setShouldShow(true)
      }
    }, delay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isLoading, delay])

  // Get the appropriate size and color classes
  const sizeClass = SPINNER_SIZES[size]
  const colorClass = SPINNER_COLORS[color]

  if (!shouldShow) {
    return null
  }

  return (
    <div className={`inline-block ${className}`}>
      <div
        className={`${sizeClass} border-2 border-t-transparent rounded-full animate-spin ${colorClass} opacity-80`}
        role="status"
        aria-label="loading"
      />
    </div>
  )
}

// Export a pre-configured delayed spinner for convenience
export const DelayedSpinner: React.FC<Omit<SpinnerProps, "delay">> = props => (
  <Spinner {...props} delay={1000} />
)

export default Spinner

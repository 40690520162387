import { FileUpload } from "@/components/datasource/FileUpload"
import type { Datasource } from "@/model"
import { setBreadcrumbs, useAppDispatch } from "@/store"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const NewDatasource = () => {
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleFilesUploaded = (datasource: Datasource) => {
    navigate(`/datasources/${datasource.id}`, { replace: true })
  }

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Datasources", path: "/datasources" },
        { label: "New" },
      ]),
    )
  }, [dispatch])

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">
        Files Upload
      </h1>
      <FileUpload
        onFilesUploaded={handleFilesUploaded}
        onFilesUploadedError={setError}
        // accept=".csv,.zip"
        isPendingDatasource={false}
        multiple={true} // Uncomment if multiple file upload is needed
      />
      {error && <p className="mt-4 text-red-500 text-center">Error: {error}</p>}
    </div>
  )
}

import { createDatasource } from "@/api"
import type { Datasource } from "@/model"
import * as Label from "@radix-ui/react-label"
import { useRef, useState } from "react"

interface FileUploadProps {
  onFilesUploaded: (datasource: Datasource) => void
  onIsUploading?: () => void
  onFilesUploadedError: (err: any) => void
  isPendingDatasource?: boolean
  accept?: string
  multiple?: boolean
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onFilesUploaded,
  onIsUploading,
  onFilesUploadedError,
  isPendingDatasource,
  accept = "csv,excel,pscad",
  multiple = false,
}) => {
  const [dragActive, setDragActive] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  const uploadFiles = async (fileList: FileList) => {
    if (fileList && fileList.length > 0) {
      const files = Array.from(fileList)
      const formData = new FormData()
      files.forEach(file => formData.append("files", file))

      try {
        setIsUploading(true)
        onIsUploading?.()
        const response = await createDatasource(formData, isPendingDatasource)
        onFilesUploaded(response)
      } catch (err) {
        onFilesUploadedError(err)
      } finally {
        setIsUploading(false)
      }
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    uploadFiles(e.dataTransfer.files)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      uploadFiles(e.target.files)
    }
  }

  return (
    <form className="space-y-4" onDragEnter={handleDrag}>
      <div
        className={`flex items-center justify-center w-full ${
          dragActive ? "border-blue-500" : "border-gray-300"
        } relative`}
      >
        <Label.Root
          htmlFor="file-upload"
          className={`flex flex-col items-center justify-center w-full h-32 border-2 border-dashed rounded-lg cursor-pointer ${
            dragActive ? "bg-blue-50" : "bg-gray-50 hover:bg-gray-100"
          }`}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            {isUploading ? (
              <svg
                className="animate-spin h-8 w-8 text-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <svg
                className="w-8 h-8 mb-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
            )}
            <p className="mb-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            {accept && (
              <p className="text-xs text-gray-500">
                {accept.split(",").join(" or ")} files are allowed
              </p>
            )}
          </div>
          <input
            id="file-upload"
            type="file"
            name="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleChange}
            disabled={isUploading}
            accept={accept}
            multiple={multiple}
          />
        </Label.Root>
        {dragActive && (
          <div
            className="absolute inset-0"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </div>
    </form>
  )
}

export const chartLineColors = [
  "#1E90FF",
  "#FF4500",
  "#32CD32",
  "#FF1493",
  "#FFD700",
  "#8A2BE2",
  "#00CED1",
  "#FF69B4",
  "#00FF00",
  "#FF6347",
  "#4169E1",
  "#9400D3",
  "#00BFFF",
  "#FF8C00",
  "#7B68EE",
  "#20B2AA",
  "#FF4D4D",
  "#A020F0",
  "#00FF7F",
  "#B8860B",
]

export const defaultColor = "#1E90FF"

import { addReferenceLine, useAppDispatch } from "@/store"
import { Input, Select } from "@/ui"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { DraggableControlContainer } from "../DraggableControlContainer"

export const AddReferenceLine = ({
  plotId,
  enabled,
  verticalType,
  containerRef,
}: {
  plotId: string
  verticalType?: string
  enabled: boolean
  containerRef: React.RefObject<HTMLDivElement>
}) => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<string>("")
  const [orientation, setOrientation] = useState<"horizontal" | "vertical">(
    "horizontal",
  )

  const onAddReferenceLine = () => {
    dispatch(
      addReferenceLine({
        plotId,
        referenceLine: {
          id: uuidv4(),
          value: parseFloat(value),
          orientation,
        },
      }),
    )
  }

  const typeMap = {
    vertical: verticalType === "number" ? "number" : "string",
    horizontal: "number",
  }

  const onValueChanged = (v: string) => {
    setValue(v)
  }

  const items = [{ name: "Horizontal", value: "horizontal" }]
  if (verticalType === "number") {
    items.push({ name: "Vertical", value: "vertical" })
  }

  if (!enabled) {
    return null
  }

  return (
    <DraggableControlContainer
      containerRef={containerRef}
      initialTop={20}
      initialRight={300}
      title="Enter reference line value"
    >
      <form
        className="space-y-3"
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          onAddReferenceLine()
        }}
      >
        <Select
          name="type"
          value={orientation}
          items={items}
          onChange={v => setOrientation(v as "horizontal" | "vertical")}
        />

        <Input
          debounceTime={0}
          type={typeMap[orientation]}
          label="Value"
          className="text-sm"
          value={value.toString()}
          onChange={onValueChanged}
          selectAllOnFocus={true}
          name="value"
          placeholder={
            typeMap[orientation] === "number" ? "" : "dd/mm/yyyy hh:mm:ss.sss"
          }
        />
      </form>
    </DraggableControlContainer>
  )
}

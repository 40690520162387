import {
  FUNC_FIT_CURVE,
  FUNC_GAUSSIAN_FILTER,
  FUNC_LOW_PASS_FILTER,
  FUNC_MEDIAN_FILTER,
  FUNC_MOVING_AVERAGE_FILTER,
  FUNC_OSCILLATIONS,
  FUNC_RISING_TIME,
  FUNC_SAVGOL_FILTER,
  FUNC_SLOPE,
  type FunctionData,
  type SeriesFunction,
  type SeriesFunctionStep,
  type SlopeResponseData,
  type StepsFunctionData,
} from "@/model"
import { defaultColor } from "@/preference"
import { isNoneColor } from "@/ui"
import type { ScaleLinear } from "@visx/vendor/d3-scale"
import React from "react"
import { LinePath } from "./../LinePath"
import { OscillationsViewer } from "./OscillationsViewer"
import { RisingTimeViewer } from "./RisingTimeViewer"
import { SlopeViewer } from "./SlopeViewer"

export const StepsFunctionViewer = ({
  func,
  funcData,
  innerWidth,
  innerHeight,
  xScale,
  yScale,
  pointerEvents,
  formatX,
  formatY,
}: {
  func: SeriesFunction
  funcData: FunctionData
  innerWidth: number
  innerHeight: number
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  pointerEvents: "none" | "auto"
  formatX: (v: number) => string
  formatY: (v: number) => string
}) => {
  const stepsFunctionData = funcData.data as StepsFunctionData
  const stepsFunction = stepsFunctionData.steps || []
  if (stepsFunction.length === 0) {
    return null
  }

  const stepsFunctionConfigs = (func.data.steps || []).reduce(
    (acc, step) => {
      acc[step.id] = step
      return acc
    },
    {} as Record<string, SeriesFunctionStep>,
  )

  return stepsFunction.map((step, i) => {
    if (isNoneColor(stepsFunctionConfigs[step.id]?.style?.color)) {
      return null
    }

    return (
      <React.Fragment key={i}>
        {(step.type === FUNC_FIT_CURVE ||
          step.type === FUNC_GAUSSIAN_FILTER ||
          step.type === FUNC_MOVING_AVERAGE_FILTER ||
          step.type === FUNC_LOW_PASS_FILTER ||
          step.type === FUNC_MEDIAN_FILTER ||
          step.type === FUNC_SAVGOL_FILTER) &&
          (step.data_points || []).length && (
            <LinePath
              key={step.id}
              data={step.data_points}
              xScale={xScale}
              yScale={yScale}
              strokeWidth={1.5}
              stroke={
                stepsFunctionConfigs[step.id]?.style?.color || defaultColor
              }
              strokeDasharray="5,5"
            />
          )}
        {step.type === FUNC_RISING_TIME && (
          <RisingTimeViewer
            key={funcData.id}
            func={func}
            style={stepsFunctionConfigs[step.id]?.style}
            step={step}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            xScale={xScale}
            yScale={yScale}
            pointerEvents={pointerEvents}
            formatX={formatX}
            formatY={formatY}
          />
        )}
        {step.type === FUNC_SLOPE && (
          <SlopeViewer
            key={funcData.id}
            func={func}
            style={stepsFunctionConfigs[step.id]?.style}
            data={step.data as SlopeResponseData}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            xScale={xScale}
            yScale={yScale}
            pointerEvents={pointerEvents}
            formatX={formatX}
            formatY={formatY}
          />
        )}
        {step.type == FUNC_OSCILLATIONS && (
          <OscillationsViewer
            key={funcData.id}
            func={func}
            style={stepsFunctionConfigs[step.id]?.style}
            step={step}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            xScale={xScale}
            yScale={yScale}
            pointerEvents={pointerEvents}
            formatX={formatX}
            formatY={formatY}
          />
        )}
      </React.Fragment>
    )
  })
}

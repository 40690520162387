const FEATURE_AI = "feature-ai"

export const getFlagBool = (flag: string): boolean => {
  const flagValue = localStorage.getItem(flag)

  return flagValue === "true" || flagValue === "1"
}

export const isAIEnabled = (): boolean => {
  return getFlagBool(FEATURE_AI)
}

import { type PayloadAction, createSlice } from "@reduxjs/toolkit"

interface Breadcrumb {
  label: string
  path?: string
}

interface BreadcrumbsState {
  breadcrumbs: Breadcrumb[]
}

const initialState: BreadcrumbsState = {
  breadcrumbs: [],
}

export const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumbs = action.payload
    },
  },
})

export const { setBreadcrumbs } = breadcrumbsSlice.actions

export default breadcrumbsSlice.reducer

export const selectBreadcrumbs = (state: { breadcrumbs: BreadcrumbsState }) =>
  state.breadcrumbs.breadcrumbs

import { CalendarDays, Hash } from "lucide-react"

export const ColumnTypeIcon = ({
  type,
  className = "w-3 h-3",
}: {
  type: string
  className?: string
}) => {
  return (
    <>
      {type === "datetime" && <CalendarDays className={className} />}
      {type === "number" && <Hash className={className} />}
    </>
  )
}

import type { SeriesFunctionStep } from "@/model"
import { defaultColor } from "@/preference"
import { Select, TextField } from "@/ui"
import { ColorPicker } from "@/ui/ColorPicker"

const fitCurveTypes = [
  {
    name: "Linear",
    value: "linear",
  },
  {
    name: "Polynomial",
    value: "polynomial",
  },
  {
    name: "Spline",
    value: "spline",
  },
]

export interface FitCurveConfigProps {
  step: SeriesFunctionStep
  onStyleChanged: (style: Record<string, any>) => void
  onConfigChanged: (config: Record<string, any>) => void
}

export const FitCurveConfig = ({
  step,
  onStyleChanged,
  onConfigChanged,
}: FitCurveConfigProps) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <span className="text-gray-500">Color</span>
        <span className="ml-auto">
          <ColorPicker
            allowNone={true}
            value={step.style?.color || defaultColor}
            onChange={v => onStyleChanged({ color: v })}
          />
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500">Type</span>
        <span className="ml-auto">
          <Select
            variant="compact"
            name="type"
            onChange={v => onConfigChanged({ type: v })}
            value={step.config?.type}
            items={fitCurveTypes}
          />
        </span>
      </div>
      {step.config?.type !== "linear" && (
        <div className="flex items-center justify-between">
          <div className="text-gray-500">Degree</div>
          <div className="w-[70px]">
            <TextField
              type="number"
              autoComplete="off"
              variant="compact"
              step={1}
              min={1}
              onFocus={e => e.target.select()}
              value={step.config?.degree}
              onChange={e => onConfigChanged({ degree: +e.target.value })}
            />
          </div>
        </div>
      )}
    </div>
  )
}

import { getToken, removeToken, setToken } from "./storage"

export const BASE_URL = import.meta.env.VITE_API_URL

interface TokenResponse {
  token: string
  type: string
  expires_in: number
  refresh_token: string
}

const handleResponse = async (response: Response) => {
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message || "An error occurred")
  }
  return response.json()
}

const refreshToken = async (): Promise<string | null> => {
  const { refresh_token } = getToken()
  if (!refresh_token) return null

  try {
    const response = await fetch(
      `${BASE_URL}/auth/token?refresh_token=${refresh_token}`,
    )
    if (!response.ok) throw new Error("Failed to refresh token")

    const data: TokenResponse = await response.json()
    setToken(data.token, data.refresh_token)
    return data.token
  } catch (error) {
    console.error("Error refreshing token:", error)
    removeToken() // Clear tokens if refresh fails
    return null
  }
}

export const authorisedFetch = async (
  url: string,
  options: RequestInit = {},
  responseHandler = handleResponse,
): Promise<any> => {
  const { token } = getToken()
  const headers = new Headers(options.headers)

  if (token && !headers.has("Authorization")) {
    headers.set("Authorization", `Bearer ${token}`)
  }

  const response = await fetch(`${BASE_URL}${url}`, { ...options, headers })

  if (response.status === 401) {
    const newToken = await refreshToken()
    if (newToken) {
      headers.set("Authorization", `Bearer ${newToken}`)
      const newResponse = await fetch(`${BASE_URL}${url}`, {
        ...options,
        headers,
      })
      return responseHandler(newResponse)
    } else {
      throw new Error("Session expired. Please log in again.")
    }
  }

  return responseHandler(response)
}

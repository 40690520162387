import { ColorPicker } from "@/ui/ColorPicker"
import { useState } from "react"

export const ColorPickerWrapper = ({
  color: initialColor,
  onChange,
}: {
  color: string
  onChange?: (color: string) => void
}) => {
  const [color, setColor] = useState(initialColor)

  return (
    <ColorPicker
      value={color}
      allowNone={true}
      onChange={(v: string) => {
        setColor(v)
        onChange?.(v)
      }}
      trigger={
        <div className="">
          <div
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: color }}
          />
        </div>
      }
    />
  )
}

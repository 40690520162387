import type { SeriesFunctionStep } from "@/model"
import { defaultColor } from "@/preference"
import { TextField } from "@/ui"
import { ColorPicker } from "@/ui/ColorPicker"

export interface MedianFilterConfigProps {
  step: SeriesFunctionStep
  onConfigChanged: (config: Record<string, any>) => void
  onStyleChanged: (style: Record<string, any>) => void
}

export const MedianFilterConfig = ({
  step,
  onStyleChanged,
  onConfigChanged,
}: MedianFilterConfigProps) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <span className="text-gray-500">Color</span>
        <span className="ml-auto">
          <ColorPicker
            allowNone={true}
            value={step.style?.color || defaultColor}
            onChange={v => onStyleChanged({ color: v })}
          />
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500">Window Size</span>
        <span className="ml-auto">
          <TextField
            className="w-[55px]"
            type="number"
            autoComplete="off"
            variant="compact"
            step={1}
            min={1}
            onFocus={e => e.target.select()}
            value={step.config?.window_size}
            onChange={e => onConfigChanged({ window_size: +e.target.value })}
          />
        </span>
      </div>
    </div>
  )
}

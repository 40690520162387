import {
  deleteChart,
  listCharts,
  selectCharts,
  setBreadcrumbs,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { DeleteDialog } from "@/ui"
import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { Link } from "react-router-dom"

interface ChartLinkProps {
  chartId: string
  children: React.ReactNode
}

const ChartLink: React.FC<ChartLinkProps> = ({ chartId, children }) => {
  return (
    <Link
      to={`/charts/${chartId}`}
      className="text-indigo-600 hover:text-indigo-900 mr-2 sm:mr-4"
    >
      {children}
    </Link>
  )
}

const NewChartLink: React.FC = () => {
  return (
    <Link
      to="/charts/new"
      className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
    >
      New Chart
    </Link>
  )
}

export const Charts: React.FC = () => {
  const dispatch = useAppDispatch()

  const charts = useAppSelector(selectCharts)

  const [chartToDelete, setChartToDelete] = useState<string | null>(null)

  const handleDelete = () => {
    if (chartToDelete) {
      setChartToDelete(null)
      dispatch(deleteChart(chartToDelete))
    }
  }

  useEffect(() => {
    dispatch(listCharts())
  }, [dispatch])

  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "Charts" }]))
  }, [dispatch])

  const renderSkeletonRows = () => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <tr key={index} className="hover:bg-gray-50">
          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
            <Skeleton width={150} />
          </td>
          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
            <Skeleton width={150} />
          </td>
          <td className="px-4 text-right sm:px-6 py-4 whitespace-nowrap text-sm font-medium">
            <Skeleton width={100} />
          </td>
        </tr>
      ))
  }

  return (
    <div className="p-4 sm:p-6">
      <div className="flex justify-between items-center mb-4 sm:mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Charts</h1>
        <NewChartLink />
      </div>
      <div className="bg-white rounded-lg shadow overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
              >
                Updated At
              </th>
              <th
                scope="col"
                className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {charts === undefined ? (
              renderSkeletonRows()
            ) : (charts || []).length > 0 ? (
              charts.map(chart => (
                <tr key={chart.id} className="hover:bg-gray-50">
                  <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {chart.name}
                  </td>
                  <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                    {new Date(chart.updated_at).toLocaleString()}
                  </td>
                  <td className="px-4 text-right sm:px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <ChartLink chartId={chart.id}>View</ChartLink>
                    <button
                      className="text-red-600 hover:text-red-900"
                      onClick={() => setChartToDelete(chart.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={3}
                  className="px-4 sm:px-6 py-4 text-center text-gray-500"
                >
                  No charts found. Create a new chart to get started.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <DeleteDialog
        isOpen={chartToDelete !== null}
        onClose={() => setChartToDelete(null)}
        onDelete={handleDelete}
        title="Confirm Chart Deletion"
        description="Are you sure you want to delete this chart? This action cannot be undone."
      />
    </div>
  )
}

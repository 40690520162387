import { TASK_ANNOTATION, TASK_MEASURE, type TaskNode } from "@/model"
import {
  ArrowUpRight,
  FileText,
  MousePointer,
  PencilRuler,
  Plus,
  Ruler,
  SquareFunction,
  Type,
} from "lucide-react"

export const tasks: TaskNode[] = [
  {
    id: "edit",
    icon: MousePointer,
    label: "View",
    shortcut: "V",
  },
  {
    id: TASK_MEASURE,
    icon: PencilRuler,
    label: "Measurement",
    shortcut: "M",
    subtaskIcons: {
      distance: Ruler,
      reference_line: Plus,
      stepsfunction: SquareFunction,
    },
  },
  {
    id: TASK_ANNOTATION,
    icon: FileText,
    label: "Annotation",
    shortcut: "A",
    subtaskIcons: {
      text: Type,
      arrow: ArrowUpRight,
    },
  },
]

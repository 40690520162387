import type { SubtaskNode, Task } from "@/model"
import * as Tooltip from "@radix-ui/react-tooltip"

interface SubtaskButtonProps {
  taskId: string
  subtask: SubtaskNode
  onClick: (task: Task) => void
}

export const SubtaskButton: React.FC<SubtaskButtonProps> = ({
  taskId,
  subtask,
  onClick,
}) => {
  const Icon = subtask.icon

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            onClick={() => onClick({ task: taskId, subtask: subtask.id })}
            className={`p-2 transition-colors text-gray-700 hover:text-blue-500 cursor-pointer`}
            disabled={subtask.disabled}
          >
            <Icon size={20} />
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="bg-white text-gray-800 px-2 py-1 rounded text-xs shadow-md"
            sideOffset={5}
            side="right"
          >
            {subtask.disabled
              ? subtask.disabledLabel || subtask.label
              : subtask.label}{" "}
            {subtask.shortcut && (
              <span className="text-gray-400">({subtask.shortcut})</span>
            )}
            <Tooltip.Arrow className="fill-white" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

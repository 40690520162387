import { timeFormat, timeParse } from "d3-time-format"
import { useEffect, useState } from "react"
import { Select, type SelectProps } from "./Select"

export interface DatetimeFormatProps extends Omit<SelectProps, "items"> {}

const parseDate = timeParse("%Y-%m-%d %H:%M:%S")
const fixedDate = parseDate("2023-04-15 09:30:00")

const formatOptions = [
  { value: "auto", name: "Auto" },
  // Time only
  { value: "%H:%M", name: timeFormat("%H:%M")(fixedDate!) },
  { value: "%H:%M:%S", name: timeFormat("%H:%M:%S")(fixedDate!) },
  { value: "%I:%M %p", name: timeFormat("%I:%M %p")(fixedDate!) },

  // Month Year only
  { value: "%b %Y", name: timeFormat("%b %Y")(fixedDate!) },

  // Day Month only
  { value: "%-d %b", name: timeFormat("%-d %b")(fixedDate!) },
  { value: "%-d %b %H:%M", name: timeFormat("%-d %b %H:%M")(fixedDate!) },

  // Date only
  { value: "%-d %b %Y", name: timeFormat("%-d %b %Y")(fixedDate!) },

  // Date and time
  { value: "%-d %b %Y %H:%M", name: timeFormat("%-d %b %Y %H:%M")(fixedDate!) },
  {
    value: "%-d %b %Y %I:%M %p",
    name: timeFormat("%-d %b %Y %I:%M %p")(fixedDate!),
  },
]

function getSelectedOption(value: string | undefined): string {
  const option = formatOptions.find(option => option.value === value)
  const selected = option ? option.value : value === "" ? "" : "custom"
  return selected
}

export const DatetimeFormat = (options: DatetimeFormatProps) => {
  const [selected, setSelected] = useState<string>()

  useEffect(() => {
    setSelected(getSelectedOption(options.value))
  }, [options])

  const onChange = (v: string) => {
    options.onChange?.(v)
  }

  return (
    <div className="space-y-4">
      <Select
        {...options}
        value={selected}
        onChange={v => {
          setSelected(v)
          onChange(v)
        }}
        items={formatOptions}
      />
    </div>
  )
}

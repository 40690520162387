import { TASK_ANNOTATION, TASK_MEASURE, type Task } from "@/model"
import { addToast, useAppDispatch } from "@/store"
import { SimpleLoader } from "@/ui"
import { Download, Images, Trash2 } from "lucide-react"
import { useEffect, useRef, useState } from "react"
import { copyPlotToClipboardAsImage, downloadPlotAsImage } from "../../util"
import { AnnotationSubToolbar, MeasurementSubToolbar } from "./subtoolbar"
import { TaskButton } from "./TaskButton"
import { tasks } from "./tasks"

interface ToolbarProps {
  activeTask: Task
  chartId: string
  plotId: string
  onTaskChanged: (task: Task) => void
  enabled: boolean
}

export const Toolbar: React.FC<ToolbarProps> = ({
  activeTask,
  onTaskChanged,
  chartId,
  plotId,
  enabled,
}) => {
  const [isCopying, setIsCopying] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const toolbarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        toolbarRef.current &&
        !toolbarRef.current.contains(event.target as Node)
      ) {
        setOpenSubmenu(null)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const copyAsImage = async () => {
    setIsCopying(true)
    try {
      await copyPlotToClipboardAsImage({ chartId, plotId })
      dispatch(
        addToast({
          type: "success",
          message: "Plot copied to clipboard as image",
        }),
      )
    } catch (error) {
      dispatch(
        addToast({
          type: "error",
          message: "Failed to export image. Please contact support!",
        }),
      )
    } finally {
      setIsCopying(false)
    }
  }

  const exportToImage = async () => {
    setIsExporting(true)
    try {
      await downloadPlotAsImage({ chartId, plotId })
    } catch (error) {
      dispatch(
        addToast({
          type: "error",
          message: "Failed to export image. Please contact support!",
        }),
      )
    } finally {
      setIsExporting(false)
    }
  }

  const handleTaskClick = (task: Task) => {
    if (task.task === "edit") {
      setOpenSubmenu(null)
    } else if (task.task === TASK_MEASURE || task.task === TASK_ANNOTATION) {
      setOpenSubmenu(openSubmenu === task.task ? null : task.task)
    }
    onTaskChanged(task)
  }

  if (!enabled) {
    return null
  }

  return (
    <div ref={toolbarRef}>
      <div className="absolute top-0 left-0 space-y-3">
        {tasks.map(task => (
          <div key={task.id} className="flex items-start gap-2">
            <TaskButton
              task={task}
              activeTask={activeTask}
              onClick={handleTaskClick}
            />

            {/* Show submenu when the task is clicked and matches openSubmenu state */}
            {openSubmenu === task.id && (
              <div className="bg-white shadow-md">
                {task.id === TASK_MEASURE && (
                  <div className="absolute  bg-white shadow-lg p-1 min-w-[32px] left-[76px] transform -translate-x-full">
                    <MeasurementSubToolbar
                      plotId={plotId}
                      onChange={task => {
                        onTaskChanged(task)
                        setOpenSubmenu(null)
                      }}
                      taskId={task.id}
                    />
                  </div>
                )}
                {task.id === TASK_ANNOTATION && (
                  <div className="absolute bg-white shadow-lg p-1 min-w-[32px] left-[76px] transform -translate-x-full">
                    <AnnotationSubToolbar
                      onChange={task => {
                        onTaskChanged(task)
                        setOpenSubmenu(null)
                      }}
                      taskId={task.id}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="absolute bottom-0 left-0 space-y-3">
        <TaskButton
          task={{
            id: "copy_as_image",
            label: isCopying ? "Copying..." : "Copy as image",
            icon: isCopying ? SimpleLoader : Images,
          }}
          onClick={copyAsImage}
        />
        <TaskButton
          task={{
            id: "export_plot_image",
            label: isExporting ? "Exporting..." : "Export to image",
            icon: isExporting ? SimpleLoader : Download,
          }}
          onClick={exportToImage}
        />
        <TaskButton
          task={{ id: "delete_plot", label: "Delete plot", icon: Trash2 }}
          onClick={onTaskChanged}
        />
      </div>
    </div>
  )
}

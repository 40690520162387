import { colorCompId } from "@/components/charts/util"
import { chartLineColors } from "@/preference"
import { cloneElement, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"

export const NONE_COLOR = "none"
export const isNoneColor = (color: string) => color === NONE_COLOR
export const NonColor = ({ onChange }: { onChange: (c: string) => void }) => {
  return (
    <button
      className="w-4 h-4 rounded-full focus:outline-none bg-white relative"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onChange("none")
      }}
    >
      <div className="absolute inset-0 border-2 border-red-500 rounded-full transform rotate-45">
        <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-red-500 transform -translate-y-1/2"></div>
      </div>
    </button>
  )
}

export interface Position {
  top?: number | string
  left?: number | string
  right?: number | string
  bottom?: number | string
}

export interface ColorPickerProps {
  value?: string
  onChange?: (color: string) => void
  colors?: string[]
  position?: Position | "auto"
  trigger?: React.ReactNode
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  allowNone?: boolean // New prop to control None option
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  value = chartLineColors[0],
  onChange,
  colors = chartLineColors,
  position = "auto",
  trigger,
  isOpen: controlledIsOpen,
  onOpenChange,
  allowNone = false, // Default to false to maintain backward compatibility
}) => {
  const [color, setColor] = useState(value)
  const [isOpenInternal, setIsOpenInternal] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)

  const isOpen = controlledIsOpen ?? isOpenInternal
  const setIsOpen = (newIsOpen: boolean) => {
    setIsOpenInternal(newIsOpen)
    onOpenChange?.(newIsOpen)
  }

  useEffect(() => {
    setColor(value)
  }, [value])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleChange = (newColor: string) => {
    setColor(newColor ?? "")
    onChange?.(newColor)
  }

  const calculatePosition = () => {
    if (position === "auto" && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect()
      return {
        position: "fixed",
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left + window.scrollX - 50}px`,
      } as const
    }
    return position === "auto" ? {} : position
  }

  const renderColorPanel = () => {
    const panelStyle = {
      position: position === "auto" ? "fixed" : "absolute",
      ...calculatePosition(),
      zIndex: 9999,
    } as const

    return createPortal(
      <div
        id={colorCompId}
        ref={panelRef}
        className="p-2 bg-white border border-gray-300 rounded-md shadow-lg"
        style={panelStyle}
      >
        <div className="grid grid-cols-5 gap-2">
          {allowNone && (
            <NonColor
              onChange={c => {
                handleChange(c)
                setIsOpen(false)
              }}
            />
          )}
          {colors.map(lineColor => (
            <button
              key={lineColor}
              className="w-4 h-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              style={{ backgroundColor: lineColor }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                handleChange(lineColor)
                setIsOpen(false)
              }}
            />
          ))}
        </div>
      </div>,
      document.body,
    )
  }

  if (trigger) {
    return (
      <div onClick={() => setIsOpen(!isOpen)}>
        {cloneElement(trigger as React.ReactElement, {
          ref: buttonRef,
        })}
        {isOpen && renderColorPanel()}
      </div>
    )
  }

  return (
    <div className="relative max-w-xs mx-auto">
      <div className="flex items-center">
        <button
          ref={buttonRef}
          className="w-4 h-4 rounded-full shadow-sm focus:outline-none"
          style={{
            backgroundColor: !isNoneColor(color)
              ? color || "transparent"
              : "inherit",
            border: isNoneColor(color) ? "2px solid #9CA3AF" : "none",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isNoneColor(color) && (
            <div className="absolute inset-0 border-2 border-red-500 rounded-full transform rotate-45">
              <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-red-500 transform -translate-y-1/2"></div>
            </div>
          )}
        </button>
        {isOpen && renderColorPanel()}
      </div>
    </div>
  )
}

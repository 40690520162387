import type { Column, Series as SeriesConfigModel } from "@/model"
import {
  deleteSeries,
  selectChart,
  selectDatasources,
  selectSerieses,
  updateSeries,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { DelayedSpinner } from "@/ui"
import { ColorPicker, isNoneColor } from "@/ui/ColorPicker"
import Input from "@/ui/Input"
import * as Tabs from "@radix-ui/react-tabs"
import { Eye, EyeOff, Percent, X } from "lucide-react"
import { useState } from "react"
import { AxisInput } from "./AxisInput"

export interface Option {
  name: string
  value: string
}

export interface GroupOption {
  name: string
  items: Option[]
}

export interface SeriesConfigProps {
  seriesConfig: SeriesConfigModel
  colors: string[]
}

export const SeriesConfig: React.FC<SeriesConfigProps> = ({
  seriesConfig,
  colors,
}) => {
  const isXYConfigured = seriesConfig.x && seriesConfig.y
  const dispatch = useAppDispatch()
  const datasources = useAppSelector(selectDatasources)
  const remainingSeries = useAppSelector(
    selectSerieses(seriesConfig.plot_id),
  ).filter(s => s.id !== seriesConfig.id)
  const chart = useAppSelector(selectChart)

  const onXChange = async (data: { datasourceId: string; column: Column }) => {
    dispatch(
      updateSeries({
        plotId: seriesConfig.plot_id,
        seriesId: seriesConfig.id,
        series: {
          ...seriesConfig,
          x: data.column.name,
          datasource_id: data.datasourceId,
        },
        refreshData: true,
      }),
    )
  }

  const onYChange = async (data: { datasourceId: string; column: Column }) => {
    dispatch(
      updateSeries({
        plotId: seriesConfig.plot_id,
        seriesId: seriesConfig.id,
        series: {
          ...seriesConfig,
          y: data.column.name,
          datasource_id: data.datasourceId,
        },
        refreshData: true,
      }),
    )
  }

  const onSeriesConfigChange = async (value: Partial<SeriesConfigModel>) => {
    const newSeries = { ...seriesConfig, ...value }
    dispatch(
      updateSeries({
        plotId: seriesConfig.plot_id,
        seriesId: seriesConfig.id,
        series: newSeries,
      }),
    )
  }

  const onSeriesDataChange = async (value: Partial<SeriesConfigModel>) => {
    const newSeries = {
      ...seriesConfig,
      ...value,
    }
    dispatch(
      updateSeries({
        plotId: seriesConfig.plot_id,
        seriesId: seriesConfig.id,
        series: newSeries,
        refreshData: true,
      }),
    )
  }

  const onDeleteSeriesConfig = async () => {
    dispatch(
      deleteSeries({ plotId: seriesConfig.plot_id, seriesId: seriesConfig.id }),
    )
  }

  if (datasources === undefined) {
    return (
      <div>
        <DelayedSpinner />
      </div>
    )
  }

  const columnType = datasources[seriesConfig.datasource_id]?.columns.find(
    c => c.name === seriesConfig.x,
  )?.type

  const showSecondSuffix = columnType === "datetime"

  return (
    <div
      className={`rounded-md relative border p-4 text-xs ${isXYConfigured ? "" : "border-yellow-500 border-dashed"}`}
    >
      <div className="absolute -top-2 -right-2 flex space-x-3">
        <div className="rounded-full shadow-md right-4">
          <ColorPicker
            allowNone={true}
            colors={colors}
            value={seriesConfig.color || "#1E90FF"}
            onChange={(v: string) =>
              onSeriesConfigChange({ color: v, visible: !isNoneColor(v) })
            }
          />
        </div>
        <div
          className=" bg-white rounded-full shadow-md hover:bg-red-500 hover:text-white cursor-pointer focus:outline-none transition-colors duration-200"
          onClick={e => {
            e.stopPropagation()
            onDeleteSeriesConfig()
          }}
        >
          <div className="w-4 h-4 flex items-center justify-center">
            <X className="h-3 w-3" />
          </div>
        </div>
      </div>

      {/* Tabs section */}
      <Tabs.Root defaultValue="data" className="w-full">
        <Tabs.List className="flex border-b border-gray-200 mb-4">
          <Tabs.Trigger
            value="data"
            className="px-4 py-2 text-xs focus:outline-none data-[state=active]:text-blue-500 data-[state=active]:border-b-2 data-[state=active]:border-blue-500"
          >
            Data
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="data" className="focus:outline-none">
          <div className="flex items-center space-x-2 mb-2">
            <AxisInput
              onChange={onXChange}
              title="X"
              axis="x"
              value={seriesConfig.x}
              series={seriesConfig}
              remainingSeries={remainingSeries}
              datasources={datasources}
            />
          </div>
          <div className="flex space-x-4 mb-4">
            <div className="flex-1">
              <Input
                label="Offset"
                name={`x-offset-${seriesConfig.id}`}
                type="number"
                min={0}
                suffix={showSecondSuffix ? "seconds" : ""}
                selectAllOnFocus={true}
                placeholder="Offset"
                value={seriesConfig.x_offset?.toString() ?? ""}
                onChange={v => {
                  onSeriesDataChange({
                    x_offset: v === "" ? 0 : parseFloat(v),
                  })
                }}
              />
            </div>
          </div>

          {/* Y Axis Configuration */}
          <div className="mt-4">
            <div className="flex items-center space-x-2 mb-2">
              <AxisInput
                onChange={onYChange}
                title="Y"
                axis="y"
                value={seriesConfig.y}
                series={seriesConfig}
                remainingSeries={remainingSeries}
                datasources={datasources}
              />
            </div>

            <div className="flex space-x-4 mb-2">
              <div className="flex-1">
                <Input
                  label="Offset"
                  name={`y-offset-${seriesConfig.id}`}
                  type="number"
                  selectAllOnFocus={true}
                  min={0}
                  value={seriesConfig.y_offset?.toString() ?? ""}
                  onChange={v =>
                    onSeriesDataChange({
                      y_offset: v === "" ? 0 : parseFloat(v),
                    })
                  }
                  placeholder="Offset"
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="flex-1">
                <Input
                  label="Scale"
                  name={`y-scale-${seriesConfig.id}`}
                  type="number"
                  min={0}
                  selectAllOnFocus={true}
                  value={seriesConfig.y_scale?.toString() ?? ""}
                  onChange={v =>
                    onSeriesDataChange({
                      y_scale: v === "" ? 0 : parseFloat(v),
                    })
                  }
                  placeholder="Scale"
                  suffix={<Percent size={14} color="gray" />}
                />
              </div>
            </div>
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  )
}

export default SeriesConfig

import { Container } from "@/components/charts/plots/Container"
import { selectChart, selectPlots, useAppSelector } from "@/store"
import { AnimatePresence, motion } from "framer-motion"

export const PlotsPanel = () => {
  const chart = useAppSelector(selectChart)
  const plots = useAppSelector(selectPlots)

  if (!chart || !plots) {
    return <div></div>
  }

  return (
    <>
      <AnimatePresence>
        {(plots || []).map(plot => (
          <motion.div
            key={plot.id}
            layout
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
              duration: 0.5,
            }}
          >
            <Container plot={plot} />
          </motion.div>
        ))}
      </AnimatePresence>
    </>
  )
}

import type { Toast as Toaster } from "@/model"
import {
  hideToast,
  removeToast,
  selectToasts,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import * as ToastPrimitive from "@radix-ui/react-toast"
import { X } from "lucide-react"
import React, { useEffect } from "react"

export const ToastProvider = ToastPrimitive.Provider

export const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitive.Viewport
    ref={ref}
    className="fixed bottom-0 right-0 left-0 flex flex-col-reverse p-4 gap-2 w-full md:max-w-[420px] max-h-screen z-[100] outline-none md:left-auto"
    {...props}
  />
))
ToastViewport.displayName = ToastPrimitive.Viewport.displayName

interface ToastProps {
  toast: Toaster
  duration?: number
}

const Toast: React.FC<ToastProps> = ({ toast, duration = 5000 }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (toast.destroy) {
        dispatch(removeToast(toast.id!))
      } else {
        dispatch(hideToast(toast.id!))
      }
    }, duration)

    return () => clearTimeout(timer)
  }, [dispatch, toast, duration])

  const getToastStyles = () => {
    const baseStyles = "border bg-opacity-90 text-sm font-medium"
    switch (toast.type) {
      case "success":
        return `${baseStyles} bg-green-100 border-green-300 text-green-800`
      case "error":
        return `${baseStyles} bg-red-100 border-red-300 text-red-800`
      case "info":
        return `${baseStyles} bg-blue-100 border-blue-300 text-blue-800`
      default:
        return `${baseStyles} bg-gray-100 border-gray-300 text-gray-800`
    }
  }

  const handleClose = () => {
    dispatch(removeToast(toast.id!))
  }

  return (
    <ToastPrimitive.Root
      className={`
        pointer-events-auto relative flex w-full items-center justify-between overflow-hidden rounded-md p-4 shadow-lg transition-all
        ${getToastStyles()}
        data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=open]:slide-in-from-bottom-full data-[state=closed]:slide-out-to-bottom-full
      `}
    >
      <ToastPrimitive.Description className="pr-8">
        {toast.message}
      </ToastPrimitive.Description>
      <ToastPrimitive.Close
        className="absolute right-2 top-2 rounded-md p-1 text-gray-500 opacity-70 transition-opacity hover:text-gray-900 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-400"
        onClick={handleClose}
        aria-label="Close"
      >
        <X className="h-4 w-4" />
      </ToastPrimitive.Close>
    </ToastPrimitive.Root>
  )
}

export const ToastContainer: React.FC = () => {
  const toasts = useAppSelector(selectToasts)

  return (
    <ToastProvider>
      <ToastViewport />
      {toasts.map(toast => (
        <Toast key={toast.id} toast={toast} />
      ))}
    </ToastProvider>
  )
}

export default ToastContainer

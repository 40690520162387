import {
  FUNC_RISING_TIME,
  FUNC_STEPS_FUNCTION,
  type ActiveLocalTask,
} from "@/model"
import { useAnnotationToolbar } from "./AnnotationToolbar"
import { useSeriesFunctionToolbar } from "./SeriesFunctionToolbar"

export const useLocalToolbar = (localTask: ActiveLocalTask | undefined) => {
  const seriesFunctionToolbar = useSeriesFunctionToolbar(localTask)
  const annotationToolbar = useAnnotationToolbar(localTask)

  const localToolbars: { [key: string]: any } = {
    [FUNC_RISING_TIME]: seriesFunctionToolbar,
    [FUNC_STEPS_FUNCTION]: seriesFunctionToolbar,
    annotation_arrow: annotationToolbar,
  }

  return { localToolbars }
}

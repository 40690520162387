import {
  selectProfile,
  setBreadcrumbs,
  useAppDispatch,
  useAppSelector,
} from "@/store"
import { useEffect } from "react"

export function Account() {
  const userInfo = useAppSelector(selectProfile)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBreadcrumbs([{ label: "Account" }]))
  }, [dispatch])

  return (
    <div className="px-4 py-4 sm:px-6 lg:px-8">
      <div>
        <div>First Name: {userInfo?.first_name}</div>
        <div>Last Name: {userInfo?.last_name}</div>
        <div>Email: {userInfo?.communication_email}</div>
      </div>
    </div>
  )
}

import type { ZoomSelection } from "@/model"
import {
  setIsSelectingBox,
  setPlotHoverValues,
  setPlotZoomSelection,
  useAppDispatch,
} from "@/store"
import { localPoint } from "@visx/event"
import type { ScaleLinear } from "d3-scale"
import { useCallback, useEffect, useState } from "react"

interface UseBoxSelectionProps {
  enabled: boolean
  plotId: string
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  innerWidth: number
  innerHeight: number
  margin: { left: number; top: number }
  onSelection?: (selection: ZoomSelection, cancelSelection: () => void) => void
}

export const useBoxSelection = ({
  enabled,
  plotId,
  xScale,
  yScale,
  innerWidth,
  innerHeight,
  margin,
  onSelection,
}: UseBoxSelectionProps) => {
  const dispatch = useAppDispatch()
  const [isSelecting, setIsSelecting] = useState(false)
  const [selection, setSelection] = useState<ZoomSelection | undefined>(
    undefined,
  )
  const [selectionStart, setSelectionStart] = useState<
    | {
        x: number
        y: number
      }
    | undefined
  >(undefined)

  const saveSelection = useCallback(
    (selection?: ZoomSelection) => {
      setSelection(selection)
      dispatch(setPlotZoomSelection({ plotId, zoom: selection }))
    },
    [dispatch, plotId],
  )

  const updateSelection = useCallback(
    (start: { x: number; y: number }, end: { x: number; y: number }) => {
      const x = Math.max(0, Math.min(start.x, end.x))
      const y = Math.max(0, Math.min(start.y, end.y))
      const width = Math.min(innerWidth, Math.max(0, Math.abs(end.x - start.x)))
      const height = Math.min(
        innerHeight,
        Math.max(0, Math.abs(end.y - start.y)),
      )

      const xStart = Math.max(0, Math.min(start.x, end.x))
      const xEnd = Math.min(innerWidth, Math.max(start.x, end.x))
      const yStart = Math.max(0, Math.min(start.y, end.y))
      const yEnd = Math.min(innerHeight, Math.max(start.y, end.y))

      saveSelection({
        x: [xScale.invert(xStart), xScale.invert(xEnd)],
        y: [yScale.invert(yEnd), yScale.invert(yStart)], // Note: y is inverted due to SVG coordinate system
        rect: { x, y, width, height },
      })
    },
    [innerWidth, innerHeight, xScale, yScale, saveSelection],
  )

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 }
      const chartX = Math.max(0, Math.min(x - margin.left, innerWidth))
      const chartY = Math.max(0, Math.min(y - margin.top, innerHeight))
      const xValue = xScale.invert(chartX)
      const yValue = yScale.invert(chartY)

      dispatch(
        setPlotHoverValues({
          plotId,
          hoverValues: {
            xScale: xValue,
            yScale: yValue,
            xPixel: chartX,
            yPixel: chartY,
          },
        }),
      )

      if (isSelecting && selectionStart) {
        dispatch(setIsSelectingBox(true))
        updateSelection(selectionStart, { x: chartX, y: chartY })
      }
    },
    [
      margin,
      innerWidth,
      innerHeight,
      xScale,
      yScale,
      isSelecting,
      selectionStart,
      updateSelection,
      dispatch,
      plotId,
    ],
  )

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      if (!enabled) {
        return
      }

      const { x, y } = localPoint(event) || { x: 0, y: 0 }
      const chartX = Math.max(0, Math.min(x - margin.left, innerWidth))
      const chartY = Math.max(0, Math.min(y - margin.top, innerHeight))

      setIsSelecting(true)
      setSelectionStart({ x: chartX, y: chartY })
      setSelection({
        x: [xScale.invert(chartX), xScale.invert(chartX)],
        y: [yScale.invert(chartY), yScale.invert(chartY)],
        rect: { x: chartX, y: chartY, width: 0, height: 0 },
      })
    },
    [margin, innerWidth, innerHeight, xScale, yScale, enabled],
  )

  const clearSelection = useCallback(() => {
    setSelectionStart(undefined)
    setSelection(undefined)
    saveSelection(undefined)
  }, [setSelectionStart, setSelection, saveSelection])

  const handleMouseUp = useCallback(() => {
    const accidentalClickThreshold = 6

    dispatch(setIsSelectingBox(false))
    setIsSelecting(false)

    if (
      // Too small selection, cancel it
      selection &&
      selection.rect.width <= accidentalClickThreshold &&
      selection.rect.height <= accidentalClickThreshold
    ) {
      clearSelection()
    }

    if (
      selection &&
      onSelection &&
      (selection.rect.width > accidentalClickThreshold ||
        selection.rect.height > accidentalClickThreshold)
    ) {
      onSelection(selection, clearSelection)
    }
  }, [selection, onSelection, clearSelection, dispatch])

  const handleMouseLeave = useCallback(() => {
    dispatch(setPlotHoverValues({ plotId, hoverValues: {} }))
  }, [])

  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isSelecting) {
        handleMouseUp()
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        clearSelection()
      }
    }

    window.addEventListener("mouseup", handleGlobalMouseUp)
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("mouseup", handleGlobalMouseUp)
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [isSelecting, handleMouseUp, clearSelection])

  return {
    handleMouseMove,
    handleMouseDown,
    handleMouseUp,
    handleMouseLeave,
    clearSelection,
  }
}

import * as Dialog from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import ReactDOM from "react-dom"

type ModalSize = "small" | "medium" | "large" | "auto"

interface ModalProps {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  title: string
  description?: string
  children: React.ReactNode
  footer?: React.ReactNode
  size?: ModalSize
}

const sizeClasses: Record<ModalSize, string> = {
  small: "w-[90vw] max-w-[400px]",
  medium: "w-[90vw] max-w-[600px]",
  large: "w-[90vw] max-w-[800px]",
  auto: "w-auto min-w-[300px] max-w-[90vw]",
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onOpenChange,
  title,
  description,
  children,
  footer,
  size = "medium",
}) => {
  const sizeClass = sizeClasses[size]

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      {isOpen &&
        ReactDOM.createPortal(
          <Dialog.Portal>
            <div className="fixed inset-0 z-50">
              <Dialog.Overlay className="fixed inset-0 bg-black/50 animate-fade-in" />
              <Dialog.Content
                className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                bg-white rounded-lg p-6 max-h-[85vh] overflow-y-auto shadow-xl animate-scale-in z-50
                ${sizeClass}`}
              >
                <Dialog.Title className="text-xl font-bold mb-2">
                  {title}
                </Dialog.Title>
                {description && (
                  <Dialog.Description className="text-sm text-gray-500 mb-4">
                    {description}
                  </Dialog.Description>
                )}
                <div className="mb-4">{children}</div>
                {footer && (
                  <div className="mt-6 flex justify-end">{footer}</div>
                )}
                <Dialog.Close asChild>
                  <button
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
                    aria-label="Close"
                  >
                    <X size={24} />
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </div>
          </Dialog.Portal>,
          document.body,
        )}
    </Dialog.Root>
  )
}

export const ModalTrigger: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <Dialog.Trigger asChild>{children}</Dialog.Trigger>

export const ModalClose: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <Dialog.Close asChild>{children}</Dialog.Close>

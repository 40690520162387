import { createChart } from "@/api"
import { Datasources } from "@/components/datasource/Datasources"
import { FileUpload } from "@/components/datasource/FileUpload"
import type { Datasource } from "@/model"
import { setBreadcrumbs, useAppDispatch } from "@/store"
import { Modal } from "@/ui"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const NewChart = () => {
  const [error, setError] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleFilesUploaded = async (datasource: Datasource) => {
    const name = datasource.name
    const ids = [datasource.id]
    const resp = await createChart(name, ids)
    navigate(`/charts/${resp.id}`, { replace: true })
  }

  const onDatasourcesSelected = async (datasources: Datasource[]) => {
    const ids = datasources.map(ds => ds.id)
    const name = datasources.map(ds => ds.name).join(", ")
    setIsModalOpen(false)
    const resp = await createChart(name, ids)
    navigate(`/charts/${resp.id}`, { replace: true })
  }

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Charts", path: "/charts" },
        { label: "New chart" },
      ]),
    )
  }, [dispatch])

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6 text-center text-gray-800">
        Datasources
      </h1>
      <FileUpload
        onFilesUploaded={handleFilesUploaded}
        onFilesUploadedError={setError}
        onIsUploading={() => setIsUploading(true)}
        isPendingDatasource={false}
        multiple={true}
      />

      <Modal
        isOpen={isModalOpen}
        size="large"
        onOpenChange={setIsModalOpen}
        title="Select datasources"
      >
        <div className="py-4">
          <Datasources mode="form" onSelect={onDatasourcesSelected} />
        </div>
      </Modal>

      {error && <p className="mt-4 text-red-500">{error}</p>}
    </div>
  )
}

const ACCESS_TOKEN_KEY = "gridcs:auth:access_token"
const REFRESH_TOKEN_KEY = "gridcs:auth:refresh_token"

export const getToken = () => {
  return {
    token: localStorage.getItem(ACCESS_TOKEN_KEY),
    refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY),
  }
}

export const setToken = (token: string, refresh_token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token)
  localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token)
}

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY)
  localStorage.removeItem(REFRESH_TOKEN_KEY)
}

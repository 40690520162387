import { FUNC_STEPS_FUNCTION, type Task } from "@/model"
import { selectSerieses, useAppSelector } from "@/store"
import { Plus, Ruler, SquareFunction } from "lucide-react"
import { SubtaskButton } from "../SubtaskButton"

export const MeasurementSubToolbar = ({
  taskId,
  onChange,
  plotId,
}: {
  taskId: string
  onChange: (task: Task) => void
  plotId: string
}) => {
  const serieses = useAppSelector(selectSerieses(plotId))

  return (
    <div className="flex flex-col gap-1">
      <SubtaskButton
        taskId={taskId}
        subtask={{
          id: "distance",
          icon: Ruler,
          label: "Distance Measurement",
        }}
        onClick={onChange}
      />
      <SubtaskButton
        taskId={taskId}
        subtask={{
          id: "reference_line",
          icon: Plus,
          label: "Add Reference Line",
        }}
        onClick={onChange}
      />
      <SubtaskButton
        taskId={taskId}
        subtask={{
          id: FUNC_STEPS_FUNCTION,
          icon: SquareFunction,
          label: "Functions",
          disabled: !(serieses || []).length,
          disabledLabel: "No series available for function analysis",
        }}
        onClick={onChange}
      />
    </div>
  )
}

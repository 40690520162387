import type { SeriesFunctionStep } from "@/model"
import { defaultColor } from "@/preference"
import { Switch, TextField } from "@/ui"
import { ColorPicker } from "@/ui/ColorPicker"

export interface RisingTimeConfigProps {
  step: SeriesFunctionStep
  onConfigChanged: (config: Record<string, any>) => void
  onStyleChanged: (style: Record<string, any>) => void
}

export const RisingTimeConfig = ({
  step,
  onStyleChanged,
  onConfigChanged,
}: RisingTimeConfigProps) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center">
        <span className="text-gray-500">Color</span>
        <span className="ml-auto">
          <ColorPicker
            allowNone={true}
            value={step.style?.color || defaultColor}
            onChange={v => onStyleChanged({ color: v })}
          />
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500">Settling Band</span>
        <span className="ml-auto">
          <TextField
            className="w-[55px]"
            type="number"
            autoComplete="off"
            variant="compact"
            step={1}
            min={1}
            onFocus={e => e.target.select()}
            value={step.config?.settling_band}
            onChange={e => onConfigChanged({ settling_band: +e.target.value })}
          />
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500">Noise Filtering</span>
        <span className="ml-auto">
          <TextField
            className="w-[55px]"
            type="number"
            autoComplete="off"
            variant="compact"
            step={1}
            min={1}
            onFocus={e => e.target.select()}
            value={step.config?.smooth_sigma}
            onChange={e => onConfigChanged({ smooth_sigma: +e.target.value })}
          />
        </span>
      </div>{" "}
      <div className="flex items-center">
        <span className="text-gray-500">Show Line</span>
        <span className="ml-auto">
          <Switch
            variant="compact"
            debounceTime={0}
            onChange={v => onStyleChanged({ show_line: v })}
            checked={step.style?.show_line}
          />
        </span>
      </div>
      <div className="flex items-center">
        <span className="text-gray-500">Show Settling Band</span>
        <span className="ml-auto">
          <Switch
            variant="compact"
            debounceTime={0}
            onChange={v => onStyleChanged({ show_settling_band: v })}
            checked={step.style?.show_settling_band}
          />
        </span>
      </div>
    </div>
  )
}
